import {
  IApiEquipe,
  IApiEquipeLight,
  IEquipe,
  IEquipeLight,
  IApiTeamStatistics,
  IApiTeamStatisticsDetail,
  IApiTeamCalendar,
  ITeamStatistics,
  ITeamStatisticsDetail,
} from './equipe.types';
import { routeList } from 'App';
import { formatImage } from 'utils/formatter';
import { apiUrl } from 'features/api/apiCalls.enum';
import { axiosPost, useApiGet } from 'features/api/apiCalls.utils';
import { formatPlayer, formatPlayerLight } from 'features/players/players.services';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { IApiPlayer, IApiPlayerLight } from 'features/players/players.types';
import { formatChampionnatLight } from 'features/championnat/championnat.services';
import { formatMatchForPlanning } from 'features/match/match.services';

function generateNomEquipe(
  club_name: IApiEquipeLight['Lvl_name'],
  category: IApiEquipeLight['Cat_cod'],
  gender: IApiEquipeLight['Gender']
) {
  return [club_name, gender, category].filter((s) => !!s).join(' - ');
}

export function formatEquipe(equipe: IApiEquipe): IEquipe {
  const {
    Off_team_name,
    Profil_id,
    Lvl_name,
    Gender,
    logoUrl,
    Cat_cod,
    Club_id,
    club_link,
    club_logo,
    team_photo,
    follow_id,
    followers,
  } = equipe;
  let clubId = Club_id;
  if (!clubId && club_link) {
    const linkAsArray = club_link.split('/');
    clubId = Number(linkAsArray[linkAsArray.length - 1]);
  }

  return {
    id: Profil_id,
    shortName: Off_team_name,
    nom: generateNomEquipe(Off_team_name || Lvl_name, Cat_cod, Gender),
    link: `${routeList.equipe}/${equipe.Profil_id}`,
    image: logoUrl ? formatImage(logoUrl) : team_photo ? formatImage(team_photo) : undefined,
    clubId,
    clubImage: club_logo ? formatImage(club_logo) : undefined,
    category: Cat_cod,
    gender: Gender,
    followId: follow_id,
    followers: followers || 0,
  };
}

export function formatEquipeLight({
  Cat_cod,
  Gender,
  Lvl_name,
  Profil_id,
  Club_id,
  logoUrl,
  nom,
  Off_team_name,
  Rk,
}: IApiEquipeLight): IEquipeLight {
  return {
    id: Profil_id,
    nom: generateNomEquipe(Off_team_name || nom || Lvl_name, Cat_cod, Gender),
    clubId: Club_id,
    image: logoUrl ? formatImage(logoUrl) : undefined,
    link: `${routeList.equipe}/${Profil_id}`,
    championnatName: Lvl_name,
    rank: Rk,
    category: Cat_cod,
  };
}

function formatTeamStatisticsDetail({
  Draw,
  Won,
  Lost,
  Game_num,
  Tot_defense,
  Avg_defense,
  defense_rk,
  Tot_offense,
  Avg_offense,
  attack_rk,
}: IApiTeamStatisticsDetail): ITeamStatisticsDetail {
  return {
    matchs: {
      played: Game_num,
      won: Won,
      lost: Lost,
      draw: Draw,
    },
    defense: {
      total: Tot_defense,
      avg: Avg_defense,
      rank: defense_rk,
    },
    offense: {
      total: Tot_offense,
      avg: Avg_offense,
      rank: attack_rk,
    },
  };
}

export function formatTeamStatistics({ domicile, exterieur, saison }: IApiTeamStatistics): ITeamStatistics {
  return {
    domicile: domicile.length ? formatTeamStatisticsDetail(domicile[0]) : undefined,
    exterieur: exterieur.length ? formatTeamStatisticsDetail(exterieur[0]) : undefined,
    saison: saison.length ? formatTeamStatisticsDetail(saison[0]) : undefined,
  };
}

export function useGetTeam({ team_id }: { team_id: number }) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGet<{ header: IApiEquipe[] }>(apiUrl.loadTeam, { team_id, user_id });
  return {
    data: data?.header && data.header.length ? formatEquipe({ ...data.header[0], Profil_id: team_id }) : undefined,
    ...rest,
  };
}

export function useGetTeamPlayers(params: { team_id: number }) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGet<{
    players: IApiPlayer[];
    popuplist: IApiPlayerLight[];
    coach: IApiPlayerLight[];
  }>(apiUrl.loadTeamPlayers, {
    ...params,
    user_id,
  });

  const coachData =
    data?.coach && data.coach.length > 0 ? data.coach.find((c) => c.poste?.toLowerCase() === 'entraineur') : undefined;
  const assistantData =
    data?.coach && data.coach.length > 0 ? data.coach.find((c) => c.poste?.toLowerCase() === 'assistant') : undefined;

  return {
    players: (data?.players || []).map(formatPlayer),
    coach: coachData ? formatPlayerLight(coachData) : undefined,
    assistant: assistantData ? formatPlayerLight(assistantData) : undefined,
    popupListPlayers: (data?.popuplist || []).map(formatPlayerLight),
    ...rest,
  };
}

export function useGetTeamStats(params: { team_id: number }, isActive?: boolean) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGet<IApiTeamStatistics>(isActive !== false ? apiUrl.loadTeamstats : null, {
    ...params,
    user_id,
  });
  return { stats: data ? formatTeamStatistics(data) : undefined, ...rest };
}

export function useGetTeamCalendar(params: { team_id: number }, isActive?: boolean) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGet<IApiTeamCalendar>(isActive !== false ? apiUrl.teamschedule : null, {
    ...params,
    user_id,
  });

  const dataFirstObject = data?.['0'];

  return {
    championnat: dataFirstObject
      ? formatChampionnatLight({ nom: dataFirstObject.name, champLink: dataFirstObject.link, type: 'withLink' })
      : undefined,
    calendar: dataFirstObject?.planning.length ? dataFirstObject.planning.map(formatMatchForPlanning) : [],
    ...rest,
  };
}

export function postJoinTeam(params: { user_id: number; team_id: string; player_id?: string; type: 'P' | 'C' }) {
  return axiosPost(apiUrl.jointeam, params);
}

export function useGetTeamsFromClub(params: { club_id?: string }, isActive?: boolean) {
  const { data, ...rest } = useApiGet<IApiEquipeLight[]>(isActive !== false ? apiUrl.teamsClub : null, params);
  return { teams: (data || []).map(formatEquipeLight), ...rest };
}
