import { useContext, useState, FC, useEffect, useMemo } from 'react';
import {
  SubMenuNavItem,
  MenuTab,
  VerticalSeparator,
  DisplayMenuResultsMobile,
  DisplayMenuResultsDesktop,
} from './MenuFragments';
import { TabNameFavoris, MenuLabel } from '../menu.enum';
import { Collapse } from 'reactstrap';
import { MenuContext } from './Menu';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { IOmlEntity } from 'features/transversal/transversal.types';
import classnames from 'classnames';

interface Props {
  isPhone?: boolean;
}

const SubMenuFavoris: FC<Props> = ({ isPhone }) => {
  const { selectedItem } = useContext(MenuContext);
  const { favoris } = useGeneralContextValue();

  const [joueursFavoris, championnatsFavoris, clubsFavoris, equipesFavoris] = useMemo(
    () => [favoris.member, favoris.championnat, favoris.club, favoris.equipe],
    [favoris]
  );

  const [selectedTab, setSelectedTab] = useState<TabNameFavoris | undefined>(
    isPhone ? undefined : TabNameFavoris.joueurs
  );

  useEffect(() => {
    if (isPhone && selectedItem !== MenuLabel.favoris) {
      setSelectedTab(undefined);
    }
  }, [isPhone, selectedItem]);

  function toggle(tab: TabNameFavoris) {
    return tab === selectedTab ? setSelectedTab(undefined) : setSelectedTab(tab);
  }

  const noFav = 'Aucun favoris';

  const tabs = useMemo(
    () => [
      { tabName: TabNameFavoris.joueurs, champs: joueursFavoris },
      { tabName: TabNameFavoris.championnats, champs: championnatsFavoris },
      { tabName: TabNameFavoris.clubs, champs: clubsFavoris },
      { tabName: TabNameFavoris.equipes, champs: equipesFavoris },
    ],
    [championnatsFavoris, clubsFavoris, equipesFavoris, joueursFavoris]
  );

  // Menu mobile
  if (isPhone) {
    return (
      <Collapse className="sub-menu-items" isOpen={selectedItem === MenuLabel.favoris}>
        {tabs.map(({ tabName, champs }) => (
          <SubMenuNavItem key={tabName} isOpen={selectedTab === tabName} name={tabName} onClick={() => toggle(tabName)}>
            <DisplayMenuResultsMobile champs={champs as IOmlEntity[]} nbResultMax={50} noResultText={noFav} />
          </SubMenuNavItem>
        ))}
      </Collapse>
    );
  }

  // Menu desktop
  return (
    <div className={classnames('favoris', selectedItem !== MenuLabel.favoris && 'd-none')}>
      <ul className="nav flex-column subMenu-tabs">
        {tabs.map(({ tabName }) => (
          <MenuTab key={tabName} changeTab={setSelectedTab} selectedTab={selectedTab} tab={tabName} title={tabName} />
        ))}
      </ul>
      <VerticalSeparator />

      {tabs.map(({ tabName, champs }) => (
        <div key={tabName} className={classnames(selectedTab !== tabName && 'd-none')}>
          <DisplayMenuResultsDesktop
            champs={champs as IOmlEntity[]}
            customTitle={tabName}
            isWide={true}
            noResultText={noFav}
          />
        </div>
      ))}
    </div>
  );
};

export default SubMenuFavoris;
