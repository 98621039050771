import OmlUnderline from 'features/transversal/components/OmlUnderline';
import Helmet from 'react-helmet';
import { metadata } from '../layout.enums';

export interface IProps {
  classNames?: string;
  children: React.ReactNode;
  browserTabTitle?: string;
}
const PageTitle = (props: IProps) => {
  const { classNames, children, browserTabTitle } = props;
  return (
    <>
      <OmlUnderline className={classNames} titleLevel={1}>
        {children}
      </OmlUnderline>
      {browserTabTitle && (
        <Helmet>
          <title>{[browserTabTitle, metadata.siteTitle].join(' - ')}</title>
        </Helmet>
      )}
    </>
  );
};

export default PageTitle;
