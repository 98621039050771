import { ComponentProps, FC } from 'react';
import { OmlText } from 'features/transversal/components/OmlText';
import classnames from 'classnames';

const ModalTitle: FC<Pick<ComponentProps<'h3'>, 'children' | 'className'> & { subtitle?: boolean }> = ({
  children,
  className,
  subtitle,
}) => {
  return (
    <OmlText
      className={
        subtitle ? classnames('mb-3', className) : classnames('text-uppercase text-center mt-4 mb-4', className)
      }
      flavour="color-title"
      tag="div"
      variant="h3"
    >
      {children}
    </OmlText>
  );
};
export { ModalTitle };
