import { useModal } from 'features/layout/layout.utils';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { Container, ModalProps } from 'reactstrap';
import { LoginForm, LoginFormProps } from './components/LoginForm';
import LoginPopupPhoto from 'assets/img/inscription/login-popup-photo.jpg';
import { OmlText } from 'features/transversal/components/OmlText';
import OmlLink from 'features/transversal/components/OmlLink';
import { routeList } from 'App';

export function useAuthModal() {
  const { user_id } = useGeneralContextValue();
  const { show: showModal, hide } = useModal();

  function show(
    modalProps?: Partial<Omit<ModalProps, 'children'>> & { informationMessage?: string } & {
      loginProps: LoginFormProps;
    }
  ) {
    const { informationMessage, loginProps, ...restModalProps } = modalProps || {};
    const { onLoginComplete, ...restLoginProps } = loginProps || {};
    showModal({
      ...restModalProps,
      children: (
        <>
          <img alt="" src={LoginPopupPhoto} />
          <Container className="py-4 px-5">
            <OmlText className="text-uppercase text-center mt-4 mb-4" flavour="color-title" tag="div" variant="h3">
              Connecte toi
            </OmlText>
            {informationMessage && (
              <OmlText className="mb-4" variant="small">
                {informationMessage}
              </OmlText>
            )}
            <LoginForm
              className="pb-5"
              onLoginComplete={onLoginComplete ? (userId: number) => onLoginComplete(userId).then(hide) : undefined}
              {...restLoginProps}
            />
            <OmlText className="text-center" variant="small">
              Pas encore membre ?{' '}
              <OmlLink onClick={hide} to={routeList.inscription}>
                Inscris toi
              </OmlLink>
            </OmlText>
          </Container>
        </>
      ),
    });
  }

  return { isConnected: !!user_id, user_id, show, hide };
}
