import { FC } from 'react';
import styled from 'styled-components';
import { colors } from 'styles/variables/colors';
import { fonts } from 'styles/variables/fonts';
import { IMatch } from '../match.types';

import '../styles/index.scss';

export interface IProps {
  match: IMatch;
  mutateMatch: () => void;
}

const StyledContainer = styled.div`
  text-align: center;
  max-width: 100%;
  height: 32px;
  margin-top: 3px;
`;
const StyledSpan = styled.span`
  text-transform: uppercase;
  font-size: ${fonts.font_size_default};
  color: ${colors.color_title};
  font-weight: bold;
  font-family: ${fonts.font_title};
  font-style: italic;
`;

const MatchAveragerating: FC<IProps> = ({ match }: IProps) => {
  const {
    rate: { user: userRate, average: averageRate },
  } = match;

  return (
    <div className="w-100">
      <div className="mb-0 d-flex align-items-center" style={{ height: 35 }}>
        <StyledSpan className="mr-3" style={{ width: 150 }}>
          La note du match
        </StyledSpan>

        <div className="position-relative flex-1">
          {typeof averageRate !== 'undefined' ? (
            <>
              <StyledContainer className="rating-bar-gradient" style={{ width: `${averageRate * 10}%` }} />
              <StyledSpan className="position-absolute" style={{ top: 0, right: 0, lineHeight: '35px' }}>
                {averageRate}/10
              </StyledSpan>
            </>
          ) : (
            "Aucune note pour l'instant"
          )}
        </div>
      </div>
      <div className="mb-3 d-flex align-items-center">
        {typeof userRate !== 'undefined' && (
          <>
            <StyledSpan className="mr-3" style={{ width: 150 }}>
              Ta note
            </StyledSpan>

            <div className="position-relative flex-1">
              {typeof userRate !== 'undefined' && (
                <>
                  <StyledContainer className="rating-bar-gradient" style={{ width: `${userRate * 10}%` }} />
                  <StyledSpan className="position-absolute" style={{ top: 0, right: 0, lineHeight: '35px' }}>
                    {userRate}/10
                  </StyledSpan>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MatchAveragerating;
