import { routeList } from 'App';
import { createContext, FC, useContext, useState, useMemo, useCallback } from 'react';
import { MenuLabel } from '../menu.enum';
import { DesktopMenu } from './DesktopMenu';
import { MobileMenu } from './MobileMenu';
import { useNavigate } from 'react-router-dom';
import { GeneralContext, useGeneralContextValue } from 'features/transversal/general.context';

import '../styles/Menu.scss';

type MenuContextProp = {
  selectedItem: MenuLabel;
  setSelectedItem: (m: MenuLabel | undefined) => void;
  clickSubMenu: (m: MenuLabel) => void;
  mobileMenuIsOpen: boolean;
  setMobileMenuIsOpen: (o: boolean) => void;
};

export const MenuContext = createContext<Partial<MenuContextProp>>({});

const Menu: FC = () => {
  const { deviceType } = useContext(GeneralContext);
  const [selectedItem, setSelectedItem] = useState<MenuLabel>();
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const navigate = useNavigate();
  const { user } = useGeneralContextValue();

  const clickSubMenu = useCallback(
    (menuName: MenuLabel) => {
      if (menuName !== selectedItem) {
        setSelectedItem(menuName);
      } else if (menuName === selectedItem) {
        setSelectedItem(undefined);
      }

      if (menuName === MenuLabel.profil) {
        if (!user) {
          navigate(routeList.login);
          setMobileMenuIsOpen(false);
        }
      } else if (menuName === MenuLabel.joueurs) {
        navigate(routeList.players);
        setMobileMenuIsOpen(false);
      }
      // else if (menuName === MenuLabel.recherche) {
      //   navigate(routeList.search);
      //   setMobileMenuIsOpen(false);
      // }
    },
    [navigate, selectedItem, user]
  );

  const menuContextValue = useMemo(
    () => ({
      selectedItem,
      setSelectedItem,
      clickSubMenu,
      mobileMenuIsOpen,
      setMobileMenuIsOpen,
    }),
    [clickSubMenu, mobileMenuIsOpen, selectedItem]
  );

  return (
    <MenuContext.Provider value={menuContextValue}>
      <header className="oml-menu">{deviceType === 'mobile' ? <MobileMenu /> : <DesktopMenu />}</header>
    </MenuContext.Provider>
  );
};

export { Menu };
