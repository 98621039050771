import { FC } from 'react';
import { Row, Col } from 'reactstrap';
import styled, { css } from 'styled-components';
import { sports } from 'features/sport/sports.const';
import { colors } from 'styles/variables/colors';
import { OmlFormikField } from 'features/form/components/OmlFormikField';
import { InjectedStepWrapperProps } from './InscriptionStepWrapper';

const ImgSport = styled.img<{ active: boolean }>`
  width: auto;
  height: 40px;
  object-fit: cover;
  margin: auto;
  ${(props) =>
    !props.active &&
    css`
      filter: grayscale(100%);
    `}
`;

const TextSport = styled.div<{ active: boolean }>`
  ${(props) =>
    props.active &&
    css`
      color: ${colors.color_title};
    `}
`;

const InscriptionStepSport: FC<InjectedStepWrapperProps> = ({ stepSubmited }) => {
  return (
    <>
      <Row className="mb-5">
        {sports.map((sport) => (
          <Col key={sport.name} className="text-center mx-3 mb-3">
            <ImgSport active={sport.active} alt={sport.name} src={sport.imgInscSrc} />
            <TextSport active={sport.active} className="mt-2">
              <div>{sport.name}</div>
              {!sport.active && <div>(bientôt)</div>}
            </TextSport>
          </Col>
        ))}
      </Row>
      <Row className="mb-5 text-center">
        <Col xs={6}>
          <OmlFormikField
            checkboxLabel="Je suis joueur"
            name="isPlayer"
            stepSubmited={stepSubmited}
            type="checkbox"
            withFeedbacks={false}
          />
        </Col>
        <Col xs={6}>
          <OmlFormikField
            checkboxLabel="Je suis entraineur"
            name="isTrainer"
            stepSubmited={stepSubmited}
            type="checkbox"
            withFeedbacks={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default InscriptionStepSport;
