import { useMemo, FC, useState } from 'react';
import { Row, Col } from 'reactstrap';
import OmlDropdown from 'features/form/components/OmlDropdown';
import { ISelectOption } from 'features/form/form.types';
import { routeList } from 'App';
import { IChampionnat, IPoule } from 'features/championnat/championnat.types';
import AddToFavorite from 'features/transversal/components/AddToFavorite';
import Classement from 'features/championnat/components/Classement';
import { useGetActuList } from 'features/actus/actus.services';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetChampionnat, useGetClassements } from 'features/championnat/championnat.services';
import { OmlPageTab, PageContainerWithHeaderAndTabs } from 'features/layout/components/PageContainerWithHeaderAndTabs';
import PageTitle from 'features/layout/components/PageTitle';
import { useGetJourneeResultats } from 'features/match/match.services';
import ResultatsList from 'features/match/components/ResultatsList';
import './Championnat.scss';
import { ChampionnatTabActus } from 'features/championnat/components/ChampionnatTabActus';

const journeePrefix = 'Journée';

const buildPoulesOptions = (poules: IPoule[]): ISelectOption[] => {
  return poules.map((poule) => ({
    value: poule.id.toString(),
    label: poule.nom,
  }));
};

const buildJourneesOptions = (nbJournees: number): ISelectOption[] => {
  const options: ISelectOption[] = [];
  for (let i = 1; i <= nbJournees; i += 1) {
    options.push({
      value: i.toString(),
      label: `${journeePrefix} ${i}`,
    });
  }
  return options;
};

const getSelectedJournee = (journeeIdFromUrl: string | undefined, championnat: IChampionnat | undefined) => {
  const journeeFromUrl = journeeIdFromUrl ? parseInt(journeeIdFromUrl, 10) : null;
  if (journeeFromUrl !== null) {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(journeeFromUrl)) {
      return -1;
    }
    if (championnat) {
      const nbJourneeMax = championnat.nbJournees;
      if (nbJourneeMax && journeeFromUrl <= nbJourneeMax) {
        return journeeFromUrl;
      }
      return -1;
    }
    return journeeFromUrl;
  }
  if (championnat) {
    return championnat.journee;
  }
  return undefined;
};

const Championnat: FC = () => {
  const { id, journee } = useParams<{ id: string; journee?: string }>();
  const champId = useMemo(() => Number(id), [id]);
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const championnatSwrState = useGetChampionnat({ id: champId });
  const championnat = useMemo(() => championnatSwrState.data, [championnatSwrState]);

  const journeeId = useMemo(() => getSelectedJournee(journee, championnat), [championnat, journee]);
  const isTabContentReadyToBeLoaded = useMemo(
    () => Boolean(championnat && journeeId && journeeId > -1),
    [championnat, journeeId]
  );

  const resultatsSwrState = useGetJourneeResultats(
    { id: champId, sched: journeeId },
    Boolean(isTabContentReadyToBeLoaded && selectedTab === 0)
  );
  const actusSwrState = useGetActuList({ champId }, Boolean(isTabContentReadyToBeLoaded && selectedTab === 1));
  const classementSwrState = useGetClassements(
    { id: champId, journee: journeeId },
    Boolean(isTabContentReadyToBeLoaded && selectedTab === 2)
  );

  // Initialise filters
  const pouleOptions = useMemo(
    () => (championnat && championnat.otherPoules ? buildPoulesOptions(championnat.otherPoules) : []),
    [championnat]
  );
  const selectedPoule: ISelectOption | undefined = useMemo(
    () =>
      championnat && championnat.poule && championnat.pouleLong
        ? {
            value: championnat.poule,
            label: championnat.pouleLong,
          }
        : undefined,
    [championnat]
  );

  function handleChangePoule(poule: ISelectOption) {
    if (championnat) {
      navigate(`${routeList.championnat}/${poule.value}/journee/${championnat.journee}`);
    }
  }

  const journeesOptions = useMemo(
    () => (championnat && championnat.nbJournees ? buildJourneesOptions(championnat.nbJournees) : []),
    [championnat]
  );
  const selectedJournee: ISelectOption | undefined = useMemo(
    () =>
      championnat && journeeId && journeeId > -1
        ? {
            value: journeeId.toString(),
            label: `${journeePrefix} ${journeeId}`,
          }
        : undefined,
    [championnat, journeeId]
  );

  function handleChangeJournee(j: ISelectOption) {
    if (championnat) {
      navigate(`${routeList.championnat}/${championnat.id}/journee/${j.value}`);
    }
  }

  const tabs: OmlPageTab[] = useMemo(
    () => [
      {
        name: 'Résultats / matchs à venir',
        tabSwrState: resultatsSwrState,
        component: <ResultatsList className="pt-3" resultats={resultatsSwrState.resultats} />,
      },
      {
        name: 'Actualités',
        tabSwrState: actusSwrState,
        component: championnat ? (
          <ChampionnatTabActus
            actus={actusSwrState.actus}
            champId={`${champId}`}
            champName={championnat.nom}
            mutateActus={actusSwrState.mutate}
          />
        ) : (
          <></>
        ),
      },
      {
        name: 'Classement',
        tabSwrState: classementSwrState,
        component: <Classement classement={classementSwrState.classements} />,
      },
    ],
    [actusSwrState, classementSwrState, resultatsSwrState, champId, championnat]
  );

  return (
    <PageContainerWithHeaderAndTabs
      browserTabTitle={championnat?.nom}
      headerComponent={
        <Row className="w-100">
          {(journeeId && journeeId > -1) || !journeeId ? (
            <>
              <Col md={3} xs={6}>
                <OmlDropdown
                  isMulti={false}
                  isSearchable={true}
                  name="selectPoule"
                  onChange={handleChangePoule}
                  options={pouleOptions}
                  placeholder="Poules"
                  value={selectedPoule}
                />
              </Col>
              <Col md={3} xs={6}>
                <OmlDropdown
                  isMulti={false}
                  isSearchable={true}
                  name="selectJournee"
                  onChange={handleChangeJournee}
                  options={journeesOptions}
                  placeholder="Journées"
                  value={selectedJournee}
                />
              </Col>
            </>
          ) : (
            <Col xs={12}>La journee demandée n'existe pas</Col>
          )}
        </Row>
      }
      headerSwrState={championnatSwrState}
      onTabChange={setSelectedTab}
      pageTitle="Championnat"
      pageTitleComponent={
        championnat ? (
          <div className="d-flex justify-content-between">
            <div>
              <PageTitle browserTabTitle={championnat.nom}>{championnat.nom}</PageTitle>
              <span className="sub-title">{`${championnat.niveau} ${championnat.genre}`}</span>
            </div>
            <div className="text-right">
              <AddToFavorite id={championnat.id} type="championnat" />
            </div>
          </div>
        ) : (
          <></>
        )
      }
      tabs={tabs}
    />
  );
};

export default Championnat;
