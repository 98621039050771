import { FormikErrorOrFeedback } from 'features/form/components/FormikErrorOrFeedback';
import { OmlFormikField } from 'features/form/components/OmlFormikField';
import { ICheckboxGroupOption } from 'features/form/form.types';
import { FieldArray } from 'formik';
import { ComponentPropsWithoutRef, FC } from 'react';
import { Col, Row } from 'reactstrap';

const PlayerPointsFortsFormFragment: FC<
  {
    mental: ICheckboxGroupOption[];
    physic: ICheckboxGroupOption[];
    technic: ICheckboxGroupOption[];
    isSubmited?: boolean;
  } & ComponentPropsWithoutRef<'div'>
> = ({ className, mental, physic, technic, isSubmited, ...rest }) => {
  return (
    <Row className={className} {...rest}>
      <Col className="pb-4" xs={6}>
        Mentaux (3 max)
        <FieldArray
          name="mentaux"
          render={() => {
            return (
              <div className="mt-1">
                {mental.map((o) => (
                  <OmlFormikField key={o.id} checkboxLabel={o.label} name={`mentaux[${o.id}]`} type="checkbox" />
                ))}
              </div>
            );
          }}
        />
        <FormikErrorOrFeedback name="mentaux" stepSubmited={isSubmited} />
      </Col>
      <Col className="pb-4" xs={6}>
        Physiques (3 max)
        <FieldArray
          name="physiques"
          render={() => {
            return (
              <div className="mt-1">
                {physic.map((o) => (
                  <OmlFormikField key={o.id} checkboxLabel={o.label} name={`physiques[${o.id}]`} type="checkbox" />
                ))}
              </div>
            );
          }}
        />
        <FormikErrorOrFeedback name="physiques" stepSubmited={isSubmited} />
      </Col>
      <Col xs={12}>
        Techniques (6 max)
        <FieldArray
          name="techniques"
          render={() => {
            return (
              <Row className="mt-1">
                <Col className="d-flex flex-column" xs={6}>
                  {technic
                    .filter((_, i) => i < technic.length / 2)
                    .map((o) => (
                      <OmlFormikField key={o.id} checkboxLabel={o.label} name={`techniques[${o.id}]`} type="checkbox" />
                    ))}
                </Col>
                <Col className="d-flex flex-column" xs={6}>
                  {technic
                    .filter((_, i) => i >= technic.length / 2)
                    .map((o) => (
                      <OmlFormikField key={o.id} checkboxLabel={o.label} name={`techniques[${o.id}]`} type="checkbox" />
                    ))}
                </Col>
              </Row>
            );
          }}
        />
        <FormikErrorOrFeedback name="techniques" stepSubmited={isSubmited} />
      </Col>
    </Row>
  );
};

export { PlayerPointsFortsFormFragment };
