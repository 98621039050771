/* eslint-disable react/no-array-index-key */
import { ComponentPropsWithRef, FC, ReactNode } from 'react';
import NoPhotoImg from 'assets/img/no_photo_thumb.png';
import classnames from 'classnames';
import OmlLink from 'features/transversal/components/OmlLink';

import '../styles/Layout.scss';

type IProps = ComponentPropsWithRef<'header'> & {
  logo?: string;
  logoUrl?: string;
  heading1: JSX.Element | string;
  subHeading?: JSX.Element | string;
  informations?: ReactNode;
  statistics?: { count: number; singular: string; plural: string }[];
  bottomRightElement?: JSX.Element;
  topRightElement?: JSX.Element;
};

const PageHeader: FC<IProps> = ({
  className,
  logo,
  logoUrl,
  heading1,
  subHeading,
  informations,
  statistics,
  bottomRightElement,
  topRightElement,
  ...rest
}) => {
  const rootClassName = classnames('page-header w-100 border', className);

  const statisticsFragment = statistics ? (
    <div className="d-flex flex-wrap small pl-4 background-light-grey">
      {statistics?.map(({ count, singular, plural }, index) => (
        <div
          key={singular}
          className={classnames('d-flex align-items-baseline', index < statistics.length - 1 && 'mr-3')}
        >
          <div className="font-weight-bold font-title mr-1">{count}</div>
          <div>{count > 1 ? plural : singular}</div>
        </div>
      ))}
    </div>
  ) : null;

  const footer =
    informations || bottomRightElement ? (
      <div className="d-flex justify-content-between mb-3">
        <div>{informations}</div>
        {bottomRightElement}
      </div>
    ) : null;
  return (
    <header className={rootClassName} {...rest}>
      <div className={classnames('d-flex', 'w-100', !logo && 'pl-4')}>
        {logo && (
          <div className={classnames('header-logo', 'mr-4', 'd-flex', 'align-items-start')}>
            {logoUrl ? (
              <OmlLink to={logoUrl}>
                <img alt="" className="cursor" src={logo || NoPhotoImg} />
              </OmlLink>
            ) : (
              <img alt="" src={logo || NoPhotoImg} />
            )}
          </div>
        )}
        <div className={classnames('flex-1 pr-4 pt-4')}>
          <div className="d-flex justify-content-between mb-3">
            <div className="w-75">
              <h1 className="mb-2 header-name">{heading1}</h1>
              {subHeading && <div className="header-sub-name">{subHeading}</div>}
            </div>
            {topRightElement}
          </div>
          {footer}
        </div>
      </div>
      {statisticsFragment}
    </header>
  );
};

export { PageHeader };
