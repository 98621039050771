// import './wdyr';

import { createRoot } from 'react-dom/client';

import App from './App';
import registerServiceWorker from './registerServiceWorker';

import './styles/index.scss';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(<App />);
registerServiceWorker();
