import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OmlLink from './OmlLink';
import { routeList } from 'App';

const More = (props: { to: routeList; texte: string }) => {
  return (
    <OmlLink className="uppercase-link" to={props.to}>
      {props.texte} <FontAwesomeIcon className="ml-2" icon="chevron-right" />
    </OmlLink>
  );
};

export default More;
