import { axiosPost } from 'features/api/apiCalls.utils';
import { InscriptionFormState } from 'scenes/Auth/Inscription';
// import { v4 as uuid } from 'uuid';
import { apiUrl } from 'features/api/apiCalls.enum';
import { getMyIp } from 'features/transversal/transversal.utils';
import { managePostLoginOperation } from 'features/auth/auth.services';
import { omlDayjs } from 'utils/date';
import { formatUser } from 'features/user/user.services';
import { IApiLogin } from 'features/auth/auth.types';
import { IApiUser } from 'features/user/user.types';

export enum InscriptionStep {
  first = 'first',
  sport = 'sport',
  qualites = 'qualites',
  club = 'club',
  photo = 'photo',
  confirm = 'confirm',
}

function getMemberType({ isTrainer, isPlayer }: { isTrainer: boolean; isPlayer: boolean }): 'M' | 'C' | 'P' | 'S' {
  return isTrainer && isPlayer ? 'M' : isTrainer ? 'C' : isPlayer ? 'P' : 'S';
}

export const postInscription = async ({
  email,
  password,
  passwordConfirm,
  termsOfUse,
  prenom,
  nom,
  avatar,
  genre,
  birthdate,
  taille,
  playerMaillot,
  poste,
  playerClub,
  isPlayerThisYear,
  playerTeam,
  isTrainer,
  isPlayer,
  isTrainerThisYear,
}: InscriptionFormState) => {
  const ip = await getMyIp();
  return axiosPost<IApiLogin>(
    apiUrl.inscription,
    {
      email,
      password,
      passconf: passwordConfirm,
      timezone: 'Europe/Berlin',
      language: 'fr',
      terms: termsOfUse,
      ip,
      photo: avatar,
      profile_type: 5, // 5 = type membre in DB
      '1_5_28_alias_': nom,
      '1_5_29_alias_': prenom,
      '1_5_31_alias_': birthdate ? omlDayjs(birthdate).format('YYYY-MM-DD') : undefined,
      '1_5_39_alias_': taille,
      '1_5_62_alias_': playerMaillot,
      '1_5_64_alias_': 'BBAL',
      '1_5_65_alias_': poste,
      '1_5_66_alias_': isPlayerThisYear,
      '1_5_68_alias_': playerClub,
      '1_5_70_alias_': genre,
      '1_5_71_alias_': playerTeam,
      '1_5_73_alias_': getMemberType({ isPlayer, isTrainer }),
      '1_5_74_alias_': isTrainerThisYear,
    },
    { withTokens: false }
  ).then(managePostLoginOperation);
};

export const postMember = async ({
  nom,
  prenom,
  avatar,
  email,
  user_id,
  genre,
  birthdate,
  taille,
  poste,
  playerMaillot,
  isTrainer,
  isPlayer,
  playerId,
  trainerId,
}: Pick<
  InscriptionFormState,
  | 'nom'
  | 'avatar'
  | 'prenom'
  | 'email'
  | 'genre'
  | 'birthdate'
  | 'taille'
  | 'poste'
  | 'playerMaillot'
  | 'isTrainer'
  | 'isPlayer'
  | 'playerId'
  | 'trainerId'
> & { user_id: number }) => {
  const type = getMemberType({ isPlayer, isTrainer });

  return axiosPost<IApiUser>(apiUrl.postMember, {
    nom,
    prenom,
    photo: avatar,
    email,
    user_id,
    sport: 'BBAL',
    genre,
    naissance: birthdate ? omlDayjs(birthdate).format('YYYY-MM-DD') : undefined,
    taille,
    poste,
    numero: playerMaillot ? Number(playerMaillot) : undefined,
    type,
    link_player: playerId,
    link_coach: trainerId,
  }).then(({ data }) => formatUser(data));
};

export const postAvatar = async ({ avatar }: InscriptionFormState) => {
  return axiosPost(apiUrl.media, {
    filedata: avatar,
  });
};
