import { apiUrl } from 'features/api/apiCalls.enum';
import { axiosPost, useApiGet } from 'features/api/apiCalls.utils';
import { formatMemberLight } from 'features/member/member.services';
import { omlDayjs } from 'utils/date';
import { IApiPublication, IApiPublicationFeed, Publication } from './publication.types';

function formatFeedToPublication({ is_like, feed }: IApiPublication): Publication {
  const { action_id, feed_title, object_id, owner, body, modified_date, comment_count, like_count } = feed;
  return {
    id: action_id,
    nom: feed_title,
    auteur: formatMemberLight({
      user_id: object_id,
      photo: owner.author_image,
      isPhotoFullPath: true,
      nom: owner.author_title,
    }),
    datePublication: omlDayjs(modified_date),
    contenu: body,
    nbComments: comment_count || 0,
    nbLikes: like_count || 0,
    type: 'divers',
    isLiked: !!is_like,
    image: undefined,
  };
}

export function useGetPublications() {
  const { data, ...rest } = useApiGet<IApiPublicationFeed>(apiUrl.userFeed);
  return { data: data ? data.data.map(formatFeedToPublication) : [], ...rest };
}

export function useGetPublicationLikesComments(params: { action_id: number }, isActive?: boolean) {
  const { data, ...rest } = useApiGet<any>(isActive !== false ? apiUrl.userFeedLikesComments : null, params);
  return { data, ...rest };
}

export function postPublication(params: {
  user_id: number;
  body: string;
  uri?: string; // used for link type
  photo?: File; // used for photo type
}) {
  const updatedParams: typeof params & Partial<{ type: 'link' | 'photo' }> = params;
  if (params.uri) {
    updatedParams.type = 'link';
  }
  if (params.photo) {
    updatedParams.type = 'photo';
  }
  return axiosPost(apiUrl.userPost, updatedParams);
}
