import { ComponentProps, FC, useMemo } from 'react';
import { Field, useFormikContext } from 'formik';
import { Label } from 'reactstrap';
import { FormikAvatarUploader } from './FormikAvatarUploader';
import { FormikCaptcha } from './FormikCaptcha';
import { FormikErrorOrFeedback, FormikErrorOrFeedbackProps } from './FormikErrorOrFeedback';
import { FormikOmlCheckbox } from './FormikOmlCheckbox';
import { FormikOmlInput } from './FormikOmlInput';
import OmlDatePicker, { OmlDatePickerProps } from './OmlDatepicker';
import classnames from 'classnames';
import OmlInput from './OmlInput/OmlInput';
import OmlDropdown from './OmlDropdown';
import OmlSwitch from './OmlSwitch';
import { FormikPhotoUploader } from './FormikPhotoUploader';
import { OmlWysiwyg } from './OmlWysiwyg';

const OmlFormikField: FC<
  {
    className?: string;
    fieldLabel?: string;
    fieldClassName?: string;
    fieldStyle?: string;
    withFeedbacks?: boolean;
    oneLine?: boolean;
  } & FormikErrorOrFeedbackProps &
    (
      | ({
          type: 'text' | 'number' | 'password';
        } & ComponentProps<typeof OmlInput>)
      | {
          type: 'captcha';
        }
      | { type: 'checkbox'; checkboxLabel: string }
      | { type: 'radio'; checkboxLabel: string; value: string }
      | { type: 'file'; subType: 'avatar'; defaultImg?: File }
      | { type: 'file'; subType: 'photo'; defaultImg?: File }
      | ({ type: 'date' } & OmlDatePickerProps)
      | ({
          type: 'select';
        } & ComponentProps<typeof OmlDropdown>)
      | ({
          type: 'switch';
        } & ComponentProps<typeof OmlSwitch>)
      | ({
          type: 'wysiwyg';
        } & ComponentProps<typeof OmlWysiwyg>)
    )
> = ({
  className,
  oneLine,
  fieldClassName,
  name,
  helperLabel,
  stepSubmited,
  fieldLabel,
  withFeedbacks = true,
  ...rest
}) => {
  const { errors, submitCount } = useFormikContext();
  const errorLabel = errors[name];
  const isSubmited = stepSubmited === undefined ? submitCount > 0 : stepSubmited;
  const displayErrorLabel = errorLabel && isSubmited;
  let extraFieldClassName: string | undefined;

  const component = (() => {
    switch (rest.type) {
      case 'number':
      case 'text':
      case 'password': {
        return FormikOmlInput;
      }
      case 'radio':
      case 'checkbox': {
        return FormikOmlCheckbox;
      }
      case 'captcha': {
        return FormikCaptcha;
      }
      case 'file': {
        const { subType } = rest;
        if (subType === 'avatar') {
          return FormikAvatarUploader;
        }
        if (subType === 'photo') {
          extraFieldClassName = !oneLine && fieldLabel ? 'mt-2' : undefined;
          return FormikPhotoUploader;
        }
        return FormikOmlInput;
      }
      case 'date': {
        return OmlDatePicker;
      }
      case 'select': {
        return OmlDropdown;
      }
      case 'switch': {
        return OmlSwitch;
      }
      case 'wysiwyg': {
        return OmlWysiwyg;
      }
      default: {
        const switchTest: never = rest;
        return switchTest;
      }
    }
  })();

  const labelContent = useMemo(() => {
    const FieldComponent = (
      <Field
        className={classnames(fieldClassName, extraFieldClassName)}
        component={component}
        {...rest}
        isInvalid={displayErrorLabel}
        name={name}
      />
    );
    return (
      <>
        {oneLine ? (
          <div className="d-flex align-items-center justify-content-between">
            {fieldLabel && <div className="mr-2">{fieldLabel}</div>}
            {FieldComponent}
          </div>
        ) : (
          <>
            {fieldLabel}
            {FieldComponent}
          </>
        )}
        {withFeedbacks && <FormikErrorOrFeedback helperLabel={helperLabel} name={name} stepSubmited={stepSubmited} />}
      </>
    );
  }, [
    component,
    displayErrorLabel,
    extraFieldClassName,
    fieldClassName,
    fieldLabel,
    helperLabel,
    name,
    oneLine,
    rest,
    stepSubmited,
    withFeedbacks,
  ]);

  return !['wysiwyg', 'date'].includes(rest.type) ? (
    <Label className={classnames('w-100 position-relative', className)}>{labelContent}</Label>
  ) : (
    <div className={classnames('w-100 position-relative', className)}>{labelContent}</div>
  );
};

export { OmlFormikField };
