import { FC } from 'react';
import { Row, Col, FormFeedback } from 'reactstrap';

import { OmlFormikField } from 'features/form/components/OmlFormikField';
import { FormikErrorOrFeedback } from 'features/form/components/FormikErrorOrFeedback';
import { InjectedStepWrapperProps } from './InscriptionStepWrapper';
import { GENRES_OPTIONS } from 'scenes/Auth/Inscription';

const InscriptionFirstStep: FC<InjectedStepWrapperProps & { globalErrorMessages: string[] }> = ({
  stepSubmited,
  globalErrorMessages,
}) => {
  return (
    <>
      {globalErrorMessages.length > 0 && (
        <div className="mb-5">
          {globalErrorMessages.map((m) => (
            <FormFeedback key={m} className="d-block mb-2">
              {m}
            </FormFeedback>
          ))}
        </div>
      )}
      <OmlFormikField className="mb-2" name="prenom" placeholder="Prénom" stepSubmited={stepSubmited} type="text" />
      <OmlFormikField className="mb-2" name="nom" placeholder="Nom" stepSubmited={stepSubmited} type="text" />
      <div className="mb-5" role="group">
        <Row>
          {GENRES_OPTIONS.map((go) => (
            <Col key={go.id}>
              <OmlFormikField
                checkboxLabel={go.value}
                name="genre"
                stepSubmited={stepSubmited}
                type="radio"
                value={go.id}
                withFeedbacks={false}
              />
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <FormikErrorOrFeedback name="genre" stepSubmited={stepSubmited} />
          </Col>
        </Row>
      </div>
      <Row className="mb-2">
        <Col>
          <OmlFormikField name="email" placeholder="Adresse e-mail" stepSubmited={stepSubmited} type="text" />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <OmlFormikField
            helperLabel="Le mot de passe doit faire au minimum 6 caractères"
            name="password"
            placeholder="Mot de passe"
            stepSubmited={stepSubmited}
            type="password"
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <OmlFormikField
            name="passwordConfirm"
            placeholder="Confirmation du mot de passe"
            stepSubmited={stepSubmited}
            type="password"
          />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <OmlFormikField
            checkboxLabel="En cochant cette case, tu accepte les conditions d'utilisation OnMyLeague"
            name="termsOfUse"
            stepSubmited={stepSubmited}
            type="checkbox"
          />
        </Col>
      </Row>
      <div className="mb-5" style={{ minHeight: 80 }}>
        <OmlFormikField name="captcha" stepSubmited={stepSubmited} type="captcha" />
      </div>
    </>
  );
};

export default InscriptionFirstStep;
