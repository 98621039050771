import { ComponentPropsWithRef, FC, useMemo } from 'react';
import { IMatch, IMatchPerf } from '../match.types';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { IEquipeLight } from 'features/equipe/equipe.types';
import { IPlayerLight } from 'features/players/players.types';
import { photoFormatter, rowLinkFormatter, basicCellFormatter } from 'utils/oml-table.utils';
import classnames from 'classnames';
import { OmlText } from 'features/transversal/components/OmlText';
import { TooltipWrapper } from 'features/transversal/components/TooltipWrapper';

import '../styles/index.scss';

export interface IProps {
  match: IMatch;
}

const TotalPerfElement: FC<{ playerId: number; perf: IMatchPerf } & ComponentPropsWithRef<'div'>> = ({
  perf: { label, desc, votes },
  playerId,
  className,
  ...rest
}) => {
  const nbVotes = votes.total.get(playerId.toString()) || 0;
  const isBest = nbVotes === Math.max(...Array.from(votes.total.values()));

  const rootClassName = classnames(isBest && 'font-weight-bold', 'small', className);
  return (
    <TooltipWrapper className={rootClassName} tooltipContent={desc} {...rest}>
      {label} <span className="font-weight-normal">({nbVotes})</span>
    </TooltipWrapper>
  );
};

const UserPerfElement: FC<{ playerId: number; perf: IMatchPerf } & ComponentPropsWithRef<'div'>> = ({
  perf: { label, desc },
  playerId,
  className,
  ...rest
}) => {
  const rootClassName = classnames('font-weight-bold', 'small', className);
  return (
    <TooltipWrapper className={rootClassName} tooltipContent={desc} {...rest}>
      {label}
    </TooltipWrapper>
  );
};

function performanceFormatter(playerId: number, { performances }: IPlayerLight & { performances: IMatchPerf[] }) {
  if (!performances.length) {
    return basicCellFormatter();
  }
  const userPrefs = performances.filter((perf) => perf.votes.user === playerId.toString());
  return (
    <div>
      {userPrefs.length > 0 && (
        <div className="mb-2">
          <OmlText tag="div" variant="tiny">
            Ton choix : 
          </OmlText>
          <div className="d-flex flex-wrap">
            {userPrefs.map((perf) => (
              <UserPerfElement key={perf.id} className="mr-1" perf={perf} playerId={playerId} />
            ))}
          </div>
        </div>
      )}
      <div>
        {userPrefs.length > 0 && (
          <OmlText tag="div" variant="tiny">
            Total : 
          </OmlText>
        )}
        <div className="d-flex flex-wrap">
          {performances.map((perf) => (
            <TotalPerfElement key={perf.id} className="mr-1" perf={perf} playerId={playerId} />
          ))}
        </div>
      </div>
    </div>
  );
}

const columns: ColumnDescription[] = [
  {
    dataField: 'id',
    text: '',
    hidden: true,
  },
  {
    dataField: 'image',
    text: '',
    formatter: photoFormatter,
    headerStyle: {
      width: 70,
    },
  },
  {
    dataField: 'maillot',
    text: 'N°',
    formatter: basicCellFormatter,
    headerStyle: {
      width: 50,
    },
  },
  {
    dataField: 'nom_prenom',
    text: 'joueur',
    classes: 'capitalized-link',
    formatter: rowLinkFormatter,
  },
  {
    dataField: 'id',
    headerStyle: {
      width: 125,
    },
    text: 'performances',
    formatter: performanceFormatter,
  },
];

const MatchTeamCompo: FC<
  ComponentPropsWithRef<'div'> & {
    equipe: IEquipeLight;
    players: IPlayerLight[];
    coachName?: string;
    performances: IMatchPerf[];
  }
> = ({ equipe, players, coachName, performances, ...rest }) => {
  const playersWithPerf = useMemo(
    () =>
      players.map((player) => ({
        ...player,
        performances: performances.reduce((acc: IMatchPerf[], perf) => {
          const nbVoteForThisPlayer = perf.votes.total.get(player.id.toString());
          if (nbVoteForThisPlayer && nbVoteForThisPlayer > 0) {
            acc.push(perf);
          }
          return acc;
        }, []),
      })),
    [players, performances]
  );

  return (
    <div {...rest}>
      <header className="tab-title">
        <div>{equipe.nom}</div>
        {coachName && <div>Coach: {coachName}</div>}
      </header>
      <BootstrapTable
        bootstrap4={true}
        bordered={false}
        classes="oml-table no-min-width"
        columns={columns}
        data={playersWithPerf}
        keyField="id"
        rowClasses="pointer"
      />
    </div>
  );
};

const MatchCompoTab: FC<IProps> = ({
  match: { players, equipeDomicile, equipeExterieur, coachNames, performances },
}) => {
  return (
    <div className="d-lg-flex">
      <MatchTeamCompo
        className="mb-5 mr-lg-3"
        coachName={coachNames.domicile}
        equipe={equipeDomicile}
        performances={performances}
        players={players.domicile}
      />
      <MatchTeamCompo
        coachName={coachNames.exterieur}
        equipe={equipeExterieur}
        performances={performances}
        players={players.exterieur}
      />
    </div>
  );

  // <Container>
  //   <Row className="border-bottom tableTitle">
  //     <Col className="text-center teamName" xs={12}>
  //       <span className="block text-uppercase">{teamName}</span>
  //       <span className="block">coach : {coachName}</span>
  //     </Col>
  //   </Row>
  //   <Row className="pt-2 pb-2 tableTitle">
  //     <Col className="p-0" xs={2} />
  //     <Col className="pl-2" xs={1}>
  //       N°
  //     </Col>
  //     <Col className="pl-2" xs={5}>
  //       joueur
  //     </Col>
  //     <Col className="p-0" xs={4}>
  //       Performance
  //     </Col>
  //   </Row>
  //   {players &&
  //     players.map((player) => {
  //       const { maillot, nom, prenom, id, link, image } = player;
  //       return (
  //         <Row key={id} className="border-right border-left border-bottom verticalCenter">
  //           <Col className="p-0" xs={2}>
  //             <img alt="" className="image playerImage" src={image || NoPhotoImg} />
  //           </Col>
  //           <Col className="p-0 name-detail" xs={1}>
  //             <span className="pl-2 pr-2">{maillot}</span>
  //           </Col>
  //           <Col className="p-0 name-detail" xs={5}>
  //             {wrapWithOmlLink(link, `${nom} ${prenom}`)}
  //           </Col>
  //           <Col className="pl-0 performance-detail" xs={4}>
  //             {perfList.slice(0, 3).map((perf) => {
  //               const { perf_id, perf_label, player_id } = perf;
  //               return (
  //                 <span
  //                   key={perf_id}
  //                   className={id === player_id ? 'cursor bold' : 'cursor'}
  //                   onClick={() => votePerfAction(id, perf_id)}
  //                 >
  //                   {perf_label}
  //                 </span>
  //               );
  //             })}
  //             <span onClick={() => clickHandler(id)}>
  //               <img alt="" src={PlusIcon} />
  //             </span>
  //             {id && popUps[id] ? (
  //               <div ref={toolTip} className="toolTip">
  //                 {perfList.slice(3, perfList.length).map((perf) => {
  //                   const { perf_id, perf_label, player_id } = perf;
  //                   return (
  //                     <span
  //                       key={perf_id}
  //                       className={id === player_id ? 'cursor bold' : 'cursor'}
  //                       onClick={() => votePerfAction(id, perf_id)}
  //                     >
  //                       {perf_label}
  //                     </span>
  //                   );
  //                 })}
  //               </div>
  //             ) : null}
  //           </Col>
  //         </Row>
  //       );
  //     })}
  // </Container>
};

export default MatchCompoTab;
