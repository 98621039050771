export const fonts = {
  font_title: 'Poppins',
  font_text: 'Fira sans',
  font_underline_title: 'Anton',

  font_size_default: '14px',
  font_size_subtitle: '14px',
  font_size_title: '24px',
  font_size_intermediate: '18px',
};
