import { FC, useEffect, useMemo, useState, useContext } from 'react';
import { Form, FormGroup, Label } from 'reactstrap';
import OmlDropdown from 'features/form/components/OmlDropdown';
import { IChampionnat } from 'features/championnat/championnat.types';
import { champCategorie, champNiveau } from 'features/championnat/championnat.enums';
import { ISelectOption } from 'features/form/form.types';
import { genreList } from 'features/transversal/transversal.enum';
import { DisplayMenuResultsDesktop, DisplayMenuResultsMobile, SubMenuNavItem } from './MenuFragments';
import { useGetChampionnatList, useGetRegionsAndDepartment } from 'features/championnat/championnat.services';
import { Loader } from 'features/layout/components/Loader';
import { menuItemloadingError, noMenuFilterSelectedLabel } from '../menu.const';
import { MenuContext } from './Menu';
import { MenuLabel } from '../menu.enum';
import FetchApiError from 'features/transversal/components/FetchApiError';

interface IProps {
  level: champNiveau.regional | champNiveau.departemental;
  isPhone: boolean;
  isSelected: boolean;
}

const RegDeptFormGroup: FC<{
  level: IProps['level'];
  value?: ISelectOption;
  options: ISelectOption[];
  onChange: (value: ISelectOption) => void;
}> = ({ level, options, onChange, value }) => {
  const name = level === champNiveau.regional ? 'Région' : 'Département';
  const className = 'region';
  const placeholder = `${name}...`;
  return (
    <FormGroup>
      <Label for={level}>{name}</Label>
      <OmlDropdown
        className={className}
        isMulti={false}
        isSearchable={true}
        name={level}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={value}
      />
    </FormGroup>
  );
};

const GenreFormGroup: FC<{
  options: ISelectOption[];
  onChange: (value: ISelectOption) => void;
  value?: ISelectOption;
}> = ({ options, onChange, value }) => {
  const name = 'Genre';
  const className = 'genre';
  const placeholder = `${name}...`;
  return (
    <FormGroup>
      <Label for={className}>{name}</Label>
      <OmlDropdown
        className={className}
        name={className}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={value}
        isMulti={false}
      />
    </FormGroup>
  );
};

const CategoryFormGroup: FC<{
  options: ISelectOption[];
  onChange: (value: ISelectOption) => void;
  value?: ISelectOption;
}> = ({ options, onChange, value }) => {
  const name = 'Catégorie';
  const className = 'category';
  const placeholder = `${name}...`;
  return (
    <FormGroup>
      <Label for={className}>{name}</Label>
      <OmlDropdown
        className={className}
        isMulti={false}
        isSearchable={true}
        name={className}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={value}
      />
    </FormGroup>
  );
};

const SubMenuChampionnatRegDept: FC<IProps> = ({ level, isPhone, isSelected }) => {
  const { selectedItem } = useContext(MenuContext);
  const [regDept, setRegDept] = useState<ISelectOption>();
  const [category, setCategory] = useState<ISelectOption>();
  const [genre, setGenre] = useState<ISelectOption>();

  const [firstDisplay, setFirstDisplay] = useState(false);
  useEffect(() => {
    if (isSelected && selectedItem === MenuLabel.championnats) {
      setFirstDisplay(true);
    }
  }, [isSelected, selectedItem]);

  const {
    regDepts,
    isValidating: isValidatingRegDepts,
    error: errorRegDepts,
    mutate: mutateRegDepts,
  } = useGetRegionsAndDepartment(level, firstDisplay);

  const { championnats, isValidating, error, mutate } = useGetChampionnatList(
    { division: level, location: regDept?.value },
    firstDisplay && !!regDept
  );

  const filteredChamps = useMemo(() => {
    function filterGenre(champ: IChampionnat) {
      if (!genre || genre.value === genreList.all) {
        return true;
      }
      return champ.genre === (genre.value as genreList);
    }
    function filterCategory(champ: IChampionnat) {
      if (!category || category.value === champCategorie.all) {
        return true;
      }
      return champ.categorie === (category.value as champCategorie);
    }
    return championnats.filter((champ) => filterGenre(champ) && filterCategory(champ));
  }, [championnats, genre, category]);

  const optionsGenre: ISelectOption[] = useMemo(
    () =>
      filteredChamps
        .reduce((acc, champ) => (acc.includes(champ.genre) ? acc : [...acc, champ.genre]), [genreList.all])
        .map((g) => ({
          value: g,
          label: g,
        })),
    [filteredChamps]
  );
  const optionsCategory: ISelectOption[] = useMemo(
    () =>
      filteredChamps
        .reduce((acc, champ) => (acc.includes(champ.categorie) ? acc : [...acc, champ.categorie]), [champCategorie.all])
        .map((c) => ({
          value: c,
          label: c,
        })),
    [filteredChamps]
  );

  const regDeptFormGroup = useMemo(() => {
    return (
      <RegDeptFormGroup
        level={level}
        onChange={setRegDept}
        options={regDepts.map((r) => ({
          value: r.id,
          label: r.nom,
        }))}
        value={regDept}
      />
    );
  }, [level, regDepts, regDept]);

  const genreFormGroup = useMemo(
    () => (regDept ? <GenreFormGroup onChange={setGenre} options={optionsGenre} value={genre} /> : null),
    [regDept, optionsGenre, genre]
  );
  const categoryFormGroup = useMemo(
    () => (genre ? <CategoryFormGroup onChange={setCategory} options={optionsCategory} value={category} /> : null),
    [genre, optionsCategory, category]
  );

  if (!isSelected) {
    return null;
  }

  if (isPhone) {
    return (
      <div>
        {isValidatingRegDepts ? (
          <SubMenuNavItem level={2} name={<Loader />} />
        ) : errorRegDepts ? (
          <SubMenuNavItem level={2} name={menuItemloadingError} />
        ) : (
          <>
            <Form>
              <SubMenuNavItem hideBorder={true} level={2} name={regDeptFormGroup} />
              {genreFormGroup && <SubMenuNavItem hideBorder={true} level={2} name={genreFormGroup} />}
              {categoryFormGroup && <SubMenuNavItem hideBorder={true} level={2} name={categoryFormGroup} />}
            </Form>
            {isValidating ? (
              <SubMenuNavItem level={2} name={<Loader />} />
            ) : error ? (
              <SubMenuNavItem level={2} name={menuItemloadingError} />
            ) : regDept ? (
              <DisplayMenuResultsMobile champs={filteredChamps} />
            ) : (
              <SubMenuNavItem level={2} name={noMenuFilterSelectedLabel} />
            )}
          </>
        )}
      </div>
    );
  }

  return (
    <div className="col">
      {isValidatingRegDepts ? (
        <div className="w-100 h-100">
          <Loader />
        </div>
      ) : errorRegDepts ? (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <FetchApiError onRetry={mutateRegDepts} />
        </div>
      ) : (
        <>
          <Form className="champ-form">
            {regDeptFormGroup}
            {genreFormGroup}
            {categoryFormGroup}
          </Form>

          <div>
            <div className="liste-result">
              <div className="title">Championnats</div>
            </div>
            {isValidating ? (
              <div className="w-100">
                <Loader />
              </div>
            ) : error ? (
              <div className="w-100 align-self-center">
                <FetchApiError onRetry={mutate} />
              </div>
            ) : regDept ? (
              <DisplayMenuResultsDesktop champs={filteredChamps} />
            ) : (
              <div className="liste-result">{noMenuFilterSelectedLabel}</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SubMenuChampionnatRegDept;
