import { FC } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { routeList } from 'App';
import LogoFooterImg from 'assets/img/logo/logo_footer.png';

import ConditionServiceImg from 'assets/img/footer/conditionsdeservice.png';
import MonCompteImg from 'assets/img/footer/moncompte.png';
import MentionsLegalesImg from 'assets/img/footer/mentionslegales.png';
import ViePriveeImg from 'assets/img/footer/vieprivee.png';
import ReclamationsImg from 'assets/img/footer/reclamations.png';
import ContactImg from 'assets/img/footer/contact.png';
import OmlLink from 'features/transversal/components/OmlLink';

import './Footer.scss';
import FollowUsBar from 'features/transversal/components/FollowUsBar';

const FooterItem: FC<{ name: string; url: routeList; image: string }> = ({ name, url, image }) => (
  <Col className="text-center my-3" lg={2} md={3} xs={4}>
    <OmlLink to={url}>
      <img alt="" className="my-3" src={image} />
      <div>{name}</div>
    </OmlLink>
  </Col>
);

const Footer: FC = () => {
  return (
    <footer className="oml-footer">
      <Container>
        <Row>
          <Col className="d-flex align-items-center justify-content-center logo" xs={12}>
            <img alt="" className="mt-5 mb-3" src={LogoFooterImg} />
          </Col>
        </Row>
        <Row>
          <FollowUsBar />
        </Row>
        <Row className="justify-content-center row-items">
          <FooterItem image={MonCompteImg} name="Mon compte" url={routeList.profil} />
          <FooterItem image={MentionsLegalesImg} name="Mentions légales" url={routeList.mentionsLegales} />
          <FooterItem image={ViePriveeImg} name="Vie privée" url={routeList.viePrivee} />
          <FooterItem image={ConditionServiceImg} name="Conditions de service" url={routeList.conditions} />
          <FooterItem image={ReclamationsImg} name="Réclamations" url={routeList.reclamations} />
          <FooterItem image={ContactImg} name="Contact" url={routeList.contact} />
        </Row>
        <Row className="row-copyright align-items-center justify-content-center">
          @ONMYLEAGUE 2022 - Tous droits réservés
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
