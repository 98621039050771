/* eslint-disable func-names */
import { nameRegEx, passwordRegex } from 'utils/regex';
import * as Yup from 'yup';

Yup.setLocale({
  mixed: {
    required: () => {
      return `Le champ est requis`;
    },
  },
});

Yup.addMethod(
  Yup.string,
  'name',
  function (fieldName: string, options?: Partial<{ feminine: boolean; min: number; max: number }>) {
    const { feminine, min = 2, max = 50 } = options || {};
    const fullFieldName = feminine ? `La ${fieldName}` : `Le ${fieldName}`;

    return this.min(min, `${fullFieldName} est trop ${feminine ? 'courte' : 'court'}`)
      .max(max, `${fullFieldName} est trop ${feminine ? 'longue' : 'long'}`)
      .matches(nameRegEx, `${fullFieldName} utilise des caractères invalides`);
  }
);

Yup.addMethod(Yup.string, 'password', function () {
  return this.matches(passwordRegex, 'Mot de passe incorrect');
});

Yup.addMethod(Yup.object, 'maxValues', function (max: number, errorOptionName: string) {
  return this.test({
    name: 'max',
    exclusive: true,
    params: { max },
    message: `Veuillez sélectionner ${max} ${errorOptionName} maximum`,
    test: (value) => value && Object.values(value).filter((v) => v === true).length <= max,
  });
});

Yup.addMethod(Yup.number, 'score', function () {
  return this.min(0, 'Le score doit être supérieur ou égal à 0');
});

export { Yup as OmlYup };
