import { FieldProps } from 'formik';
import { FC } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const FormikCaptcha: FC<FieldProps<string, any>> = ({ field, form }) => {
  function onCaptchaChange(value) {
    form.setFieldValue(field.name, value);
  }
  return <ReCAPTCHA onChange={onCaptchaChange} sitekey="6LcR5CIaAAAAAGXPkc39VNcSgHC1GcdiqXx4-6UN" />;
};

export { FormikCaptcha };
