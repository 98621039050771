import { ComponentPropsWithoutRef, FC } from 'react';
import { NewActuModal, NewActuModalProps } from 'features/actus/components/NewActuModal';
import { Button } from 'reactstrap';
import { useModal } from 'features/layout/layout.utils';
import { useGeneralContextValue } from 'features/transversal/general.context';

type IProps = { modalProps: Omit<NewActuModalProps, 'modalTitle'> & { entityName: string } };

const NewActuButton: FC<IProps & ComponentPropsWithoutRef<'button'>> = ({
  modalProps: { entityName, ...restModalProps },
  ...rest
}) => {
  const { user_id } = useGeneralContextValue();
  const { show } = useModal();

  function openNewActuModal() {
    show({ children: <NewActuModal modalTitle={`Nouvelle actu pour ${entityName}`} {...restModalProps} /> });
  }

  return user_id ? (
    <Button color="primary" onClick={openNewActuModal} {...rest}>
      Publier une nouvelle actu
    </Button>
  ) : null;
};

export { NewActuButton };
