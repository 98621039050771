import { useState, FC, useMemo, useEffect } from 'react';
import GameBgImg from 'assets/img/game/bg-asset.png';
import OmlDropdown from 'features/form/components/OmlDropdown';
import { ISelectOption } from 'features/form/form.types';
import { IPlayerLight } from 'features/players/players.types';
import { postJoinTeam } from '../equipe.services';
import { useAuthModal } from 'features/auth/auth.hook';
import { newToast } from 'features/layout/components/Toaster';
import { severityLevel } from 'features/layout/layout.enums';
import { ModalBody } from 'features/layout/components/Modal/ModalBody';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import OmlCheckbox from 'features/form/components/OmlCheckbox';

const PlayersListModal: FC<{
  popupListPlayers: IPlayerLight[];
  teamImage?: string;
  teamName?: string;
  teamId: number;
}> = ({ popupListPlayers, teamImage, teamId, teamName }) => {
  const [selectedPlayer, setSelectedPlayer] = useState<ISelectOption>();
  const [notFoundInList, setnotFoundInList] = useState(false);

  const { hide, user_id, show } = useAuthModal();

  useEffect(() => {
    if (notFoundInList) {
      setSelectedPlayer(undefined);
    }
  }, [notFoundInList]);

  useEffect(() => {
    if (selectedPlayer) {
      setnotFoundInList(false);
    }
  }, [selectedPlayer]);

  const onSubmitHandler = () => {
    if (!user_id) {
      show({
        informationMessage: 'Tu dois être connecté pour rejoindre une équipe',
        loginProps: {
          onLoginComplete: async (userId: number) => {
            return postJoinTeam({
              user_id: userId,
              team_id: teamId.toString(),
              player_id: selectedPlayer?.value,
              type: 'P',
            }).then(() => {
              newToast({
                message: `Ton profil est désormais lié à l'équipe ${teamName}`,
                severity: severityLevel.success,
              });
            });
          },
        },
      });
    } else {
      postJoinTeam({ user_id, team_id: teamId.toString(), player_id: selectedPlayer?.value, type: 'P' }).then(() => {
        newToast({
          message: `Ton profil est désormais lié à l'équipe ${teamName}`,
          severity: severityLevel.success,
        });
      });
      hide();
    }
  };

  const playersOptions: ISelectOption[] = useMemo(
    () => popupListPlayers.map((p) => ({ value: p.id.toString(), label: p.nom })),
    [popupListPlayers]
  );

  return (
    <ModalBody
      title="Es-tu dans cette liste ?"
      img={teamImage || GameBgImg}
      actionButtons={<OmlSubmitButton texte="Rejoindre" onClick={onSubmitHandler} />}
    >
      <OmlDropdown onChange={setSelectedPlayer} options={playersOptions} value={selectedPlayer} isMulti={false} />
      <div className="mt-3">
        <OmlCheckbox
          checkboxLabel="Je ne me suis pas trouvé dans la liste"
          checked={notFoundInList}
          onClick={() => setnotFoundInList(!notFoundInList)}
        />
      </div>
    </ModalBody>
  );
};

export default PlayersListModal;
