import { FC, useMemo, useEffect } from 'react';
import { Container } from 'reactstrap';
import { StyledPanelTitle } from 'styles/components/titles';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import BackButton from './InscriptionBackButton';
import { useFormikContext } from 'formik';
import { InscriptionFormState } from 'scenes/Auth/Inscription';
import { InscriptionStep } from '../inscription.services';

export type InjectedStepWrapperProps = { stepSubmited: boolean };

const InscriptionStepWrapper: FC<{
  title: string;
  submitButtonLabel: string;
  stepName: InscriptionStep;
  submitedSteps: InscriptionStep[];
  withBackButton?: boolean;
  onSubmitStep: (step: InscriptionStep, isValid: boolean, goToFirstStep: boolean) => void;
  onBack: () => void;
  children(props: InjectedStepWrapperProps): JSX.Element;
  isStepActive: boolean;
}> = ({
  title,
  submitButtonLabel,
  stepName,
  withBackButton = true,
  onSubmitStep,
  submitedSteps,
  onBack,
  isStepActive,
  children,
}) => {
  const { errors, isValid, setFieldValue } = useFormikContext<InscriptionFormState>();

  useEffect(() => {
    if (isStepActive) {
      setFieldValue('activeStep', stepName);
    }
  }, [isStepActive, setFieldValue, stepName]);

  const stepSubmited = useMemo(() => submitedSteps.includes(stepName), [stepName, submitedSteps]);

  const disabledSubmitButton = useMemo(() => {
    const isErrorInStep = (() => {
      switch (stepName) {
        case InscriptionStep.first: {
          return Boolean(
            errors.prenom ||
              errors.nom ||
              errors.email ||
              errors.genre ||
              errors.password ||
              errors.passwordConfirm ||
              errors.captcha
          );
        }
        case InscriptionStep.sport: {
          return Boolean(errors.isPlayer || errors.isTrainer);
        }
        case InscriptionStep.qualites: {
          return Boolean(
            errors.birthdate || errors.poste || errors.taille || errors.mentaux || errors.physiques || errors.techniques
          );
        }
        case InscriptionStep.club: {
          return Boolean(
            errors.isPlayerThisYear ||
              errors.playerPostal ||
              errors.playerClub ||
              errors.playerTeam ||
              errors.playerMaillot ||
              errors.isTrainerThisYear ||
              errors.trainerPostal ||
              errors.trainerClub ||
              errors.trainerTeam
          );
        }
        case InscriptionStep.photo: {
          return Boolean(errors.avatar);
        }
        case InscriptionStep.confirm: {
          return false;
        }
        default: {
          const switchTest: never = stepName;
          return switchTest;
        }
      }
    })();
    return stepSubmited && isErrorInStep;
  }, [errors, stepSubmited, stepName]);

  function handleSubmitStep() {
    onSubmitStep(stepName, isValid, !!errors.captcha);
  }

  return (
    <div>
      {withBackButton && <BackButton onBack={onBack} />}
      <StyledPanelTitle className="my-5">{title}</StyledPanelTitle>
      <Container className="mb-3 p-0" fluid>
        {children({ stepSubmited })}
      </Container>
      <OmlSubmitButton disabled={disabledSubmitButton} onClick={handleSubmitStep} texte={submitButtonLabel} />
    </div>
  );
};

export default InscriptionStepWrapper;
