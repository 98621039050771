import { Dayjs } from 'dayjs';
import { champCategorie } from 'features/championnat/championnat.enums';
import { IChampionnatLight } from 'features/championnat/championnat.types';
import { IMemberLight } from 'features/member/member.types';
import { genreList } from 'features/transversal/transversal.enum';
import { IOmlEntity } from '../transversal/transversal.types';

export interface IApiActu {
  Cat_cod?: champCategorie;
  Champ_id?: number;
  Cre_dt: string;
  Gender?: genreList;
  Groupe?: string;
  Postal_cod?: string;
  User_id: number;
  auteur: string;
  champ?: string;
  // champCategory?: champCategorie;
  // champName?: string;
  // champPoule?: string;
  contenu: string;
  id: number;
  image: string;
  nbComments?: number;
  nom_prenom?: string;
  photo?: string;
  titre: string;
  type: string;
  nbLikes?: number;
  liked?: string;
}

export const actuTypes = ['club', 'championnat', 'joueur', 'equipe', 'divers'] as const;

export type ActuType = typeof actuTypes[number];

export interface IActu extends IOmlEntity {
  auteur: IMemberLight;
  datePublication: Dayjs;
  contenu: string;
  image: string;
  champ?: IChampionnatLight;
  nbComments: number;
  nbLikes: number;
  type: ActuType;
  isLiked: boolean;
}
