import { FC } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { NavbarBrand } from 'reactstrap';
import LogoImg from 'assets/img/logo/logo_oml_menu.png';
import { NavLinkProps } from 'react-router-dom';

const OMLLogo: FC<Omit<NavLinkProps, 'to'>> = (props) => {
  return (
    <LinkContainer to="/" {...props}>
      <NavbarBrand>
        <img alt="accueil" className="logo" src={LogoImg} />
      </NavbarBrand>
    </LinkContainer>
  );
};

export { OMLLogo };
