import { useState, FC, useMemo } from 'react';
import TeamStatisticsTab from 'features/equipe/components/TeamStatisticsTab';
import TeamPageHeader from 'features/equipe/components/TeamPageHeader';
import TeamCalendarTab from 'features/equipe/components/TeamCalendarTab';
import { useParams } from 'react-router-dom';
import { useGetTeam, useGetTeamCalendar, useGetTeamPlayers, useGetTeamStats } from 'features/equipe/equipe.services';
import TeamPlayersTab from 'features/equipe/components/TeamPlayersTab';
import ActusList from 'features/actus/components/ActusList';
import { useGetActuList } from 'features/actus/actus.services';
import { OmlPageTab, PageContainerWithHeaderAndTabs } from 'features/layout/components/PageContainerWithHeaderAndTabs';

const Team: FC = () => {
  const [tab, setTab] = useState(0);
  const { id } = useParams<{ id: string }>();
  const team_id = useMemo(() => (id ? parseInt(id, 10) : -1), [id]);

  const teamSwrState = useGetTeam({ team_id });
  const team = useMemo(() => teamSwrState.data, [teamSwrState]);

  const teamPlayersSwrState = useGetTeamPlayers({
    team_id,
  });
  const statsSwrState = useGetTeamStats({ team_id }, tab === 1);
  const calendarSwrState = useGetTeamCalendar({ team_id }, tab === 2);
  const actusSwrState = useGetActuList({ team_id }, tab === 3);

  const tabs: OmlPageTab[] = useMemo(
    () => [
      {
        name: 'effectif',
        component: (
          <TeamPlayersTab
            assistant={teamPlayersSwrState.assistant}
            coach={teamPlayersSwrState.coach}
            players={teamPlayersSwrState.players}
          />
        ),
        tabSwrState: teamPlayersSwrState,
      },
      {
        name: 'statistiques',
        component: statsSwrState.stats ? <TeamStatisticsTab teamStats={statsSwrState.stats} /> : <></>,
        tabSwrState: statsSwrState,
      },
      {
        name: 'calendrier',
        component: calendarSwrState.championnat ? (
          <TeamCalendarTab calendar={calendarSwrState.calendar} championnat={calendarSwrState.championnat} />
        ) : (
          <></>
        ),
        tabSwrState: calendarSwrState,
      },
      { name: "fil d'actu", component: <ActusList actus={actusSwrState.actus} />, tabSwrState: actusSwrState },
    ],
    [actusSwrState, calendarSwrState, statsSwrState, teamPlayersSwrState]
  );
  return (
    <PageContainerWithHeaderAndTabs
      browserTabTitle={team?.nom}
      headerComponent={
        team ? (
          <>
            <TeamPageHeader popupListPlayers={teamPlayersSwrState.popupListPlayers} team={team} />
            {team.image && <img alt={`Equipe ${team.nom}`} className="w-100" src={team.image} />}
          </>
        ) : (
          <></>
        )
      }
      headerSwrState={teamSwrState}
      onTabChange={setTab}
      pageTitle="Team"
      tabs={tabs}
    />
  );
};

export default Team;
