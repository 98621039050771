import { FC, ComponentProps } from 'react';
import { Label } from 'reactstrap';
import { colors } from 'styles/variables/colors';
import Switch from 'react-switch';
import { FieldProps } from 'formik';

import '../styles/OmlSwitch.scss';

type IProps = Omit<ComponentProps<typeof Switch>, 'checked' | 'onChange'> & {
  checked?: ComponentProps<typeof Switch>['checked'];
  onChange?: ComponentProps<typeof Switch>['onChange'];
  switchLabel: string;
  isInvalid?: boolean;
};

const OmlSwitch: FC<IProps & Partial<FieldProps<boolean, any>>> = ({
  field,
  form,
  switchLabel,
  isInvalid,
  checked,
  onChange,
  ...rest
}) => {
  const height = 18;
  const { name, value } = field || {};
  const { setFieldValue } = form || {};
  function handleOnChange(v: boolean, event: any, id: string) {
    if (onChange) {
      onChange(v, event, id);
    }
    if (setFieldValue && name) {
      setFieldValue(name, v);
    }
  }

  // TODO implement isInvalid

  return (
    <Label className="d-flex oml-switch align-items-center justify-content-between">
      <span className="mr-2">{switchLabel}</span>
      <Switch
        checked={checked !== undefined ? checked : value || false}
        className="pl-2 pr-1"
        height={height}
        onChange={handleOnChange}
        onColor={colors.color_title}
        width={height * 2}
        {...rest}
      />
    </Label>
  );
};

export default OmlSwitch;
