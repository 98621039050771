import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MemberLayout } from 'features/member/components/MemberLayout';

const Member: FC = () => {
  const { id } = useParams<{ id: string }>();
  const member_id = useMemo(() => (id ? parseInt(id, 10) : -1), [id]);

  return <MemberLayout member_id={member_id} />;
};

export default Member;
