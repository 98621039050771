import { FC, useState } from 'react';
import PageTitle from 'features/layout/components/PageTitle';
import PageContainer from 'features/layout/components/PageContainer';

import { useGetActuList } from 'features/actus/actus.services';
import ActusList from 'features/actus/components/ActusList';
import { ActuType, actuTypes } from 'features/actus/actus.types';
import { Button } from 'reactstrap';

const Actus: FC = () => {
  const [activeActuTypes, setActiveActuTypes] = useState([...actuTypes]);

  const {
    actus,
    isValidating: isValidatingActu,
    error: errorActus,
    mutate: mutateActus,
    setSize,
    size,
    isLastPageReached,
  } = useGetActuList({
    maxItem: 10,
    type: activeActuTypes,
  });

  function handleTypeClick(actuType: ActuType) {
    setActiveActuTypes((types) => {
      if (types.includes(actuType)) {
        return types.filter((t) => t !== actuType);
      }
      return [...types, actuType];
    });
  }

  return (
    <PageContainer>
      <PageTitle browserTabTitle="Actus" classNames="mb-5">
        Actus
      </PageTitle>

      <div className="d-flex mb-4">
        {actuTypes.map((actuType) => (
          <Button
            key={actuType}
            className="mr-3 "
            color={activeActuTypes.includes(actuType) ? 'primary' : 'light'}
            onClick={() => handleTypeClick(actuType)}
            size="sm"
            style={{ borderRadius: 16 }}
          >
            {actuType}
          </Button>
        ))}
      </div>

      <ActusList
        actus={actus}
        currentPage={size}
        error={errorActus}
        isLoading={isValidatingActu}
        mutate={mutateActus}
        unfoldFirstElement
      />

      {!isLastPageReached && (
        <Button color="primary" onClick={() => setSize(size + 1)} type="button">
          Voir plus d'actus
        </Button>
      )}
    </PageContainer>
  );
};

export default Actus;
