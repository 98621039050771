import { ComponentPropsWithRef, FC } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

type IProps = {
  isLoading?: boolean;
  texte?: string;
};

const StyledButton = styled(Button)`
  margin: auto;
  border-radius: 0 !important;
  display: block;
`;

const OmlSubmitButton: FC<IProps & Omit<ComponentPropsWithRef<'button'>, 'children'>> = ({
  isLoading,
  texte = 'Valider',
  className,
  type = 'submit',
  ...rest
}) => {
  return (
    <StyledButton className={className} color="primary" type={type} {...rest}>
      {isLoading ? <FontAwesomeIcon className="fa-spin" icon="circle-notch" /> : texte}
    </StyledButton>
  );
};

export default OmlSubmitButton;
