import { FC } from 'react';
import GameImg from 'assets/img/game/img.png';
import { useModal } from 'features/layout/layout.utils';
import { IMatch } from '../match.types';
import { postRating, postVotePerformances } from '../match.services';
import { OmlText } from 'features/transversal/components/OmlText';
import { useAuthModal } from 'features/auth/auth.hook';
import { OmlYup } from 'features/form/form.validation';
import { Formik, Form } from 'formik';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import { isSubmitButtonDisabled } from 'features/form/form.utils';
import { FormikErrorOrFeedback } from 'features/form/components/FormikErrorOrFeedback';
import { OmlInputRange } from 'features/form/components/OmlInputRange';
import OmlDropdown from 'features/form/components/OmlDropdown';
import { IPlayer } from 'features/players/players.types';
import { ISelectOption } from 'features/form/form.types';
import { ModalBody } from 'features/layout/components/Modal/ModalBody';
import { ModalTitle } from 'features/layout/components/Modal/ModalTitle';

export interface IProps {
  match: IMatch;
  mutateMatch: () => void;
}

const rateSubmitionValidationSchema = OmlYup.object({
  rate: OmlYup.number().required(),
  performances: OmlYup.array(
    OmlYup.object({
      perfId: OmlYup.number().required(),
      player: OmlYup.number().required(),
    })
  ),
});
export type RateSubmitionFormState = OmlYup.Asserts<typeof rateSubmitionValidationSchema>;

const RateSubmissionModal: FC<IProps> = ({
  match: {
    id,
    rate: { user, average },
    performances,
    players,
    equipeDomicile,
    equipeExterieur,
  },
  mutateMatch,
}) => {
  const { hide } = useModal();
  const initialState: RateSubmitionFormState = {
    rate: typeof user !== 'undefined' ? user : typeof average !== 'undefined' ? average : 8,
    performances: performances.reduce((acc: { perfId: number; player: number }[], perf) => {
      if (perf.votes.user) {
        return [...acc, { perfId: perf.id, player: Number(perf.votes.user) }];
      }
      return acc;
    }, []),
  };

  const { show, user_id } = useAuthModal();

  function handleSubmit({ rate, performances: submitedPerfs }: RateSubmitionFormState) {
    if (!user_id) {
      show({
        informationMessage: 'Tu dois être connecté pour soumettre une note',
        loginProps: {
          onLoginComplete: async (userId: number) => {
            await postRating({ user_id: userId, game_id: id, rate });
            await postVotePerformances({ user_id: userId, game_id: id, performances: submitedPerfs });
            return mutateMatch();
          },
        },
      });
    } else {
      postRating({ user_id, game_id: id, rate })
        .then(() => {
          return postVotePerformances({ user_id, game_id: id, performances: submitedPerfs });
        })
        .then(() => {
          return mutateMatch();
        })
        .then(() => {
          hide();
        });
    }
  }

  function formatPlayerAsOption({ id: playerId, nom_prenom, maillot }: IPlayer): ISelectOption {
    return {
      value: `${playerId}`,
      label: `${nom_prenom}${typeof maillot !== 'undefined' ? ` (${maillot})` : ''}`,
    };
  }

  return (
    <Formik initialValues={initialState} onSubmit={handleSubmit} validationSchema={rateSubmitionValidationSchema}>
      {({ errors, submitCount, isSubmitting, values, setFieldValue }) => (
        <Form className="d-flex flex-column align-items-center">
          <ModalBody
            title="Ta note du match"
            img={GameImg}
            actionButtons={
              <OmlSubmitButton
                disabled={isSubmitButtonDisabled({ isSubmitting, errors, submitCount })}
                isLoading={isSubmitting}
                texte="valider"
              />
            }
          >
            <div className="mt-4 mb-5">
              <OmlInputRange
                maxValue={10}
                minValue={0}
                onChange={(value) => setFieldValue('rate', Number(value))}
                step={1}
                value={values.rate}
              />
              <FormikErrorOrFeedback name="rate" />
            </div>
            <div>
              <ModalTitle subtitle>Vote pour tes stars</ModalTitle>
              {performances.map((perf) => {
                const userPerf = values.performances?.filter((p) => p.perfId === perf.id);
                const playerId = userPerf?.length ? userPerf[0].player : undefined;

                const playersOptions = [
                  {
                    label: equipeDomicile.nom,
                    options: players.domicile.map(formatPlayerAsOption),
                  },
                  {
                    label: equipeExterieur.nom,
                    options: players.exterieur.map(formatPlayerAsOption),
                  },
                ];
                const player = [...playersOptions[0].options, ...playersOptions[1].options].find(
                  (p) => p.value === `${playerId}`
                );

                function handleChangePlayer(option: ISelectOption) {
                  const newValue = { perfId: perf.id, player: option.value };
                  setFieldValue(
                    'performances',
                    values.performances
                      ? [...values.performances.filter((p) => p.perfId !== perf.id), newValue]
                      : [newValue]
                  );
                }

                return (
                  <div className="d-flex align-items-center justify-content-between">
                    <OmlText key={perf.id} tag="div" variant="p">
                      {perf.desc}
                    </OmlText>
                    <div style={{ width: 200 }}>
                      <OmlDropdown
                        isMulti={false}
                        isSearchable={true}
                        onChange={handleChangePlayer}
                        options={[...playersOptions[0].options, ...playersOptions[1].options]}
                        placeholder="Sélectionner un joueur..."
                        value={player}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </ModalBody>
        </Form>
      )}
    </Formik>
  );
};

export default RateSubmissionModal;
