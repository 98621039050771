import { ComponentPropsWithRef, FC, useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';

const TooltipWrapper: FC<ComponentPropsWithRef<'div'> & { tooltipContent: string }> = ({ tooltipContent, ...rest }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const divRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div ref={divRef} {...rest} />
      <Tooltip isOpen={tooltipOpen} target={divRef} toggle={toggle}>
        {tooltipContent}
      </Tooltip>
    </>
  );
};

export { TooltipWrapper };
