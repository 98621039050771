import { FC } from 'react';
import styled from 'styled-components';
import ExitImg from 'assets/img/cross-exit.png';
import { useModal } from 'features/layout/layout.utils';

const ContainerWrapper = styled.div`
  .image {
    background-size: cover;
    width: 100%;
  }
  .image-modal {
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
  }
`;
const ActuImageModal: FC<{ img?: string; alt?: string }> = ({ alt = '', img = '' }) => {
  const { hide } = useModal();

  return (
    <ContainerWrapper>
      <img alt={alt} className="w-100" src={img} />
      <img alt="Fermer" className="image-modal" onClick={hide} src={ExitImg} />
    </ContainerWrapper>
  );
};

export default ActuImageModal;
