import { Row } from 'reactstrap';
import { IResultat } from '../match.types';
import { IEquipeLight } from 'features/equipe/equipe.types';
import ContentLoader from 'features/transversal/components/ContentLoader';
import NoPhotoImg from 'assets/img/no_photo_thumb.png';

import '../styles/BoxResultat.scss';
import { routeList } from 'App';
import OmlLink from 'features/transversal/components/OmlLink';
import { useNavigate } from 'react-router-dom';
import { formatDateWithUntilNow } from 'utils/date';

interface IProps {
  resultat?: IResultat;
  displayChampName?: boolean;
}

const TeamRow = (props: { equipe: IEquipeLight; score: number; isWinner: boolean }) => {
  const { equipe, score, isWinner } = props;
  return (
    <Row className="resultRow align-items-center w-100 my-2">
      <div className="img-wrapper mx-2">
        <img alt="" src={equipe.image || NoPhotoImg} />
      </div>
      <div className="name-wrapper single-line">
        <OmlLink className="d-flex" to={equipe.link}>
          {equipe.nom}
        </OmlLink>
      </div>
      <div className={`mx-2 text-center color-title score ${isWinner ? 'font-weight-bold' : ''}`}>{score}</div>
    </Row>
  );
};

const BoxResultat = ({ displayChampName, resultat }: IProps) => {
  const navigate = useNavigate();
  if (!resultat) {
    return <ContentLoader height={200} lineNumber={10} width={300} />;
  }
  const { equipeDomicile, equipeExterieur, score, date, id } = resultat;
  function handleOnClick() {
    navigate(`${routeList.match}/${id}`);
  }
  return (
    <button
      className="resultBox d-flex flex-column h-100 w-100 justify-content-around"
      onClick={handleOnClick}
      type="button"
    >
      <div className="dateBox w-100 d-flex flex-column align-items-center justify-content-center text-center py-2">
        <div>{formatDateWithUntilNow(date)}</div>
        {displayChampName && <div>{resultat.championnat.nom || resultat.championnat.nom}</div>}
      </div>
      <TeamRow equipe={equipeDomicile} isWinner={score.domicile > score.exterieur} score={score.domicile} />
      <TeamRow equipe={equipeExterieur} isWinner={score.domicile < score.exterieur} score={score.exterieur} />
    </button>
  );
};

export default BoxResultat;
