export enum apiUrl {
  // SOCIAL ENGINE
  login = '/login',
  userProfile = '/user/profile',
  inscription = '/signup',
  // inscriptionValidation = '/signup/validations',
  userPost = '/advancedactivity/feeds/post',
  userFeed = '/advancedactivity/feeds',
  userFeedLikesComments = '/advancedactivity/feeds/likes-comments',
  userFeedLike = '/advancedactivity/like',
  userFeedUnlike = '/advancedactivity/unlike',
  userFeedComment = '/advancedactivity/comment',
  userFeedDeleteComment = '/comment-delete',
  userFeedAnswerComment = '/advancedcomments/reply',
  media = '/video-photo-upload',

  // OML CORE
  actus = '/omlcore/actus',
  resultats = '/omlcore/resultats',
  players = '/omlcore/players',
  championnats = '/omlcore/listchampionnats', // par : division=National/Régional/Départemental location=R01 ...
  championnat = '/omlcore/championnat',
  classement = '/omlcore/classement',
  favoris = '/omlcore/favoris',
  ajoutFavori = '/omlcore/ajoutfavori',
  deleteFavori = '/omlcore/deletefavori',
  recherche = '/omlcore/recherche', // user_id query type
  pointsForts = '/omlcore/pointsforts',
  regions = '/omlcore/regionsetdpts',
  towns = '/omlcore/villes',
  postes = '/omlcore/postes',
  clubsTown = '/omlcore/clubsdelaville',
  teamsClub = '/omlcore/equipesduclub',
  playersTeam = '/omlcore/joueursdelequipe',
  postMember = '/omlcore/savemember',
  getMatch = '/omlcore/game',
  votePerf = '/omlcore/voteperf',
  giveScrore = '/omlcore/putscore',
  rategame = '/omlcore/rategame',
  omlmemberinfo = '/omlcore/omlmemberinfo',
  statsplayer = '/omlcore/statsplayer',
  evaluation = '/omlcore/evaluation',
  getCareer = '/omlcore/career',
  addcareer = '/omlcore/addcareer',
  loadTeam = '/omlcore/team',
  loadTeamPlayers = '/omlcore/teamplayers',
  loadTeamstats = '/omlcore/teamstats',
  teamschedule = '/omlcore/teamschedule',
  ajoutfavori = '/omlcore/ajoutfavori',
  deletefavori = '/omlcore/deletefavori',
  pointsforts = '/omlcore/pointsforts',
  savepointsforts = '/omlcore/savepointsforts',
  jointeam = '/omlcore/jointeam',
  savestats = '/omlcore/savestats',
  likeSkill = '/omlcore/likeSkill',
  getComments = '/omlcore/omlgetcomment',
  postComment = '/omlcore/omlpostcomment',
  deleteComment = '/omlcore/omldeletecomment',
  like = '/omlcore/omllike',
  unlike = '/omlcore/omlunlike',
  likeDetails = '/omlcore/omllikedetails', // détail des users ayant liké un post
  postActu = '/omlcore/create-article',
  // Routes à créer
  clubs = '/omlcore/clubs',
  club = '/omlcore/clubdata',
}

export enum oauthTokens {
  consumerKey = 'oauth_consumer_key',
  consumerSecret = 'oauth_consumer_secret',
  token = 'oauth_token',
  secret = 'oauth_secret',
}

export enum axiosStatus {
  tempStatus = '_TEMP',
  success = '_REUSSI',
  failure = '_ERREUR',
}

export enum httpVerbs {
  get = 'GET',
  post = 'POST',
}
