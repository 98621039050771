/* eslint-disable no-param-reassign */
import { FC } from 'react';
import { NewPublicationButton } from 'features/publication/components/NewPublicationButton';
import { Publication } from 'features/publication/publication.types';
import ActuCard from 'features/actus/components/ActuCard';

const MemberPublicationTab: FC<{ publications: Publication[]; mutatePublications: () => void }> = ({
  publications,
  mutatePublications,
}) => {
  return (
    <div className="w-100">
      <NewPublicationButton
        buttonTitle="Exprime toi"
        className="my-3"
        modalProps={{ modalTitle: 'Nouvelle publication', mutatePublications }}
      />
      <div>
        {publications.length
          ? publications.map((publication) => <ActuCard key={publication.id} actu={publication} />)
          : "Aucune publication pour l'instant"}
      </div>
    </div>
  );
};

export { MemberPublicationTab };
