import { Container } from 'reactstrap';

const NotFound = () => {
  return (
    <Container className="fullHeight d-flex align-items-center align-content-center text-center">
      <h1>T'es sûr de ce que tu fais ?</h1>
      <video
        autoPlay={true}
        controls={false}
        loop={true}
        muted={true}
        src={`${process.env.PUBLIC_URL}/404.mp4`}
        style={{ maxWidth: '100%' }}
      />
    </Container>
  );
};

export default NotFound;
