import { FC, useState, useMemo } from 'react';
import { StyledPanelSubTitle } from 'styles/components/titles';
import { InjectedStepWrapperProps } from './InscriptionStepWrapper';
import { useFormikContext } from 'formik';
import { InscriptionFormState } from 'scenes/Auth/Inscription';
import { OmlFormikField } from 'features/form/components/OmlFormikField';
import { FormGroup, Label } from 'reactstrap';
import OmlDropdown from 'features/form/components/OmlDropdown';
import { ISelectOption } from 'features/form/form.types';
import { FormikErrorOrFeedback } from 'features/form/components/FormikErrorOrFeedback';
import { useGetTeamsFromClub } from 'features/equipe/equipe.services';
import { useGetPlayersForTeam } from 'features/players/players.services';
import { useGetClubsFromTown } from 'features/club/club.services';
import { useGetTownList } from 'features/transversal/transversal.services';
import { OmlText } from 'features/transversal/components/OmlText';

type Props = Partial<InjectedStepWrapperProps> & { isStepActive?: boolean };

type ClubSubSectionField<V> = { fieldName: keyof InscriptionFormState; label?: string; value?: V };
type ClubSubSectionFields = {
  isActive?: ClubSubSectionField<boolean>;
  postal: ClubSubSectionField<string>;
  club: ClubSubSectionField<string>;
  team: ClubSubSectionField<string>;
  id: ClubSubSectionField<string>;
  maillot?: ClubSubSectionField<string>;
};

export const ClubSubSection: FC<
  Props & {
    subSectionTitle?: string;
    fields: ClubSubSectionFields;
    type: 'P' | 'C';
    hideTeamSelectionFragment?: boolean;
  }
> = ({
  isStepActive,
  stepSubmited,
  subSectionTitle,
  fields: { isActive, postal, club, team, maillot, id },
  type,
  hideTeamSelectionFragment,
}) => {
  const { setFieldValue } = useFormikContext<InscriptionFormState>();
  const [inputValue, setInputValue] = useState('');

  const { towns } = useGetTownList({ param: inputValue }, isStepActive !== false);
  const townOptions: ISelectOption[] = useMemo(
    () => (towns ? towns.map((t) => ({ value: t.Postal, label: t.Ville })) : []),
    [towns]
  );

  const { clubs } = useGetClubsFromTown({ postal: postal.value }, isStepActive !== false && !!postal.value);
  const clubOptions = useMemo(() => (clubs ? clubs.map((c) => ({ id: c.id.toString(), label: c.nom })) : []), [clubs]);

  const { teams } = useGetTeamsFromClub({ club_id: club.value }, isStepActive !== false && !!club.value);
  const teamOptions = useMemo(() => (teams ? teams.map((t) => ({ id: t.id.toString(), label: t.nom })) : []), [teams]);

  const { data: players } = useGetPlayersForTeam(
    { team_id: team.value || '', type },
    isStepActive !== false && !!team.value
  );
  const playersOptions: ISelectOption[] = useMemo(
    () => (players ? players.map((p) => ({ value: p.id.toString(), label: p.nom_prenom })) : []),
    [players]
  );

  const maillotOptions = useMemo(() => {
    const options: ISelectOption[] = [];
    for (let i = 0; i < 100; i += 1) {
      const formatedNumero = i < 10 ? `0${i}` : i.toString();
      options.push({ value: formatedNumero, label: formatedNumero });
    }
    return options;
  }, []);

  function handleChangeTown(option?: ISelectOption | null) {
    setFieldValue(postal.fieldName, option?.value);
  }

  return (
    <div className="mb-5">
      {subSectionTitle && <StyledPanelSubTitle className="mb-3">{subSectionTitle}</StyledPanelSubTitle>}
      {isActive && (
        <OmlFormikField
          name={isActive.fieldName}
          stepSubmited={stepSubmited}
          switchLabel={isActive.label || ''}
          type="switch"
        />
      )}
      {hideTeamSelectionFragment ? (
        <OmlText tag="div" variant="small">
          Tu es déjà lié à ton équipe en tant que joueur
        </OmlText>
      ) : (
        (!isActive || isActive.value) && (
          <FormGroup className="mb-5 w-100">
            <Label className="d-flex align-items-center">
              <div className="mr-3">Localisation</div>
              <OmlDropdown
                className="w-100"
                isMulti={false}
                isSearchable={true}
                onChange={handleChangeTown}
                onInputChange={(value) => setInputValue(value)}
                options={townOptions}
                placeholder="Sélectionner ma ville..."
              />
            </Label>

            <FormikErrorOrFeedback name="playerPostal" stepSubmited={stepSubmited} />
          </FormGroup>
        )
      )}
      {postal.value && (
        <FormGroup className="mb-5">
          Mon club
          <div className="mt-1">
            {clubOptions.map((o) => (
              <OmlFormikField key={o.id} checkboxLabel={o.label} name={club.fieldName} type="radio" value={o.id} />
            ))}
          </div>
          <FormikErrorOrFeedback name="playerClub" stepSubmited={stepSubmited} />
        </FormGroup>
      )}
      {club.value && (
        <FormGroup className="mb-5">
          Mon équipe
          {teamOptions.map((o) => (
            <OmlFormikField key={o.id} checkboxLabel={o.label} name={team.fieldName} type="radio" value={o.id} />
          ))}
          <FormikErrorOrFeedback name="playerTeam" stepSubmited={stepSubmited} />
        </FormGroup>
      )}
      {team.value && (
        <OmlFormikField
          className="mb-5"
          fieldLabel="Lier mon profil à un joueur de l'effectif"
          name={id.fieldName}
          options={playersOptions}
          stepSubmited={stepSubmited}
          type="select"
          isMulti={false}
        />
      )}
      {team.value && maillot && (
        <OmlFormikField
          fieldLabel="Mon maillot"
          name={maillot.fieldName}
          options={maillotOptions}
          stepSubmited={stepSubmited}
          type="select"
          isMulti={false}
        />
      )}
    </div>
  );
};

const InscriptionStepClub: FC<Props> = (props) => {
  const {
    values: {
      isPlayer,
      isTrainer,
      isPlayerThisYear,
      isTrainerThisYear,
      playerPostal,
      trainerPostal,
      playerClub,
      trainerClub,
      playerTeam,
      trainerTeam,
      playerId,
      trainerId,
    },
  } = useFormikContext<InscriptionFormState>();
  // const [isModaleOpen, updateIsModaleOpen] = useState(false);
  // const [isModalePreviouslyShown, updateIsModalePreviouslyShown] = useState(false);

  // const toggleModale = () => {
  //   updateIsModaleOpen(!isModaleOpen);
  // };
  // const submitModale = () => {
  //   toggleModale();
  // };

  const playerFields: ClubSubSectionFields = useMemo(
    () => ({
      isActive: { fieldName: 'isPlayerThisYear', value: isPlayerThisYear, label: 'Je joue en club cette saison' },
      postal: { fieldName: 'playerPostal', value: playerPostal },
      club: { fieldName: 'playerClub', value: playerClub },
      team: { fieldName: 'playerTeam', value: playerTeam },
      maillot: { fieldName: 'playerMaillot' },
      id: { fieldName: 'playerId', value: playerId },
    }),
    [isPlayerThisYear, playerPostal, playerClub, playerTeam, playerId]
  );

  const trainerFields: ClubSubSectionFields = useMemo(
    () => ({
      isActive: { fieldName: 'isTrainerThisYear', value: isTrainerThisYear, label: "J'entraine en club cette saison" },
      postal: { fieldName: 'trainerPostal', value: trainerPostal },
      club: { fieldName: 'trainerClub', value: trainerClub },
      team: { fieldName: 'trainerTeam', value: trainerTeam },
      id: { fieldName: 'trainerId', value: trainerId },
    }),
    [isTrainerThisYear, trainerPostal, trainerClub, trainerTeam, trainerId]
  );

  return (
    <>
      {isPlayer && <ClubSubSection fields={playerFields} subSectionTitle="En tant que joueur" type="P" {...props} />}
      {isTrainer && (
        <ClubSubSection
          fields={trainerFields}
          hideTeamSelectionFragment={isTrainerThisYear && isPlayerThisYear}
          subSectionTitle="En tant qu'entraineur"
          type="C"
          {...props}
        />
      )}
    </>
  );
};

export default InscriptionStepClub;
