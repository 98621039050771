import { Location, NavigateFunction, Params, useLocation, useNavigate, useParams } from 'react-router-dom';

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export type RouteComponentProps = {
  location: Location;
  navigate: NavigateFunction;
  params: Readonly<Params<string>>;
};
