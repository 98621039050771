import { FC, useState } from 'react';
import { Row, Col } from 'reactstrap';
import ContentLoader, { ContentLoaderTypes } from 'features/transversal/components/ContentLoader';
import { IActu } from '../actus.types';
import classnames from 'classnames';
import { OmlText } from 'features/transversal/components/OmlText';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import { ActuComments } from './ActuComments';
import { Author } from './Author';
import { ActuAction } from './ActuAction';
import { LikeButton } from 'features/transversal/components/LikeButton';
import CommentEmptyImg from 'assets/img/pictos/comments-empty.png';
import CommentImg from 'assets/img/pictos/comments.png';
import { useModal } from 'features/layout/layout.utils';
import ActuImageModal from './ActuImageModal';
import { Publication } from 'features/publication/publication.types';

import '../styles/ActuCard.scss';

export interface IProps {
  actu?: IActu | Publication;
  defaultDetailOpened?: boolean;
  defaultCommentsOpened?: boolean;
}

const ActuCard: FC<IProps> = ({ actu, defaultDetailOpened, defaultCommentsOpened }) => {
  const [isDetailCollapsed, setIsDetailCollapsed] = useState(!defaultDetailOpened);
  const [isCommentsCollapsed, setIsCommentsCollapsed] = useState(!defaultCommentsOpened);
  const [nbLikesInternal, setNbLikesInternal] = useState(actu?.nbLikes || 0);
  const { show } = useModal();
  const loaderWidth = 250;
  const loaderRatio = 4 / 3;

  function showImageModal() {
    show({
      children: <ActuImageModal alt={actu?.nom} img={actu?.image} />,
    });
  }
  function toggleDetailCollapse() {
    setIsDetailCollapsed(!isDetailCollapsed);
  }
  function toggleCommentsCollapse() {
    setIsCommentsCollapsed(!isCommentsCollapsed);
  }

  function handleLikeActu(modificator: number) {
    setNbLikesInternal((current) => current + modificator);
  }

  const isPublication = actu && !actu.image && actu.type === 'divers';

  return actu ? (
    <li className="actu-card styled-text rounded-0 px-4 py-3 mb-4">
      {/* Row du titre */}
      {!isPublication && (
        <Row>
          <Col className={classnames('d-flex', 'actu-type-row', 'mb-2', actu.type.toLowerCase())}>
            <div className="info-stroke">INFO</div>
            <div className="actu-type">{actu.type}</div>
          </Col>
        </Row>
      )}
      {/* Row du contenu */}
      <Row className="mb-3">
        {actu.image && (
          <Col className="text-center" md={6}>
            <img alt={actu.nom} className="rounded-0 pointer" onClick={showImageModal} src={actu.image} />
          </Col>
        )}
        <Col className="py-1" md={actu.image ? 6 : 12}>
          {!isPublication && (
            <OmlText className="text-uppercase font-weight-bold mb-1" flavour="color-title" tag="p" variant="h4">
              {actu.nom}
            </OmlText>
          )}
          <Author auteur={actu.auteur} className="mb-2" datePublication={actu.datePublication} />

          <div className="pointer" onClick={toggleDetailCollapse}>
            {isDetailCollapsed ? (
              <HTMLEllipsis basedOn="words" ellipsis="..." maxLine="3" unsafeHTML={actu.contenu} />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: actu.contenu }} />
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex border-top-grey pt-2">
            <ActuAction
              className="mr-3"
              icon={
                <LikeButton
                  className="mr-2"
                  id={actu.id}
                  isLiked={actu.isLiked}
                  nbLikes={actu.nbLikes}
                  onLikeComplete={handleLikeActu}
                  size={20}
                  type={isPublication ? 'publication' : 'actu'}
                />
              }
              nb={nbLikesInternal}
              title="Likes"
            />

            <ActuAction
              className="pointer"
              icon={
                <img
                  alt=""
                  className="mr-2"
                  src={actu.nbComments > 0 ? CommentImg : CommentEmptyImg}
                  style={{ width: 20 }}
                />
              }
              nb={actu.nbComments}
              onClick={toggleCommentsCollapse}
              title="Commentaires"
            />
          </div>
        </Col>
      </Row>
      {!isCommentsCollapsed && <ActuComments actu={actu} />}
    </li>
  ) : (
    <li className="actu-card rounded-0 px-4 py-3 mb-4 d-flex">
      <ContentLoader
        height={(loaderWidth * loaderRatio * 1) / 3}
        style={{ width: '50%' }}
        type={ContentLoaderTypes.image}
        width={loaderWidth}
      />

      <ContentLoader
        height={(loaderWidth * loaderRatio * 2) / 3}
        lineNumber={10}
        type={ContentLoaderTypes.lines}
        width={loaderWidth}
      />
    </li>
  );
};

export default ActuCard;
