import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { IClassement } from '../classement.types';
import ArrowStableImg from 'assets/img/classement/arrow-stable.png';
import ArrowUpImg from 'assets/img/classement/arrow-up.png';
import ArrowDownImg from 'assets/img/classement/arrow-down.png';
import BestAttaqueImg from 'assets/img/classement/meilleureattaque.png';
import BestDefenseImg from 'assets/img/classement/meilleuredefense.png';
import { equipeFormatter } from 'utils/oml-table.utils';
import NoPhotoImg from 'assets/img/no_photo_thumb.png';
import { wrapWithOmlLink } from 'features/transversal/components/OmlLink';

import '../styles/Classement.scss';

interface IProps {
  classement: IClassement[];
}

function photoFormatter(cell: string, row: IClassement) {
  const img = <img alt="" src={cell || NoPhotoImg} />;
  return wrapWithOmlLink(row.equipe.link, img);
}

function diffFormatter(cell: IClassement['diffWithPreviousJournee']) {
  let src: string | undefined;
  let alt: string | undefined;
  let txt: string | undefined;
  if (cell === 0) {
    src = ArrowStableImg;
    alt = 'progression stable';
  } else if (cell > 0) {
    src = ArrowUpImg;
    alt = 'en montée';
    txt = `(+${cell})`;
  } else if (cell < 0) {
    src = ArrowDownImg;
    alt = 'en descente';
    txt = `(-${cell})`;
  }
  return (
    <div className="d-flex flex-row">
      <img alt={alt} src={src} />
      <div className="rangLabel ml-1">{txt}</div>
    </div>
  );
}

function forceFormatter(_: any, row: IClassement) {
  const { rankDefense, rankOffense } = row;
  let defense: React.ReactNode | null = null;
  let offense: React.ReactNode | null = null;

  if (rankOffense === 1) {
    offense = (
      <div className="text-center">
        <img alt="" className="img-force" src={BestAttaqueImg} />
        <div className="rangLabel text-center">Meilleure attaque</div>
      </div>
    );
  }
  if (rankDefense === 1) {
    defense = (
      <div className="text-center">
        <img alt="" className="img-force" src={BestDefenseImg} />
        <div className="rangLabel text-center">Meilleure défense</div>
      </div>
    );
  }

  return (
    <div className="d-flex row">
      {offense}
      {defense}
    </div>
  );
}

const columns: ColumnDescription[] = [
  {
    dataField: 'equipe.id',
    text: '',
    hidden: true,
  },
  {
    dataField: 'rank',
    text: '#',
    headerStyle: {
      width: '5%',
      padding: '0.75rem 0.75rem 0.75rem 7px',
    },
    style: {
      padding: '0.75rem 0.75rem 0.75rem 7px',
    },
  },
  {
    dataField: 'diffWithPreviousJournee',
    text: '',
    formatter: diffFormatter,
    headerStyle: {
      width: '8%',
    },
  },
  {
    dataField: 'equipe.image',
    text: '',
    formatter: photoFormatter,
    headerStyle: {
      width: '10%',
    },
  },
  {
    dataField: 'equipe',
    text: 'équipe',
    classes: 'capitalized-link',
    formatter: equipeFormatter,
    headerStyle: {
      width: '23%',
      padding: '0.75rem 0.75rem 0.75rem 5px',
    },
    style: {
      padding: '0.75rem 0.75rem 0.75rem 5px',
    },
  },
  {
    dataField: 'nbPts',
    text: 'pts',
    headerStyle: {
      width: '6%',
      padding: '0.75rem 0.75rem 0.75rem 7px',
    },
  },
  {
    dataField: 'journee',
    text: 'mj',
    headerStyle: {
      width: '6%',
      padding: '0.75rem 0.75rem 0.75rem 7px',
    },
  },
  {
    dataField: 'nbWin',
    text: 'vic',
    headerStyle: {
      width: '6%',
      padding: '0.75rem 0.75rem 0.75rem 7px',
    },
  },
  {
    dataField: 'nbLost',
    text: 'def',
    headerStyle: {
      width: '6%',
      padding: '0.75rem 0.75rem 0.75rem 7px',
    },
  },
  {
    dataField: 'avgOffense',
    text: 'att',
    headerStyle: {
      width: '7%',
    },
  },
  {
    dataField: 'avgDefense',
    text: 'def',
    headerStyle: {
      width: '7%',
    },
  },
  {
    dataField: 'rankOffense',
    text: '',
    formatter: forceFormatter,
    headerStyle: {
      width: '50px',
    },
    isDummyField: true,
  },
  {
    dataField: 'rankDefense',
    text: '',
    hidden: true,
  },
];

const Classement = (props: IProps) => {
  const { classement } = props;

  return classement.length > 0 ? (
    <BootstrapTable
      bootstrap4={true}
      bordered={false}
      classes="oml-table table-classement"
      columns={columns}
      data={classement}
      keyField="equipe.id"
    />
  ) : (
    <div className="text-center">Pas de classement disponible pour cette journée</div>
  );
};

export default Classement;
