import { Row, Col } from 'reactstrap';
import BoxResultat from './BoxResultat';
import { IResultat } from '../match.types';
import { ComponentPropsWithoutRef, FC } from 'react';
import classnames from 'classnames';

interface IProps {
  resultats: IResultat[];
}

const ResultatsList: FC<IProps & ComponentPropsWithoutRef<'div'>> = ({ resultats, className, ...rest }) => {
  return resultats.length > 0 ? (
    <Row className={className} {...rest}>
      {resultats.map((resultat) => (
        <Col key={resultat.id} className="d-flex justify-content-center mb-3" md={6} xs={12}>
          <BoxResultat displayChampName={false} resultat={resultat} />
        </Col>
      ))}
    </Row>
  ) : (
    <div className={classnames(className, 'text-center')} {...rest}>
      Pas de résultats pour cette journée
    </div>
  );
};

export default ResultatsList;
