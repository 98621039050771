import { Dayjs } from 'dayjs';
import { IMemberLight } from 'features/member/member.types';
import OmlLink from 'features/transversal/components/OmlLink';
import { OmlText } from 'features/transversal/components/OmlText';
import { FC } from 'react';
import classnames from 'classnames';
import { formatDateWithUntilNow } from 'utils/date';

const Author: FC<{
  auteur: IMemberLight;
  datePublication: Dayjs;
  className?: string;
}> = ({ auteur: { nom, image, link }, datePublication, className, ...rest }) => {
  const rootClassName = classnames('d-flex', className);
  return (
    <OmlLink className={rootClassName} to={link} {...rest}>
      <img alt={nom} className="rounded-circle mr-2" src={image} style={{ width: 36, height: 36 }} />
      <div className="overflow-hidden">
        <OmlText className="font-weight-bold" tag="div" variant="p">
          {nom}
        </OmlText>
        <OmlText tag="div" variant="small">
          {datePublication && datePublication.isValid() ? formatDateWithUntilNow(datePublication) : 'Inconnue'}
        </OmlText>
      </div>
    </OmlLink>
  );
};

export { Author };
