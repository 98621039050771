import { wrapWithOmlLink } from 'features/transversal/components/OmlLink';
import { IOmlEntity } from 'features/transversal/transversal.types';
import NoPhotoImg from 'assets/img/no_photo_thumb.png';
import { IEquipeLight } from 'features/equipe/equipe.types';
import { IChampionnatLight } from 'features/championnat/championnat.types';
import { TooltipWrapper } from 'features/transversal/components/TooltipWrapper';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { ReactNode } from 'react';

const EMPTY_CELL = '-';

export function photoFormatter(cell: string, row: IOmlEntity) {
  const img = <img alt="" className="image-thumb" src={cell || NoPhotoImg} />;
  return wrapWithOmlLink(row.link, img);
}

export function rowLinkFormatter(cell: string, row: IOmlEntity) {
  return wrapWithOmlLink(row.link, cell);
}

export function equipeFormatter(cell: IEquipeLight) {
  return cell ? wrapWithOmlLink(cell.link, cell.nom) : EMPTY_CELL;
}

export function championnatFormatter(cell: IChampionnatLight) {
  return cell ? wrapWithOmlLink(cell.link, `${cell.nom} - ${cell.poule}`) : EMPTY_CELL;
}

export function basicCellFormatter(cell?: string) {
  return cell || EMPTY_CELL;
}

export function tooltipHeaderFormatter(tooltipContent: string) {
  return ({ text }: ColumnDescription) => {
    return <TooltipWrapper tooltipContent={tooltipContent}>{text}</TooltipWrapper>;
  };
}

export function doubleHeaderFormatter(firstHeader: ReactNode, secondHeader: ReactNode) {
  return (
    <>
      {firstHeader}
      {secondHeader}
    </>
  );
}

// function championnatCategorieFormatter(
//  cell: IChampionnatLight,
//  row: IOmlEntity
// ) {
//  return cell && cell.categorie
//    ? wrapWithOmlLink(cell.link, cell.categorie)
//    : EMPTY_CELL;
// }
