import { ActuType, IActu, IApiActu } from './actus.types';
import { routeList } from 'App';
import { formatImage } from 'utils/formatter';
import { axiosPost, useApiGetWithPagination } from 'features/api/apiCalls.utils';
import { apiUrl } from 'features/api/apiCalls.enum';
import { IChampionnat } from 'features/championnat/championnat.types';
import { IEquipe } from 'features/equipe/equipe.types';
import { formatMemberLight } from 'features/member/member.services';
import { omlDayjs } from 'utils/date';
import { formatChampionnatLight } from 'features/championnat/championnat.services';
import { PaginationParams } from 'features/api/apiCalls.types';
import { useGeneralContextValue } from 'features/transversal/general.context';

export function formatActu(actu: IApiActu): IActu {
  return {
    id: actu.id,
    nom: actu.titre,
    auteur: formatMemberLight({ user_id: actu.User_id, nom: actu.nom_prenom || actu.auteur, photo: actu.photo }),
    datePublication: omlDayjs(actu.Cre_dt),
    contenu: actu.contenu,
    image: formatImage(`/public/photo_articles/${actu.image}`),
    champ:
      actu.champ && actu.Champ_id
        ? formatChampionnatLight({
            nom: actu.champ,
            poule: actu.Groupe,
            categorie: actu.Cat_cod,
            genre: actu.Gender,
            id: actu.Champ_id,
            type: 'withId',
          })
        : undefined,
    nbComments: actu.nbComments || 0,
    nbLikes: actu.nbLikes || 0,
    link: `${routeList.actu}/${actu.id}`,
    type: (actu.type || 'divers') as ActuType,
    isLiked: actu.liked === 'true',
  };
}

export function useGetActuList(
  {
    type,
    ...restParams
  }: { champId?: IChampionnat['id']; team_id?: IEquipe['id']; type?: ActuType[] } & PaginationParams,
  isActive?: boolean
) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGetWithPagination<{ elements: IApiActu[]; total: number }>(
    isActive !== false ? apiUrl.actus : null,
    { type: type?.join(','), user_id, ...restParams }
  );
  const flattenData = (data || [])?.reduce((acc: IApiActu[], page) => {
    return page?.elements.length > 0 ? [...acc, ...page.elements] : acc;
  }, []);
  return { actus: flattenData.map((d) => formatActu(d)), ...rest };
}

export function postActu(params: {
  user_id: number;
  title: string;
  text: string;
  type: ActuType;
  champId?: string;
  photo?: File;
}) {
  return axiosPost<IApiActu>(apiUrl.postActu, { sport: 'BBAL', ...params });
}
