import { FC } from 'react';
import GameImg from 'assets/img/game/img.png';
import { useModal } from 'features/layout/layout.utils';
import { IMatch } from '../match.types';
import { postMatchScore } from '../match.services';
import { OmlText } from 'features/transversal/components/OmlText';
import { useAuthModal } from 'features/auth/auth.hook';
import { OmlYup } from 'features/form/form.validation';
import { Formik, Form } from 'formik';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import { isSubmitButtonDisabled } from 'features/form/form.utils';
import { Input } from 'reactstrap';
import { FormikErrorOrFeedback } from 'features/form/components/FormikErrorOrFeedback';

export interface IProps {
  match: IMatch;
  mutateMatch: () => void;
}

const scoreSubmitionValidationSchema = OmlYup.object({
  scoreDomicile: OmlYup.number().score().required(),
  scoreExterieur: OmlYup.number().score().required(),
});
export type ScoreSubmitionFormState = OmlYup.Asserts<typeof scoreSubmitionValidationSchema>;

const ScoreSubmissionModal: FC<IProps> = ({ match: { id, equipeDomicile, equipeExterieur, score }, mutateMatch }) => {
  const { hide } = useModal();
  const initialState: Partial<ScoreSubmitionFormState> = {
    scoreDomicile: score?.domicile,
    scoreExterieur: score?.exterieur,
  };

  const { show, user_id } = useAuthModal();

  function handleSubmit({ scoreDomicile, scoreExterieur }: Partial<ScoreSubmitionFormState>) {
    if (typeof scoreDomicile === 'undefined' || typeof scoreExterieur === 'undefined') {
      return;
    }
    if (!user_id) {
      show({
        informationMessage: 'Tu dois être connecté pour soumettre un score',
        loginProps: {
          onLoginComplete: async (userId: number) => {
            await postMatchScore({
              user_id: userId,
              game_id: id,
              h_team_score: scoreDomicile,
              a_team_score: scoreExterieur,
            });
            return mutateMatch();
          },
        },
      });
    } else {
      postMatchScore({
        user_id,
        game_id: id,
        h_team_score: scoreDomicile,
        a_team_score: scoreExterieur,
      })
        .then(() => {
          return mutateMatch();
        })
        .then(() => {
          hide();
        });
    }
  }

  return (
    <Formik initialValues={initialState} onSubmit={handleSubmit} validationSchema={scoreSubmitionValidationSchema}>
      {({ errors, submitCount, isSubmitting, values, setFieldValue }) => (
        <Form className="d-flex flex-column align-items-center">
          <img alt="" className="cover w-100" src={GameImg} />
          <OmlText className="text-uppercase text-center mt-4 mb-4" flavour="color-title" tag="div" variant="h3">
            entrez le score du match
          </OmlText>
          <div className="d-inline-flex flex-column">
            <div className="mb-3">
              <div className="d-flex align-items-center justify-content-between">
                <div className="mr-3">{equipeDomicile.nom}</div>
                <Input
                  className="no-arrows text-right"
                  min={0}
                  onChange={(e) => setFieldValue('scoreDomicile', Number(e.target.value))}
                  style={{ maxWidth: '50px' }}
                  type="number"
                  value={values.scoreDomicile}
                />
              </div>
              <FormikErrorOrFeedback name="scoreDomicile" />
            </div>

            <div className="mb-4">
              <div className="d-flex align-items-center justify-content-between">
                <div className="mr-3">{equipeExterieur.nom}</div>
                <div style={{ maxWidth: '50px' }}>
                  <Input
                    className="no-arrows text-right"
                    min={0}
                    onChange={(e) => setFieldValue('scoreExterieur', Number(e.target.value))}
                    style={{ maxWidth: '50px' }}
                    type="number"
                    value={values.scoreExterieur}
                  />
                </div>
              </div>
              <FormikErrorOrFeedback name="scoreExterieur" />
            </div>
            <div className="d-flex justify-content-center mb-4">
              <OmlSubmitButton
                disabled={isSubmitButtonDisabled({ isSubmitting, errors, submitCount })}
                isLoading={isSubmitting}
                texte="valider"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ScoreSubmissionModal;
