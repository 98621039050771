import { FC, useState, useContext, useMemo, useEffect } from 'react';
import { Form, FormGroup, Label, Collapse } from 'reactstrap';
import OmlDropdown from 'features/form/components/OmlDropdown';
import { MenuLabel } from '../menu.enum';
import { ISelectOption } from 'features/form/form.types';
import { DisplayMenuResultsDesktop, DisplayMenuResultsMobile, SubMenuNavItem } from './MenuFragments';
import { MenuContext } from './Menu';
import { useGetTownList } from 'features/transversal/transversal.services';
import { useGetClubsFromTown } from 'features/club/club.services';
import { useGetTeamsFromClub } from 'features/equipe/equipe.services';
import { menuItemloadingError, noMenuFilterSelectedLabel } from '../menu.const';
import { Loader } from 'features/layout/components/Loader';
import classnames from 'classnames';
import FetchApiError from 'features/transversal/components/FetchApiError';

interface IProps {
  isPhone?: boolean;
}

const VilleFormGroup: FC<{
  isLoading: boolean;
  options: ISelectOption[];
  onChange: (value: ISelectOption) => void;
  value?: ISelectOption;
  input?: string;
  onInputChange: (val?: string) => void;
}> = ({ options, onChange, value, onInputChange, isLoading, input }) => {
  const name = 'ville';
  const className = 'ville';
  const placeholder = 'Ville';
  return (
    <FormGroup>
      <Label for={className}>{name}</Label>
      <OmlDropdown
        className={className}
        isLoading={isLoading}
        isMulti={false}
        isSearchable={true}
        name={className}
        noOptionsMessage={() => (input ? 'Pas de résultat' : 'Commencez à saisir')}
        onChange={onChange}
        onInputChange={onInputChange}
        options={options}
        placeholder={placeholder}
        value={value}
      />
    </FormGroup>
  );
};

const ClubFormGroup: FC<{
  options: ISelectOption[];
  onChange: (value: ISelectOption) => void;
  value?: ISelectOption;
}> = ({ options, onChange, value }) => {
  const name = 'club';
  const className = 'club';
  const placeholder = 'Club';
  return (
    <FormGroup>
      <Label for={className}>{name}</Label>
      <OmlDropdown
        className={className}
        isMulti={false}
        isSearchable={true}
        name={className}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={value}
      />
    </FormGroup>
  );
};

const SubMenuEquip: FC<IProps> = ({ isPhone }) => {
  const { selectedItem } = useContext(MenuContext);
  const [firstDisplay, setFirstDisplay] = useState(false);

  useEffect(() => {
    if (selectedItem === MenuLabel.equipes) {
      setFirstDisplay(true);
    }
  }, [selectedItem]);

  const [ville, setVille] = useState<ISelectOption>();
  const [club, setClub] = useState<ISelectOption>();
  const [villeInput, setVilleInput] = useState<string>();

  const {
    towns,
    isValidating: isValidatingTowns,
    error: errorTowns,
  } = useGetTownList({ param: villeInput }, firstDisplay);

  const { clubs } = useGetClubsFromTown({ postal: ville?.value }, !!ville);
  const { teams, isValidating, error, mutate } = useGetTeamsFromClub({ club_id: club?.value }, !!club);

  function handleSetVille(val?: ISelectOption) {
    setVille(val);
    setClub(undefined);
  }

  const villeFormGroup = useMemo(() => {
    const optionsVille = isValidatingTowns
      ? [{ label: 'Chargement...', value: 'load' }]
      : towns.map((v) => ({
          label: `${v.Ville} (${v.Postal})`,
          value: v.Postal,
        }));
    return (
      <VilleFormGroup
        input={villeInput}
        isLoading={isValidatingTowns}
        onChange={handleSetVille}
        onInputChange={setVilleInput}
        options={optionsVille}
        value={ville}
      />
    );
  }, [towns, ville, isValidatingTowns, villeInput]);

  const clubFormGroup = useMemo(() => {
    const optionsClub = clubs.map((c) => ({
      label: c.nom,
      value: c.id.toString(),
    }));
    return ville && <ClubFormGroup onChange={setClub} options={optionsClub} value={club} />;
  }, [clubs, ville, club]);

  // Menu mobile
  if (isPhone) {
    return (
      <Collapse className="sub-menu-items" isOpen={selectedItem === MenuLabel.equipes}>
        <Form>
          <SubMenuNavItem hideBorder={true} level={2} name={villeFormGroup} />
          {clubFormGroup && <SubMenuNavItem hideBorder={true} level={2} name={clubFormGroup} />}
        </Form>
        {errorTowns ? (
          <SubMenuNavItem level={2} name={menuItemloadingError} />
        ) : isValidating ? (
          <SubMenuNavItem level={2} name={<Loader />} />
        ) : error ? (
          <SubMenuNavItem level={2} name={menuItemloadingError} />
        ) : club ? (
          <DisplayMenuResultsMobile champs={teams} />
        ) : (
          <SubMenuNavItem level={2} name={noMenuFilterSelectedLabel} />
        )}
      </Collapse>
    );
  }

  // Menu desktop => need to hide the menu instead of removing it in case it is not active to make sure not to loose the form contexts on menu closing
  return (
    <div className={classnames('equipes', selectedItem !== MenuLabel.equipes && 'd-none')}>
      <div className="col">
        <Form className="champ-form">
          {villeFormGroup}
          {clubFormGroup}
        </Form>

        <div>
          <div className="liste-result">
            <div className="title">Equipes</div>
          </div>
          {isValidating ? (
            <div className="w-100">
              <Loader />
            </div>
          ) : error ? (
            <div className="w-100 align-self-center">
              <FetchApiError onRetry={mutate} />
            </div>
          ) : club ? (
            <DisplayMenuResultsDesktop champs={teams} />
          ) : (
            <div className="liste-result">{noMenuFilterSelectedLabel}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubMenuEquip;
