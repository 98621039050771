import { FC } from 'react';
import { IActu } from 'features/actus/actus.types';
import ActusList from 'features/actus/components/ActusList';
import { NewActuButton } from 'features/actus/components/NewActuButton';

interface IProps {
  actus: IActu[];
  mutateActus: () => void;
  champId: string;
  champName: string;
}

const ChampionnatTabActus: FC<IProps> = ({ actus, mutateActus, champId, champName }) => {
  return (
    <>
      <NewActuButton
        className="my-3"
        modalProps={{ mutateActus, type: 'championnat', omlEntityId: champId, entityName: champName }}
      />
      <ActusList actus={actus} className="my-3" />
    </>
  );
};

export { ChampionnatTabActus };
