import { FC } from 'react';
import { IMatch } from '../match.types';
import { PageHeader } from 'features/layout/components/PageHeader';
import OmlLink from 'features/transversal/components/OmlLink';

type IProps = {
  match: IMatch;
};

const MatchPageHeader: FC<IProps> = ({ match: { jour, championnat, journee } }) => {
  if (!championnat) {
    return null;
  }
  const { nom, categorie, pouleLong, link } = championnat;
  return (
    <PageHeader
      heading1={<OmlLink to={`${link}/journee/${journee}`}>{nom}</OmlLink>}
      informations={`Journée n°${journee} (${jour})`}
      subHeading={
        <>
          {categorie} {pouleLong}
        </>
      }
    />
  );
};

export { MatchPageHeader };
