import { FC } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ScoreSubmissionModal from './ScoreSubmitionModal';
import { IMatch } from '../match.types';
import NoPhotoImg from 'assets/img/no_photo_thumb.png';
import { useModal } from 'features/layout/layout.utils';
import RateSubmissionModal from './RateSubmitionModal';
import { useGeneralContextValue } from 'features/transversal/general.context';
import classnames from 'classnames';

import '../styles/index.scss';

export interface IProps {
  match: IMatch;
  mutateMatch: () => void;
}

const MatchHeaderScoreBox: FC<IProps> = ({ match, mutateMatch }) => {
  const { equipeExterieur, equipeDomicile, score } = match;
  const { show } = useModal();
  const { deviceType } = useGeneralContextValue();

  function handleShowModalScore() {
    show({
      children: <ScoreSubmissionModal match={match} mutateMatch={mutateMatch} />,
    });
  }

  function handleShowModalRate() {
    show({
      children: <RateSubmissionModal match={match} mutateMatch={mutateMatch} />,
    });
  }

  return (
    <Container className={classnames('w-100', deviceType === 'desktop' ? 'scoreBox' : 'rating-bar-gradient')}>
      <Row className="row justify-content-center mt-5">
        <Col className="text-center" md={3} xs={3}>
          <img alt="" className="teamLogoGame" src={equipeDomicile.image || NoPhotoImg} />
        </Col>
        <Col md={2} xs={2}>
          <span className="scorePadding mt-5 text-left">{score?.domicile || '-'}</span>
        </Col>
        <Col md={2} xs={2}>
          <span className="scorePadding mt-5 text-right">{score?.exterieur || '-'}</span>
        </Col>
        <Col className="text-center" md={3} xs={3}>
          <img alt="" className="teamLogoGame" src={equipeExterieur.image || NoPhotoImg} />
        </Col>
      </Row>
      <Row className="row justify-content-between mt-3">
        <Col className="text-center" md={5} xs={5}>
          <span className="scoreTeamName">{equipeDomicile.nom}</span>
        </Col>
        <Col className="text-center" md={5} xs={5}>
          <span className="scoreTeamName">{equipeExterieur.nom}</span>
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
        <Col className="text-center">
          <button className="gameButton ghost mb-2" onClick={handleShowModalScore} type="button">
            Propose un score
          </button>
          <br />
          <button className="gameButton" onClick={handleShowModalRate} type="button">
            Donne ton avis
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default MatchHeaderScoreBox;
