import { ISport } from './sports.types';
import BasketImg from 'assets/img/sport/basket.jpg';
import BasketInscImg from 'assets/img/inscription/basket.png';
import FootImg from 'assets/img/sport/foot.jpg';
import FootInscImg from 'assets/img/inscription/foot.png';
import HandImg from 'assets/img/sport/hand.jpg';
import HandInscImg from 'assets/img/inscription/hand.png';
import RugbyImg from 'assets/img/sport/rugby.jpg';
import RugbyInscImg from 'assets/img/inscription/rugby.png';
import VolleyImg from 'assets/img/sport/volley.jpg';
import VolleyInscImg from 'assets/img/inscription/volley.png';

export enum sportListApi {
  basket = 'BBAL',
  foot = 'FOOT',
  hand = 'HAND',
  rugby = 'RUGBY',
  volley = 'VOLLEY',
}

export enum sportList {
  basket = 'Basket-ball',
  foot = 'Football',
  hand = 'Handball',
  rugby = 'Rugby',
  volley = 'Volley-ball',
}

export const sports: ISport[] = [
  {
    name: sportList.basket,
    imgSrc: BasketImg,
    imgInscSrc: BasketInscImg,
    active: true,
  },
  {
    name: sportList.foot,
    imgSrc: FootImg,
    imgInscSrc: FootInscImg,
    active: false,
  },
  {
    name: sportList.hand,
    imgSrc: HandImg,
    imgInscSrc: HandInscImg,
    active: false,
  },
  {
    name: sportList.rugby,
    imgSrc: RugbyImg,
    imgInscSrc: RugbyInscImg,
    active: false,
  },
  {
    name: sportList.volley,
    imgSrc: VolleyImg,
    imgInscSrc: VolleyInscImg,
    active: false,
  },
];
