import '../styles/Alphabet.scss';

interface IProps {
  onClickLetter: (letter: string) => void;
  selectedLetter: string;
}

const Alphabet = (props: IProps) => {
  const { selectedLetter } = props;
  const letters = 'abcdefghijklmnopqrstuvwxyz'.split('');

  const onClickLetter = (letter: string) => {
    props.onClickLetter(letter.toUpperCase());
  };

  return (
    <div className="playerAlphabet mb-2 mt-4 w-100">
      <ul className="ch-grid">
        {letters.map((letter) => (
          <li key={letter} onClick={() => onClickLetter(letter)}>
            <div className="ch-item">
              <div className="ch-info-wrap">
                <div className={selectedLetter === letter.toUpperCase() ? 'ch-info selected' : 'ch-info'}>
                  <div className={selectedLetter === letter.toUpperCase() ? 'ch-info-selected' : 'ch-info-front'}>
                    {letter}
                  </div>
                  <div className="ch-info-back">{letter}</div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Alphabet;
