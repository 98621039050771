import { ComponentPropsWithRef, FC } from 'react';

export type IProps = ComponentPropsWithRef<'div'> & {
  message?: string;
  onRetry?: () => void;
};

const FetchApiError: FC<IProps> = ({ message = 'Une erreur est survenue', onRetry, className, ...rest }) => {
  function handleOnRetry() {
    if (onRetry) {
      onRetry();
    }
  }
  return (
    <div className={`text-center ${className}`} {...rest}>
      <div>{message}</div>
      {onRetry && (
        // eslint-disable-next-line react/button-has-type
        <button className="btn btn-primary my-3 px-2 py-1" onClick={handleOnRetry}>
          Réessayer
        </button>
      )}
    </div>
  );
};

export default FetchApiError;
