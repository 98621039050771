import { routeList } from 'App';
import { apiUrl } from 'features/api/apiCalls.enum';
import { useApiGet } from 'features/api/apiCalls.utils';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { IApiMember, IMember, IMemberLight } from './member.types';
import NoPhotoImg from 'assets/img/no_photo_thumb.png';
import { formatImage } from 'utils/formatter';
import { formatPlayer } from 'features/players/players.services';
import { IApiPlayer, IPlayer } from 'features/players/players.types';
import { joinNomPrenom } from 'utils/strings';

export function formatMemberLight({
  user_id,
  photo,
  nom,
  isPhotoFullPath,
}: {
  user_id: number;
  photo?: string;
  nom: string;
  isPhotoFullPath?: boolean;
}): IMemberLight {
  return {
    id: user_id,
    nom,
    link: `${routeList.member}/${user_id}`,
    image: photo ? (isPhotoFullPath ? photo : formatImage(photo)) : NoPhotoImg,
  };
}

export function formatMember({ header }: IApiMember): IMember {
  let playerDetails: IPlayer | undefined;
  let coachDetails: IPlayer | undefined;

  const { user_id, nom, prenom, storage_path, comments, articles, approved, followers, email, genre } = header[0];

  header.forEach((h) => {
    const { user_id: Player_id, member_type, ...rest } = h;
    const headerAsPlayer: IApiPlayer = { Player_id, ...rest };
    const formatedHeaderAsPlayer = formatPlayer(headerAsPlayer);
    if (member_type === 'player') {
      playerDetails = formatedHeaderAsPlayer;
    } else if (member_type === 'coach') {
      coachDetails = formatedHeaderAsPlayer;
    }
  });

  return {
    id: user_id,
    link: `${routeList.member}/${user_id}`,
    nom,
    prenom,
    email,
    genre,
    nom_prenom: joinNomPrenom({ nom, prenom }),
    image: storage_path ? formatImage(storage_path) : NoPhotoImg,
    playerDetails,
    coachDetails,
    informations: {
      comments,
      articles,
      followers,
      qualitiesApproved: approved,
    },
  };
}

export function useGetMemberInfo(params: { member_id: number }) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGet<IApiMember>(apiUrl.omlmemberinfo, { ...params, user_id });
  return { data: data ? formatMember(data) : undefined, ...rest };
}
