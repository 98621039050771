import { FC, useContext } from 'react';
import { MenuLabel } from '../menu.enum';
import classnames from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NavItem, NavItemProps } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuContext } from 'features/menu/components/Menu';
import styled from 'styled-components';
import { GeneralContext } from 'features/transversal/general.context';

type IProps = {
  itemType: MenuLabel;
  hasSubMenu?: boolean;
  isIcon?: boolean;
  onClick: (itemType: MenuLabel) => void;
  onHover?: (itemType: MenuLabel) => void;
};

const getIcon = (itemType: MenuLabel): IconProp | undefined => {
  switch (itemType) {
    case MenuLabel.favoris:
      return ['far', 'star'];
    case MenuLabel.recherche:
      return 'search';
    case MenuLabel.profil:
      return 'user';
    case MenuLabel.notifications:
      return ['far', 'bell'];
    default:
      return undefined;
  }
};

const StyledNavItem = styled(NavItem)`
  cursor: pointer;
`;

const MenuItem: FC<IProps & Omit<NavItemProps, 'onClick' | 'onHover'>> = ({
  itemType,
  hasSubMenu,
  isIcon,
  onClick,
  onHover,
  className,
  ...rest
}) => {
  const { selectedItem } = useContext(MenuContext);
  const { deviceType } = useContext(GeneralContext);
  const navItemClassName = classnames(className, selectedItem === itemType && 'active', isIcon && 'icon');
  const itemClassName = classnames('d-flex', `align-items-center`, 'justify-content-between');
  const icon = getIcon(itemType);

  function handleOnHover() {
    if (onHover) {
      onHover(itemType);
    }
  }
  function handleOnClick() {
    if (hasSubMenu) {
      if (!onHover) {
        onClick(itemType);
      }
    } else {
      onClick(itemType);
    }
  }

  return (
    <StyledNavItem className={navItemClassName} {...rest}>
      <div className={itemClassName} onClick={handleOnClick} onFocus={handleOnHover} onMouseOver={handleOnHover}>
        <div>
          {icon && <FontAwesomeIcon className="mr-2" icon={icon} size={deviceType === 'desktop' ? 'lg' : 'sm'} />}
          {!isIcon && <span className="menu-label">{itemType}</span>}
        </div>
        {deviceType === 'mobile' && hasSubMenu && (
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={selectedItem === itemType ? 'minus' : 'plus'} size="xs" />
          </div>
        )}
      </div>
    </StyledNavItem>
  );
};

export { MenuItem };
