import { FC, useMemo, useState } from 'react';
import { useAuthModal } from 'features/auth/auth.hook';
import { OmlYup } from 'features/form/form.validation';
import { Formik, Form } from 'formik';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import { isSubmitButtonDisabled } from 'features/form/form.utils';
import { IMember } from '../member.types';
import { OmlFormikField } from 'features/form/components/OmlFormikField';
import { dataURLtoFile } from 'utils/images';
import { useGetPostes } from 'features/players/players.services';
import { Loader } from 'features/layout/components/Loader';
import FetchApiError from 'features/transversal/components/FetchApiError';
import { OmlText } from 'features/transversal/components/OmlText';
import { ISelectOption } from 'features/form/form.types';
import { ClubSubSection } from 'features/inscription/components/InscriptionStepClub';
import { GENRES_OPTIONS } from 'scenes/Auth/Inscription';
import classnames from 'classnames';
import { OmlAccordion } from 'features/transversal/components/OmlAccordion';
import { postMember } from 'features/inscription/inscription.services';

export interface IProps {
  member: IMember;
  mutateMember: () => void;
}

const updateMemberValidationSchema = OmlYup.object({
  avatar: OmlYup.mixed().test('fileSize', "L'image est trop volumineuse (taille maximum 2Mo)", (value) => {
    if (!value?.length) {
      return true;
    }
    return value[0].size <= 2000 * 1000;
  }),
  prenom: OmlYup.string().required().name('prénom').required(),
  nom: OmlYup.string().required().name('nom').required(),
  genre: OmlYup.string()
    .required()
    .oneOf(
      GENRES_OPTIONS.map((o) => o.id),
      'La sélection du genre est obligatoire'
    )
    .required(),

  isPlayer: OmlYup.bool().required(),
  isTrainer: OmlYup.bool().required(),

  poste: OmlYup.string().when('isPlayer', {
    is: true,
    then: OmlYup.string().required(),
  }),
  taille: OmlYup.number().when('isPlayer', {
    is: true,
    then: OmlYup.number().required(),
  }),
  birthdate: OmlYup.date(),

  playerPostal: OmlYup.string().when(['isPlayer', 'isPlayerThisYear'], {
    is: true,
    then: OmlYup.string().required('La sélection de la ville est obligatoire'),
  }),
  playerClub: OmlYup.string().when(['isPlayer', 'isPlayerThisYear', 'playerPostal'], {
    is: true,
    then: OmlYup.string().required('La sélection du club est obligatoire'),
  }),
  playerTeam: OmlYup.string(),
  playerTeamName: OmlYup.string(),
  playerMaillot: OmlYup.string(),
  playerId: OmlYup.string(),

  trainerPostal: OmlYup.string().when(['isTrainer', 'isTrainerThisYear', 'isPlayerThisYear'], {
    is: (isTrainer, isTrainerThisYear, isPlayerThisYear) => (isPlayerThisYear ? false : isTrainer && isTrainerThisYear),
    then: OmlYup.string().required('La sélection de la ville est obligatoire'),
  }),
  trainerClub: OmlYup.string().when(['isTrainer', 'isTrainerThisYear', 'trainerPostal'], {
    is: true,
    then: OmlYup.string().required('La sélection du club est obligatoire'),
  }),
  trainerTeam: OmlYup.string(),
  trainerTeamName: OmlYup.string(),
  trainerId: OmlYup.string(),
});
export type UpdateMemberFormState = OmlYup.Asserts<typeof updateMemberValidationSchema>;

const UpdateMemberProfileModal: FC<IProps> = ({ member, mutateMember }) => {
  const { nom, prenom, playerDetails, coachDetails, image, genre, email } = member;
  const { postes, isValidating: isValidatingPostes, error: errorPostes, mutate: mutatePostes } = useGetPostes({});
  const [showPlayerTeamSelection, setShowPlayerTeamSelection] = useState(false);
  const [showTrainerTeamSelection, setShowTrainerTeamSelection] = useState(false);
  const [selectedSection, setSelectedSection] = useState<'identity' | 'player' | 'trainer'>();

  const initialState: Partial<UpdateMemberFormState> = {
    avatar: dataURLtoFile(image, `${nom}.png`),
    nom,
    prenom,
    genre,
    isPlayer: !!playerDetails,
    isTrainer: !!coachDetails,
    poste: playerDetails?.poste,
    taille: playerDetails?.taille,
    playerTeam: playerDetails?.equipe?.id.toString(),
    playerTeamName: playerDetails?.equipe?.nom,
    playerMaillot: playerDetails?.maillot,
    playerId: playerDetails?.profil_id?.toString(),
  };

  const { show, hide, user_id } = useAuthModal();

  function handleSubmit(values: Partial<UpdateMemberFormState>) {
    if (typeof values.prenom === 'undefined' || typeof values.nom === 'undefined') {
      return;
    }
    const postMemberParams: Omit<Parameters<typeof postMember>[0], 'user_id'> = {
      nom: values.nom,
      prenom: values.prenom,
      avatar: values.avatar,
      email,
      genre: values.genre as 'M' | 'F',
      birthdate: values.birthdate,
      taille: values.taille,
      poste: values.poste,
      playerMaillot: values.playerMaillot,
      isTrainer: !!values.isTrainer,
      isPlayer: !!values.isPlayer,
      playerId: values.playerId,
      trainerId: values.trainerId,
    };
    if (!user_id) {
      show({
        informationMessage: 'Tu dois être connecté pour mettre à jour ton profil',
        loginProps: {
          onLoginComplete: async (userId: number) => {
            await postMember({ user_id: userId, ...postMemberParams });
            return mutateMember();
          },
        },
      });
    } else {
      postMember({ user_id, ...postMemberParams })
        .then(() => {
          return mutateMember();
        })
        .then(() => {
          hide();
        });
    }
  }

  const maillotOptions = useMemo(() => {
    const options: ISelectOption[] = [];
    for (let i = 0; i < 100; i += 1) {
      const formatedNumero = i < 10 ? `0${i}` : i.toString();
      options.push({ value: formatedNumero, label: formatedNumero });
    }
    return options;
  }, []);

  function handleClickPlayerTeamSelect() {
    setShowPlayerTeamSelection(true);
  }

  function handleClickTrainerTeamSelect() {
    setShowTrainerTeamSelection(true);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialState}
      onSubmit={handleSubmit}
      validationSchema={updateMemberValidationSchema}
    >
      {({ errors, submitCount, isSubmitting, values }) => (
        <Form className="px-3 py-4">
          <div className="d-flex align-items-start">
            <div className="mr-4">
              <OmlFormikField defaultImg={values.avatar} name="avatar" subType="avatar" type="file" />
            </div>
            <div>
              <OmlFormikField className="mb-3" fieldLabel="Prénom" name="prenom" type="text" />
              <OmlFormikField fieldLabel="Nom" name="nom" type="text" />
            </div>
          </div>
          <OmlAccordion
            headerTitle="Identité"
            isOpen={selectedSection === 'identity'}
            onHeaderClick={() => setSelectedSection((s) => (s === 'identity' ? undefined : 'identity'))}
          >
            <div className="d-flex mb-2">
              {GENRES_OPTIONS.map((go, i) => (
                <div key={go.id} className={classnames(i < GENRES_OPTIONS.length - 1 && 'mr-3')}>
                  <OmlFormikField
                    checkboxLabel={go.value}
                    name="genre"
                    type="radio"
                    value={go.id}
                    withFeedbacks={false}
                  />
                </div>
              ))}
            </div>
            <div className="d-flex">
              <div className="w-25 mr-5">
                <OmlFormikField
                  fieldClassName="text-right"
                  fieldLabel="Ma taille"
                  max="230"
                  min="100"
                  name="taille"
                  step="1"
                  sufix="cm"
                  type="number"
                />
              </div>
              <div className="w-50">
                <OmlFormikField fieldLabel="Ma date de naissance" isDropdown={true} name="birthdate" type="date" />
              </div>
            </div>
          </OmlAccordion>
          <OmlAccordion
            headerTitle="Mon profil de joueur"
            isOpen={selectedSection === 'player'}
            onHeaderClick={() => setSelectedSection((s) => (s === 'player' ? undefined : 'player'))}
          >
            <div className="w-75">
              <OmlFormikField
                className="mb-3"
                name="isPlayer"
                switchLabel="Je suis joueur"
                type="switch"
                withFeedbacks={false}
              />
            </div>
            {values.isPlayer && (
              <>
                {isValidatingPostes ? (
                  <Loader />
                ) : errorPostes ? (
                  <FetchApiError onRetry={mutatePostes} />
                ) : (
                  <div className="w-75">
                    <OmlFormikField
                      className="mb-2"
                      fieldClassName="w-25"
                      fieldLabel="Mon poste"
                      name="poste"
                      oneLine
                      options={postes}
                      placeholder="Selectionner le poste"
                      type="select"
                      isMulti={false}
                    />

                    <div className="d-flex align-items-center justify-content-between my-2">
                      <OmlText className="mr-3" tag="div" variant="p">
                        Mon équipe :
                      </OmlText>
                      {!showPlayerTeamSelection && (
                        <u>
                          <OmlText
                            className="pointer"
                            flavour="color-title"
                            onClick={handleClickPlayerTeamSelect}
                            tag="div"
                            variant="p"
                          >
                            {values.playerTeamName || 'Sélectionne ton équipe'}
                          </OmlText>
                        </u>
                      )}
                    </div>
                    {showPlayerTeamSelection && (
                      <ClubSubSection
                        fields={{
                          postal: { fieldName: 'playerPostal', value: values.playerPostal },
                          club: { fieldName: 'playerClub', value: values.playerClub },
                          team: { fieldName: 'playerTeam', value: values.playerTeam },
                          id: { fieldName: 'playerId', value: values.playerId },
                        }}
                        type="P"
                      />
                    )}
                    <OmlFormikField
                      fieldClassName="w-25"
                      fieldLabel="Mon maillot"
                      name="playerMaillot"
                      oneLine
                      options={maillotOptions}
                      type="select"
                      isMulti={false}
                    />
                  </div>
                )}
              </>
            )}
          </OmlAccordion>
          <OmlAccordion
            headerTitle="Mon profil d'entraineur"
            isOpen={selectedSection === 'trainer'}
            onHeaderClick={() => setSelectedSection((s) => (s === 'trainer' ? undefined : 'trainer'))}
          >
            <div className="w-75">
              <OmlFormikField
                className="mb-3"
                name="isTrainer"
                switchLabel="Je suis entraineur"
                type="switch"
                withFeedbacks={false}
              />
            </div>
            {values.isPlayer ? (
              <OmlText tag="div" variant="small">
                Tu es déjà lié à ton équipe en tant que joueur
              </OmlText>
            ) : (
              values.isTrainer && (
                <div className="w-75">
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <OmlText className="mr-3" tag="div" variant="p">
                      Mon équipe :
                    </OmlText>
                    {!showTrainerTeamSelection && (
                      <u>
                        <OmlText
                          className="pointer"
                          flavour="color-title"
                          onClick={handleClickTrainerTeamSelect}
                          tag="div"
                          variant="p"
                        >
                          {values.playerTeamName || 'Sélectionne ton équipe'}
                        </OmlText>
                      </u>
                    )}
                  </div>
                  {showTrainerTeamSelection && (
                    <ClubSubSection
                      fields={{
                        postal: { fieldName: 'trainerPostal', value: values.trainerPostal },
                        club: { fieldName: 'trainerClub', value: values.trainerClub },
                        team: { fieldName: 'trainerTeam', value: values.trainerTeam },
                        id: { fieldName: 'trainerId', value: values.trainerId },
                      }}
                      type="C"
                    />
                  )}
                </div>
              )
            )}
          </OmlAccordion>

          <div className="d-flex mt-3 justify-content-center">
            <OmlSubmitButton
              disabled={isSubmitButtonDisabled({ isSubmitting, errors, submitCount })}
              isLoading={isSubmitting}
              texte="valider"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { UpdateMemberProfileModal };
