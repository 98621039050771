import { GenericModalContext } from 'features/transversal/general.context';
import { useContext } from 'react';
import { ModalProps } from 'reactstrap';

export function useModal() {
  const modalRef = useContext(GenericModalContext);

  function show(props: Partial<ModalProps>) {
    if (modalRef?.current) {
      modalRef.current.show(props);
    }
  }

  function hide() {
    if (modalRef?.current) {
      modalRef.current.hide();
    }
  }

  return {
    hide,
    show,
  };
}
