export const separator = '-';

// GENRES
export enum genreList {
  all = 'All',
  masculin = 'Masculin',
  feminin = 'Feminin',
  mixte = 'Mixte',
}

export type GenreShort = 'M' | 'F';
