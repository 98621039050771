import { useState, FC, useMemo } from 'react';
import { useGetMatch } from 'features/match/match.services';
import { useParams } from 'react-router-dom';
import { OmlPageTab, PageContainerWithHeaderAndTabs } from 'features/layout/components/PageContainerWithHeaderAndTabs';
import { MatchPageHeader } from 'features/match/components/MatchPageHeader';
import MatchHeaderScoreBox from 'features/match/components/MatchHeaderScoreBox';
import MatchAveragerating from 'features/match/components/MatchAverageRating';
import MatchCompoTab from 'features/match/components/MatchCompoTab';

const Match: FC = () => {
  const [tab, setTab] = useState(0);
  const { id } = useParams<{ id: string }>();
  const game_id = useMemo(() => (id ? parseInt(id, 10) : -1), [id]);

  const matchSwrState = useGetMatch({ game_id });
  const match = useMemo(() => matchSwrState.data, [matchSwrState]);
  const mutateMatch = useMemo(() => matchSwrState.mutate, [matchSwrState]);

  const tabs: OmlPageTab[] = useMemo(
    () => [
      {
        name: 'compo',
        component: match ? <MatchCompoTab match={match} /> : <></>,
        tabSwrState: matchSwrState,
        options: { scrollable: false },
      },
      {
        name: 'mur',
        component: <div>TODO tab={tab}</div>,
        tabSwrState: matchSwrState,
      },
    ],
    [matchSwrState, match, tab]
  );

  return (
    <PageContainerWithHeaderAndTabs
      browserTabTitle={match?.nom}
      headerComponent={
        match ? (
          <>
            <MatchPageHeader match={match} />
            <MatchHeaderScoreBox match={match} mutateMatch={mutateMatch} />
            <MatchAveragerating match={match} mutateMatch={mutateMatch} />
          </>
        ) : (
          <></>
        )
      }
      headerSwrState={matchSwrState}
      onTabChange={setTab}
      pageTitle="Game"
      tabs={tabs}
    />
  );
};

export default Match;
