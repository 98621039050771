import { ComponentPropsWithoutRef, FC } from 'react';
import { Container } from 'reactstrap';
import classnames from 'classnames';

const PageContainer: FC<ComponentPropsWithoutRef<'div'>> = ({ className, children, ...rest }) => {
  return (
    <Container className={classnames(className, 'my-5')} {...rest}>
      {children}
    </Container>
  );
};

export default PageContainer;
