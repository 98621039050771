import { FC } from 'react';
import { Row, Col } from 'reactstrap';
import { InjectedStepWrapperProps } from './InscriptionStepWrapper';
import { OmlFormikField } from 'features/form/components/OmlFormikField';
import { useFormikContext } from 'formik';
import { InscriptionFormState } from 'scenes/Auth/Inscription';

const InscriptionStepPhoto: FC<InjectedStepWrapperProps> = ({ stepSubmited }) => {
  const { values } = useFormikContext<InscriptionFormState>();
  return (
    <Row className="mb-5 text-center">
      <Col xs={12}>
        <OmlFormikField
          defaultImg={values.avatar}
          fieldClassName="mx-auto"
          name="avatar"
          stepSubmited={stepSubmited}
          subType="avatar"
          type="file"
        />
      </Col>
    </Row>
  );
};

export default InscriptionStepPhoto;
