import { GenericModalProps } from 'features/layout/components/Modal/GenericModal';
import { FAVORITES_DEFAULT } from 'features/user/favoris.services';
import { IFavoris } from 'features/user/favoris.type';
import { IUser } from 'features/user/user.types';
import { createContext, Dispatch, RefObject, SetStateAction, useContext, useMemo, useState } from 'react';
import { City } from 'utils/geogouv-api';
import { useWindowSize, WindowSize } from 'utils/viewport';

type GeneralContextProps = Partial<WindowSize> & {
  selectedCity?: City;
  setSelectedCity?: (newCity: City | undefined) => void;
  user_id?: number;
  user?: IUser;
  setUser?: (newUser: IUser | undefined) => void;
  isAppInit: boolean;
  setIsAppInit?: (newIsUserInit: boolean) => void;
  favoris: IFavoris;
  setFavoris?: Dispatch<SetStateAction<IFavoris>>;
};

export const GeneralContext = createContext<GeneralContextProps>({
  isAppInit: false,
  favoris: FAVORITES_DEFAULT,
});

export function useCreateGeneralContextValue() {
  const windowSize = useWindowSize();
  const [selectedCity, setSelectedCity] = useState<City>();
  const [user, setUser] = useState<IUser>();
  const [isAppInit, setIsAppInit] = useState(false);
  const [favoris, setFavoris] = useState<IFavoris>(FAVORITES_DEFAULT);

  const contextValue: GeneralContextProps = useMemo(
    () => ({
      ...windowSize,
      selectedCity,
      setSelectedCity,
      user,
      user_id: user ? user.id : undefined,
      setUser,
      isAppInit,
      setIsAppInit,
      favoris,
      setFavoris,
    }),
    [windowSize, selectedCity, user, isAppInit, favoris]
  );

  return contextValue;
}

export function useGeneralContextValue() {
  const contextValue = useContext(GeneralContext);
  return contextValue;
}

export const GenericModalContext = createContext<RefObject<GenericModalProps> | null>(null);
