import { FC, SyntheticEvent, useMemo } from 'react';
import OmlLink from 'features/transversal/components/OmlLink';
import { IChampionnatLight } from 'features/championnat/championnat.types';
import { IMatch } from 'features/match/match.types';
import { useNavigate } from 'react-router-dom';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { equipeFormatter } from 'utils/oml-table.utils';
import { IEquipeLight } from '../equipe.types';

import '../styles/Team.scss';

export interface IProps {
  calendar: IMatch[];
  championnat: IChampionnatLight;
}

function scoreFormatter(score: IMatch['score'], { link }: IMatch) {
  const { domicile, exterieur } = score || {};
  return (
    <div>
      {domicile && exterieur ? (
        <>
          <span className={domicile > exterieur ? 'font-weight-bold blue-follow' : ''}>{domicile}</span>-
          <span className={domicile > exterieur ? '' : 'font-weight-bold blue-follow'}>{exterieur}</span>
        </>
      ) : (
        <OmlLink className="points-popup text-nowrap p-1" colorInverted to={link}>
          GAME PAGE
        </OmlLink>
      )}
    </div>
  );
}

const TeamCalendarTab: FC<IProps> = ({ calendar, championnat }: IProps) => {
  const navigate = useNavigate();

  const columns: ColumnDescription[] = useMemo(() => {
    return [
      {
        dataField: 'id',
        text: '',
        hidden: true,
      },
      {
        dataField: 'jour',
        text: 'Date',
      },
      {
        dataField: 'heure',
        text: 'Heure',
      },
      {
        dataField: 'equipeDomicile',
        text: 'Domicile',
        formatter: (cell: IEquipeLight, { score }: IMatch) => (
          <span className={score && score.domicile > score.exterieur ? 'font-weight-bold' : ''}>
            {equipeFormatter(cell)}
          </span>
        ),
      },
      {
        dataField: 'equipeExterieur',
        text: 'Visiteur',
        formatter: (cell: IEquipeLight, { score }: IMatch) => (
          <span className={score && score.domicile < score.exterieur ? 'font-weight-bold' : ''}>
            {equipeFormatter(cell)}
          </span>
        ),
      },
      {
        dataField: 'score',
        text: 'Score',
        formatter: scoreFormatter,
      },
    ];
  }, []);

  function handleRowClick(_: SyntheticEvent, { link }: IMatch) {
    navigate(link);
  }

  return (
    <div className="w-100">
      <div className="tab-title">
        <OmlLink colorInverted to={championnat.link}>
          {championnat.nom}
        </OmlLink>
      </div>
      <BootstrapTable
        bootstrap4={true}
        bordered={false}
        classes="oml-table"
        columns={columns}
        data={calendar}
        keyField="id"
        rowClasses="pointer"
        rowEvents={{ onClick: handleRowClick }}
      />
    </div>
  );
};

export default TeamCalendarTab;
