import { FC } from 'react';
import { Loader } from './Loader';

const FullPageLoader: FC = () => (
  <main className="fullHeight d-flex justify-content-center align-items-center">
    <Loader />
  </main>
);

export { FullPageLoader };
