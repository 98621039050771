import { Button } from 'reactstrap';
import { ComponentPropsWithoutRef, FC } from 'react';
import { useModal } from 'features/layout/layout.utils';
import { PublicationModal, PublicationModalProps } from './PublicationModal';
import { useGeneralContextValue } from 'features/transversal/general.context';

const NewPublicationButton: FC<
  ComponentPropsWithoutRef<'button'> & { buttonTitle: string; modalProps: PublicationModalProps }
> = ({ buttonTitle, modalProps, ...rest }) => {
  const { user_id } = useGeneralContextValue();
  const { show } = useModal();

  function openNewCommentModal() {
    show({ children: <PublicationModal {...modalProps} /> });
  }

  return user_id ? (
    <Button color="primary" onClick={openNewCommentModal} {...rest}>
      {buttonTitle}
    </Button>
  ) : null;
};

export { NewPublicationButton };
