import { apiUrl } from 'features/api/apiCalls.enum';
import { useApiGet } from 'features/api/apiCalls.utils';
import { useMemo } from 'react';
import { IApiVille } from './transversal.types';

export function useGetTownList({ param }: { param?: string }, isActive?: boolean) {
  const validatedParam = useMemo(() => (param && param.length >= 3 ? param.trim() : undefined), [param]);
  const { data, ...rest } = useApiGet<IApiVille[]>(isActive !== false && validatedParam ? apiUrl.towns : null, {
    param: validatedParam,
  });
  return { towns: data || [], ...rest };
}
