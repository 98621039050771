import { FC, useContext } from 'react';

import { MenuLabel } from '../menu.enum';
import { SubMenuNavItem } from './MenuFragments';
import classnames from 'classnames';
import OmlUnderline from 'features/transversal/components/OmlUnderline';
import { sports } from 'features/sport/sports.const';
import { Collapse } from 'reactstrap';
import { MenuContext } from './Menu';

interface IProps {
  isPhone?: boolean;
}

const SubMenuSport: FC<IProps> = ({ isPhone }) => {
  const { selectedItem } = useContext(MenuContext);
  // Menu mobile
  if (isPhone) {
    return (
      <Collapse className="sub-menu-items" isOpen={selectedItem === MenuLabel.sports}>
        {sports
          .filter((sport) => sport.active)
          .map((sport) => (
            <SubMenuNavItem key={sport.name} name={sport.name} />
          ))}
      </Collapse>
    );
  }

  // Menu desktop
  return selectedItem === MenuLabel.sports ? (
    <div className="teaser-list pt-4">
      <div className="img-container">
        {sports.map((sport) => (
          <div key={sport.name}>
            <div key={sport.name} className={classnames('img-anim', sport.active ? 'active' : 'disabled')}>
              <img alt={sport.name} src={sport.imgSrc} />
            </div>
            <div className="text-center mt-2">
              {sport.active ? <OmlUnderline className="titre">{sport.name}</OmlUnderline> : sport.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
export default SubMenuSport;
