import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useState } from 'react';
import { ModalProps } from 'reactstrap';
import ModalContainer from '../ModalContainer';

export interface GenericModalProps {
  show: (props: Partial<ModalProps>) => void;
  hide: () => void;
}

const GenericModal: ForwardRefRenderFunction<GenericModalProps> = (_, modalRef) => {
  // const close = () => {};
  const [{ toggle, ...restModalProps }, setModalProps] = useState<ModalProps>({ isOpen: false });

  const handleToggle: typeof toggle = (e) => {
    if (restModalProps.isOpen) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      hide();
    }
    if (toggle) {
      toggle(e);
    }
  };

  function show(props: Partial<ModalProps>) {
    return setModalProps({ ...props, isOpen: true });
  }

  function hide() {
    return setModalProps({ ...restModalProps, toggle: handleToggle, isOpen: false });
  }

  useImperativeHandle(modalRef, () => ({
    hide,
    show,
  }));

  return <ModalContainer toggle={handleToggle} {...restModalProps} />;
};

export default forwardRef(GenericModal);
