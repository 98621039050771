/* eslint-disable no-param-reassign */
import { FC } from 'react';
import { PlayerPointFortsDetail, PlayerPointsForts, PointsFortsType } from 'features/players/players.types';
import { Button, Col, Row } from 'reactstrap';
import { OmlText } from 'features/transversal/components/OmlText';
import { useModal } from 'features/layout/layout.utils';
import PlayerPointsFortsModal from 'features/players/components/PlayerPointsFortsModal';
import { IMember } from '../member.types';

import '../styles/index.scss';

export interface IProps {
  member: IMember;
  playerPointsForts: PlayerPointsForts;
  mutatePointsForts: () => void;
}

const digitSize = 90;

const PointFortSingle: FC<{ detail: PlayerPointFortsDetail }> = ({ detail: { skill, total, votes } }) => {
  return (
    <div className="d-flex mr-3 mb-3 align-items-start">
      <div
        className="mr-2 text-right"
        style={{ fontSize: `${digitSize}px`, lineHeight: `${digitSize}px`, fontWeight: 'bold', minWidth: 95 }}
      >
        {(total || 0) < 10 ? `0${total}` : total}
      </div>
      <div className="d-flex flex-column justify-content-between" style={{ height: `${digitSize}px` }}>
        <OmlText className="mb-2" style={{ fontWeight: 'bold' }} tag="div" variant="big">
          {skill}
        </OmlText>
        <div>
          <OmlText className="uppercase" tag="div" variant="small">
            Votes:
          </OmlText>
          <OmlText tag="div" variant="small">
            {votes}
          </OmlText>
        </div>
      </div>
    </div>
  );
};

const MemberPointsFortsDetail: FC<{
  details: PlayerPointFortsDetail[];
  type: PointsFortsType;
  className?: string;
}> = ({ details, type, className }) => {
  return (
    <div className={className}>
      <OmlText className="mb-3" flavour="color-title" variant="h3">
        {type === 'mental' ? 'Mentaux' : type === 'physic' ? 'Physiques' : 'Techniques'}
      </OmlText>
      <Row>
        {details.length ? (
          details.map((d) => (
            <Col key={d.id} md={6} xs={12}>
              <PointFortSingle detail={d} />
            </Col>
          ))
        ) : (
          <Col xs={12}>
            <OmlText variant="p">Aucun point fort sélectionné pour l'instant</OmlText>
          </Col>
        )}
      </Row>
    </div>
  );
};

const MemberPointsFortsTab: FC<IProps> = ({ playerPointsForts, member, mutatePointsForts }) => {
  const { show } = useModal();
  const { mental, physic, technic } = playerPointsForts;
  function showPlayerPointsFortsModal() {
    show({
      children: (
        <PlayerPointsFortsModal
          mutatePointsForts={mutatePointsForts}
          playerId={member.id}
          playerName={member.nom_prenom}
          votedPointsForts={playerPointsForts}
        />
      ),
    });
  }

  return (
    <div className="w-100">
      <Button className="my-3" color="primary" onClick={showPlayerPointsFortsModal}>
        Voter pour les points forts
      </Button>

      <MemberPointsFortsDetail className="mb-5" details={mental} type="mental" />
      <MemberPointsFortsDetail className="mb-5" details={physic} type="physic" />
      <MemberPointsFortsDetail details={technic} type="technic" />
    </div>
  );
};

export { MemberPointsFortsTab };
