import ActiveFavoriteImg from 'assets/img/pictos/follow.png';
import NonActiveFavoriteImg from 'assets/img/pictos/unfollow.png';
import { useAuthModal } from 'features/auth/auth.hook';
import { newToast } from 'features/layout/components/Toaster';
import { postFavori } from 'features/user/favoris.services';
import { FavorisType } from 'features/user/favoris.type';
import { FC, useMemo } from 'react';
import { useGeneralContextValue } from '../general.context';
import { severityLevel } from 'features/layout/layout.enums';

interface IProps {
  id: number;
  type: FavorisType;
}

const AddToFavorite: FC<IProps> = ({ id, type }) => {
  const { favoris, setFavoris } = useGeneralContextValue();
  const isActive = useMemo(() => {
    const favorisInType = favoris[type];
    return favorisInType.findIndex((f) => f.id === id) > -1;
  }, [favoris, id, type]);

  function handlePostFavori(userId: number) {
    if (!userId) {
      return Promise.reject();
    }
    return postFavori({ isAdd: !isActive, user_id: userId, follow_id: id, follow_type: type })
      .then((newFav) => {
        if (setFavoris) {
          setFavoris(newFav);
        }
      })
      .then(() => {
        newToast({ message: isActive ? 'Retiré des favoris' : 'Ajouté en favoris', severity: severityLevel.success });
      })
      .catch(() => {
        newToast({
          message: `Erreur lors ${isActive ? 'du retrait des' : "de l'ajout en"} favoris. Merci de réessayer plus tard`,
          severity: severityLevel.error,
        });
      });
  }

  const { show, user_id } = useAuthModal();

  const toggleFavori = () => {
    if (user_id) {
      handlePostFavori(user_id);
    } else {
      show({
        informationMessage: 'Tu dois être connecté pour utiliser la fonction favoris',
        loginProps: { onLoginComplete: handlePostFavori },
      });
    }
  };

  return (
    <img
      alt={isActive ? 'Supprimer favoris' : 'Ajouter favoris'}
      className="img-favoris pointer"
      onClick={toggleFavori}
      src={isActive ? ActiveFavoriteImg : NonActiveFavoriteImg}
      title={isActive ? 'Supprimer de mes favoris' : 'Ajouter à mes favoris'}
    />
  );
};

export default AddToFavorite;
