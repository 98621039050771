import { ICommentIndented } from 'features/transversal/comments.types';
import { ComponentPropsWithRef, FC, MouseEventHandler, RefObject, useState } from 'react';
import { Author } from './Author';
import { LikeButton } from 'features/transversal/components/LikeButton';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { useModal } from 'features/layout/layout.utils';
import ConfirmationModal from 'features/layout/components/ConfirmationModal';
import { deleteActuComment, deletePublicationComment } from 'features/transversal/comments.services';
import { SWRResponse } from 'swr';
import { Loader } from 'features/layout/components/Loader';
import { OmlTextAreaHandle } from 'features/form/components/OmlInput/OmlTextArea';
import { IconWithTooltip } from 'features/transversal/components/IconWithTooltip';
import classnames from 'classnames';
import { TooltipWrapper } from 'features/transversal/components/TooltipWrapper';
import { OmlText } from 'features/transversal/components/OmlText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ActuComment: FC<
  {
    comment: ICommentIndented;
    elementId: number;
    mutate: SWRResponse<any, any>['mutate'];
    inputRef: RefObject<OmlTextAreaHandle>;
    onReplyToggle?: (id: number) => void;
    type: 'actu' | 'publication';
  } & ComponentPropsWithRef<'div'>
> = ({
  comment: { id, contenu, auteur, datePublication, isLiked, nbLikes, parent, children },
  elementId,
  className,
  mutate,
  inputRef,
  onReplyToggle,
  type,
  ...rest
}) => {
  const [repliesVisible, setRepliesVisible] = useState(false);
  const [nbLikesInternal, setNbLikesInternal] = useState(nbLikes);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const { user_id } = useGeneralContextValue();
  const { show } = useModal();

  function handleLikeComment(modificator: number) {
    setNbLikesInternal((current) => current + modificator);
  }

  function handleModifyComment() {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.updateValue(contenu, { commentId: id });
  }

  async function handleDeleteComment() {
    if (!user_id) {
      return;
    }
    setIsDeleteLoading(true);

    if (type === 'actu') {
      await deleteActuComment({ user_id, news_id: elementId, comment_id: id });
    }
    if (type === 'publication') {
      await deletePublicationComment({ action_id: elementId, comment_id: id });
    }

    mutate();
    setIsDeleteLoading(false);
  }

  const handleReplyMessage: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    if (!inputRef.current) {
      return;
    }
    inputRef.current.updateValue([''], { parentId: id });
  };

  function showDeleteConfirmationModal() {
    show({
      children: (
        <ConfirmationModal
          message="Es tu sur de vouloir supprimer ton message ?"
          onConfirm={handleDeleteComment}
          title="Suppression de message"
        />
      ),
    });
    return Promise.resolve();
  }

  const actuCommentIconClassnames = classnames('d-flex', 'align-items-start', 'pointer');

  const hasReplies = children.length > 0;

  function handleReplyToggle() {
    if (!hasReplies) {
      return;
    }
    setRepliesVisible((current) => !current);
    if (onReplyToggle) {
      onReplyToggle(id);
    }
  }

  return (
    <div className={className} id={`comment-${id}`} {...rest}>
      <div className="d-flex justify-content-between">
        <Author auteur={auteur} className="mb-2" datePublication={datePublication} />
        <div className="d-flex color-title" style={{ height: 'fit-content' }}>
          {auteur.id === user_id ? (
            <>
              {type === 'actu' && (
                <IconWithTooltip
                  className={classnames(actuCommentIconClassnames, 'mr-2')}
                  icon="edit"
                  onClick={handleModifyComment}
                  tooltipContent="Modifier"
                />
              )}
              {isDeleteLoading ? (
                <Loader className={classnames(actuCommentIconClassnames, 'd-inline-block')} size={18} />
              ) : (
                <IconWithTooltip
                  className={classnames(actuCommentIconClassnames, 'd-inline-block', 'mr-2')}
                  icon="trash"
                  onClick={showDeleteConfirmationModal}
                  tooltipContent="Supprimer"
                />
              )}
            </>
          ) : (
            <TooltipWrapper className={actuCommentIconClassnames} tooltipContent="Like">
              <LikeButton
                className="d-inline-block"
                id={id}
                isLiked={isLiked}
                nbLikes={nbLikes}
                onLikeComplete={handleLikeComment}
                size={18}
                type="comment"
              />
              <span style={{ fontSize: 11 }}>
                 (
                {nbLikesInternal})
              </span>
            </TooltipWrapper>
          )}
        </div>
      </div>
      <div className="ml-5">
        {contenu.map((l, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <p key={i} className="m-0">
            {l}
          </p>
        ))}
      </div>
      {hasReplies && (
        <>
          <OmlText className="text-right pointer" onClick={handleReplyToggle} tag="div" variant="small">
            <u>{`${children.length} réponse${children.length > 1 ? 's' : ''}`}</u>
          </OmlText>
          {repliesVisible &&
            children.map((answer, i) => (
              <ActuComment
                key={answer.id}
                className={classnames('pl-5', 'pt-3', i !== children.length - 1 ? 'pb-3' : undefined)}
                comment={{ ...answer, children: [] }}
                elementId={elementId}
                inputRef={inputRef}
                mutate={mutate}
                type={type}
              />
            ))}
        </>
      )}
      {!parent && (hasReplies ? repliesVisible : true) && (
        <div className="pointer d-flex justify-content-end" onClick={handleReplyMessage}>
          <div className="d-inline-flex align-items-center">
            <FontAwesomeIcon className={classnames(actuCommentIconClassnames, 'mr-2')} icon="reply" />
            <u>
              <OmlText tag="span" variant="small">
                Répondre
              </OmlText>
            </u>
          </div>
        </div>
      )}
    </div>
  );
};

export { ActuComment };
