import { useAuthModal } from 'features/auth/auth.hook';
import { FormikErrorOrFeedback } from 'features/form/components/FormikErrorOrFeedback';
import OmlTextArea, { OmlTextAreaHandle } from 'features/form/components/OmlInput/OmlTextArea';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import { isSubmitButtonDisabled } from 'features/form/form.utils';
import { OmlYup } from 'features/form/form.validation';
import { Loader } from 'features/layout/components/Loader';
import { postComment, useGetComments } from 'features/transversal/comments.services';
import FetchApiError from 'features/transversal/components/FetchApiError';
import { OmlText } from 'features/transversal/components/OmlText';
import { Formik, Form, FormikHelpers } from 'formik';
import { FC, useEffect, useMemo, useRef } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { IActu } from '../actus.types';
import { ActuComment } from './ActuComment';
import classnames from 'classnames';
import { ActuCommentParentMessage } from './ActuCommentParentMessage';
import { ICommentIndented } from 'features/transversal/comments.types';
import { Publication } from 'features/publication/publication.types';

const actuCommentSchema = OmlYup.object({
  contenu: OmlYup.array(OmlYup.string().required()).required(),
  commentId: OmlYup.number(),
  parentId: OmlYup.number(),
});
export type ActuCommentFormState = OmlYup.Asserts<typeof actuCommentSchema>;
const initialState: ActuCommentFormState = {
  contenu: [],
  commentId: undefined,
  parentId: undefined,
};

const ActuComments: FC<{ actu: IActu | Publication }> = ({ actu }) => {
  const { id: elementId } = actu;
  const isPublication = actu && !actu.image && actu.type === 'divers';

  const commentsListContainer = useRef<HTMLDivElement>(null);
  const commentsInputHandleRef = useRef<OmlTextAreaHandle>(null);

  const {
    data: comments,
    isValidating,
    error,
    mutate,
  } = useGetComments({
    elementId,
    type: isPublication ? 'publication' : 'actu',
  });

  const indentedComments = useMemo(
    () =>
      (comments || []).reduce((acc: ICommentIndented[], comment) => {
        if (!comment.parent) {
          return [...acc, { ...comment, children: [] }];
        }
        const parentElement = acc.find((c) => c.id === comment.parent);
        if (!parentElement) {
          console.error(`Commentaire id ${parentElement} introuvable pour le commentaire ${comment.id} `);
          return acc;
        }
        parentElement.children.push(comment);
        return acc;
      }, []),
    [comments]
  );

  const { user_id, show } = useAuthModal();

  function handleOnSubmit(values: ActuCommentFormState, { resetForm }: FormikHelpers<ActuCommentFormState>) {
    function afterSubmitComplete() {
      mutate();
      return resetForm();
    }

    if (user_id) {
      return postComment({
        user_id,
        elementId,
        body: values.contenu,
        comment_id: values.commentId,
        parent_id: values.parentId,
        type: isPublication ? 'publication' : 'actu',
      }).then(afterSubmitComplete);
    }
    return show({
      informationMessage: 'Tu dois être connecté pour commenter une actu',
      loginProps: {
        onLoginComplete: async (userId: number) => {
          return postComment({
            user_id: userId,
            elementId,
            body: values.contenu,
            comment_id: values.commentId,
            parent_id: values.parentId,
            type: isPublication ? 'publication' : 'actu',
          }).then(afterSubmitComplete);
        },
      },
    });
  }

  useEffect(() => {
    if (!commentsListContainer.current) {
      return;
    }
    commentsListContainer.current?.scrollTo({ top: commentsListContainer.current.scrollHeight, behavior: 'smooth' });
  }, [comments]);

  function handleReplyToggle(commentId: number) {
    setTimeout(() => {
      const commentWrapper = commentsListContainer.current?.querySelector(`#comment-${commentId}`) as
        | HTMLDivElement
        | undefined;
      const height = commentWrapper?.offsetHeight;
      const offsetTop = commentWrapper?.offsetTop;
      if (commentsListContainer.current && offsetTop && height) {
        commentsListContainer.current.scrollTo({ top: offsetTop + height, left: 0, behavior: 'smooth' });
      }
    }, 100);
  }

  return (
    <Row className="mt-3">
      <Col xs={12}>
        {isValidating ? (
          <Loader />
        ) : error ? (
          <FetchApiError onRetry={mutate} />
        ) : (
          <div ref={commentsListContainer} className="overflow-y-scroll" style={{ maxHeight: '50vh' }}>
            {indentedComments.length === 0 ? (
              <OmlText tag="div" variant="p">
                Aucun commentaire pour l'instant soyez le premier
              </OmlText>
            ) : (
              indentedComments.map((comment, i) => (
                <ActuComment
                  key={comment.id}
                  className={classnames('pt-3', 'pb-3', i > 0 && 'border-top-grey')}
                  comment={comment}
                  elementId={elementId}
                  inputRef={commentsInputHandleRef}
                  mutate={mutate}
                  onReplyToggle={handleReplyToggle}
                  type={isPublication ? 'publication' : 'actu'}
                />
              ))
            )}
          </div>
        )}
      </Col>
      <Col xs={12}>
        <Formik initialValues={initialState} onSubmit={handleOnSubmit} validationSchema={actuCommentSchema}>
          {({ errors, submitCount, isSubmitting, values, setFieldValue, resetForm }) => {
            const parentComment =
              values.parentId && indentedComments && indentedComments.find((c) => c.id === values.parentId);
            return (
              <>
                {parentComment && (
                  <ActuCommentParentMessage
                    className="my-2"
                    comment={parentComment}
                    onCancel={() => {
                      setFieldValue('parentId', undefined);
                    }}
                  />
                )}
                <Form className="d-flex align-items-end">
                  <div className="flex-1 d-flex align-items-end mr-3">
                    <OmlTextArea
                      ref={commentsInputHandleRef}
                      disabled={isSubmitting}
                      maxRows={6}
                      minRows={1}
                      onChange={(value, options) => {
                        if (options?.commentId) {
                          setFieldValue('commentId', options.commentId);
                          setFieldValue('parentId', undefined);
                        } else if (options?.parentId) {
                          setFieldValue('commentId', undefined);
                          setFieldValue('parentId', options.parentId);
                        }
                        setFieldValue('contenu', value);
                      }}
                      placeholder="Laisse un commentaire"
                      value={values.contenu}
                    />
                  </div>
                  <div className="d-flex">
                    {values.commentId && (
                      <Button className="mr-3" color="secondary" onClick={() => resetForm()}>
                        Annuler
                      </Button>
                    )}
                    <OmlSubmitButton
                      disabled={isSubmitButtonDisabled({ isSubmitting, errors, submitCount })}
                      isLoading={isSubmitting}
                      texte="Envoyer"
                    />
                  </div>
                </Form>
                <FormikErrorOrFeedback name="contenu" />
              </>
            );
          }}
        </Formik>
      </Col>
    </Row>
  );
};

export { ActuComments };
