import { useAuthModal } from 'features/auth/auth.hook';
import { FC, useState } from 'react';
import LikeImg from 'assets/img/pictos/like.png';
import LikeEmptyImg from 'assets/img/pictos/like-empty.png';
import { postLike, LikeType } from 'features/transversal/like.services';
import { Loader } from 'features/layout/components/Loader';
import classnames from 'classnames';

const LikeButton: FC<{
  isLiked: boolean;
  nbLikes: number;
  id: number;
  size?: number;
  className?: string;
  onLikeComplete: (modificator: number) => void;
  type: LikeType;
}> = ({ isLiked, id, size = 20, onLikeComplete, className, type }) => {
  const [isLikedInternal, setIsLikedInternal] = useState(isLiked);
  const [isLikeLoading, setIsLikeLoading] = useState(false);

  function afterSubmitComplete() {
    setIsLikedInternal((current) => !current);
    setIsLikeLoading(false);
    onLikeComplete(isLikedInternal ? -1 : 1);
  }

  const { user_id, show } = useAuthModal();

  function handleLikeActu() {
    setIsLikeLoading(true);
    if (user_id) {
      postLike({ user_id, resource_id: id, type, unlike: isLiked }).then(afterSubmitComplete);
    } else {
      show({
        informationMessage: `Tu dois être connecté pour liker ${type === 'actu' ? 'une actu' : 'un commentaire'} `,
        loginProps: {
          onLoginComplete: async (userId: number) => {
            return postLike({
              user_id: userId,
              resource_id: id,
              type: 'actu',
              unlike: isLiked,
            }).then(afterSubmitComplete);
          },
        },
      });
    }
  }

  return isLikeLoading ? (
    <Loader className={className} size={size} />
  ) : (
    <img
      alt=""
      className={classnames('pointer', className)}
      onClick={handleLikeActu}
      src={isLikedInternal ? LikeImg : LikeEmptyImg}
      style={{ width: size }}
    />
  );
};

export { LikeButton };
