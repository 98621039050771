import {
  IApiPlayerPointsForts,
  IApiStatistics,
  IPlayer,
  IApiPointFort,
  IApiPlayer,
  IApiPlayerLight,
  IPlayerLight,
  IPlayerStatistics,
  IPlayerStatisticsDetail,
  IApiStatCommon,
  PlayerPointsForts,
  PlayerPointFortsDetail,
  PointsFortsType,
} from './players.types';
import { routeList } from 'App';
import { formatChampionnatLight } from '../championnat/championnat.services';
import { formatClubLight } from '../club/club.services';
import { formatEquipeLight } from '../equipe/equipe.services';
import { formatImage } from 'utils/formatter';
import NoPhotoImg from 'assets/img/no_photo_thumb.png';
import { axiosPost, useApiGet } from 'features/api/apiCalls.utils';
import { apiUrl } from 'features/api/apiCalls.enum';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { sportListApi } from 'features/sport/sports.const';
import { joinNomPrenom } from 'utils/strings';
import { newToast } from 'features/layout/components/Toaster';
import { severityLevel } from 'features/layout/layout.enums';
import { ICheckboxGroupOption } from 'features/form/form.types';

export function formatPlayer(player: IApiPlayer): IPlayer {
  const championnat =
    player.Champ_id && player.championnat
      ? formatChampionnatLight({
          id: player.Champ_id,
          nom: player.championnat,
          poule: player.groupe,
          type: 'withId',
        })
      : undefined;

  const club =
    player.club && player.Club_id
      ? formatClubLight({
          Club_id: player.Club_id,
          Club_name: player.club,
        })
      : undefined;

  const equipe =
    player.equipe_id && player.equipe && player.Club_id
      ? formatEquipeLight({
          Profil_id: player.equipe_id,
          nom: player.equipe,
          Club_id: player.Club_id,
        })
      : undefined;

  const maillotNumber = player.maillot || player.numero;
  const image = player.storage_path || player.photo;
  const taille = typeof player.taille === 'number' ? player.taille : player.taille ? Number(player.taille) : undefined;
  return {
    id: player.Player_id,
    link: `${routeList.member}/${player.Player_id}`,
    nom: player.nom,
    prenom: player.prenom,
    nom_prenom: joinNomPrenom({ nom: player.nom, prenom: player.prenom }),
    image: image ? formatImage(image) : NoPhotoImg,
    profil_id: player.Profil_id,
    poste: player.poste,
    taille,
    tailleWithSuffix: taille ? `${taille}cm` : undefined,
    championnat,
    club,
    equipe,
    maillot:
      maillotNumber && typeof maillotNumber === 'number'
        ? maillotNumber < 10
          ? `0${maillotNumber}`
          : maillotNumber.toString()
        : undefined,
    categorie: player.categorie,
  };
}

export function formatPlayerLight({ Player_id, name }: IApiPlayerLight): IPlayerLight {
  return { id: Player_id, nom: name, link: `${routeList.member}/${Player_id}` };
}

function formatPlayerStatisticsDetailWithoutType({
  id,
  played,
  starter,
  mins,
  pts,
  made2,
  att2,
  pct2,
  made3,
  att3,
  pct3,
  made_tot,
  att_tot,
  pct_tot,
  made_lf,
  att_lf,
  pct_lf,
  reb,
  ctr,
  asst,
  stls,
  lb,
  // fpr,
  fte,
}: IApiStatCommon): Omit<IPlayerStatisticsDetail, 'type'> {
  return {
    id,
    starter: starter === null || starter === undefined ? undefined : starter === 1,
    hasPlayed: played === null || played === undefined ? undefined : played === 1,
    minutes: mins || undefined,
    totalPoints: pts || undefined,
    totalShots: {
      success: made_tot || undefined,
      attempt: att_tot || undefined,
      rate: pct_tot || undefined,
    },
    twoPoints: {
      success: made2 || undefined,
      attempt: att2 || undefined,
      rate: pct2 || undefined,
    },
    threePoints: {
      success: made3 || undefined,
      attempt: att3 || undefined,
      rate: pct3 || undefined,
    },
    freeShots: {
      success: made_lf || undefined,
      attempt: att_lf || undefined,
      rate: pct_lf || undefined,
    },
    rebounds: reb || undefined,
    counters: ctr || undefined,
    decisivePasses: asst || undefined,
    stls: stls || undefined,
    lb: lb || undefined,
    fouls: fte || undefined,
  };
}

function formatPlayerStatistics({
  status,
  updateMode,
  stats,
  total,
  moyen,
  record,
}: IApiStatistics): IPlayerStatistics {
  if (status !== 'ok') {
    return { isError: true, updateMode: false, details: [] };
  }

  const details: IPlayerStatisticsDetail[] = [
    ...stats.map((s) => ({
      ...formatPlayerStatisticsDetailWithoutType(s),
      type: 'game' as const,
      game: {
        Sched_id: s.Sched_id,
        sched_num: s.sched_num,
        opponent: s.opponent,
        place: s.place,
        score: s.score,
        result: s.result,
      },
    })),
  ];

  if (moyen.length) {
    details.push({ ...formatPlayerStatisticsDetailWithoutType(moyen[0]), type: 'average' as const });
  }
  if (total.length) {
    details.push({ ...formatPlayerStatisticsDetailWithoutType(total[0]), type: 'total' as const });
  }
  if (record.length) {
    details.push({ ...formatPlayerStatisticsDetailWithoutType(record[0]), type: 'record' as const });
  }

  return {
    isError: false,
    updateMode: updateMode === 'yes',
    details,
  };
}

export function useGetPlayers() {
  const { data, ...rest } = useApiGet<IApiPlayer[]>(apiUrl.players);
  return { players: (data || []).map(formatPlayer), ...rest };
}

export function useGetStatsPlayer(params: { member_id: number }, isActive?: boolean) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGet<IApiStatistics>(isActive !== false ? apiUrl.statsplayer : null, {
    ...params,
    user_id,
  });
  return { data: data ? formatPlayerStatistics(data) : undefined, ...rest };
}

export function postStats(
  params: { id: number; user_id: number } & Partial<Omit<IApiStatCommon, 'id' | 'Profil_id'>>,
  gameName = 'Statistiques'
) {
  return axiosPost(apiUrl.savestats, params).then(() => {
    newToast({ message: `${gameName} bien mise à jour`, severity: severityLevel.success });
  });
}

export function useGetCareerPlayer(params: { member_id: number }, isActive?: boolean) {
  const { data, ...rest } = useApiGet<any>(isActive !== false ? apiUrl.getCareer : null, params);
  return { career: data, ...rest };
}

export function postCareer(params: {
  user_id: number;
  year1: number;
  year2: number;
  club: string;
  cat_cod: string;
  level_name: string;
}) {
  return axiosPost(apiUrl.addcareer, params);
}

export const POINT_FORT_ID_PREFIX = 'pf-';

function fromApiPointFortArrayToOption(arr?: IApiPointFort[]): ICheckboxGroupOption[] {
  return (arr || []).map((pf) => {
    const { pf_id, pf_label } = pf;

    return { id: `${POINT_FORT_ID_PREFIX}${pf_id}`, label: pf_label };
  });
}
export function useGetPointsForts(isActive?: boolean) {
  const { data, ...rest } = useApiGet<{
    mental: IApiPointFort[];
    physic: IApiPointFort[];
    technic: IApiPointFort[];
  }>(isActive !== false ? apiUrl.pointsForts : null);
  const { mental, physic, technic } = data || {};

  return {
    mental: fromApiPointFortArrayToOption(mental),
    physic: fromApiPointFortArrayToOption(physic),
    technic: fromApiPointFortArrayToOption(technic),
    ...rest,
  };
}

export function useGetPlayerPointsForts(params: { member_id: number; user_id?: number }, isActive?: boolean) {
  const { data: rawData, ...rest } = useApiGet<IApiPlayerPointsForts>(
    isActive !== false ? apiUrl.evaluation : null,
    params
  );
  const myVotes = rawData?.myVotes || [];

  function fromEvaluationToPlayerPointFortsDetails(
    evaluation: IApiPlayerPointsForts['evaluation'],
    type: PointsFortsType
  ): PlayerPointFortsDetail[] {
    const detailFiltered = evaluation.find((e) => e.domain === type)?.detail?.filter((d) => d.pf_id && d.skill) || [];
    return detailFiltered.map((d) => ({
      id: d.pf_id || 0,
      skill: d.skill || '',
      total: d.total || 0,
      votes: d.votes || '',
      myVote: myVotes.findIndex((v) => v.pf_id === d.pf_id) > -1,
    }));
  }

  const mental = fromEvaluationToPlayerPointFortsDetails(rawData?.evaluation || [], 'mental');
  const physic = fromEvaluationToPlayerPointFortsDetails(rawData?.evaluation || [], 'physic');
  const technic = fromEvaluationToPlayerPointFortsDetails(rawData?.evaluation || [], 'technic');

  const data: PlayerPointsForts = {
    mental,
    physic,
    technic,
  };
  return {
    data,
    ...rest,
  };
}

export function postPointsForts(params: { user_id: number; member_id: number; ids: number[] }) {
  return axiosPost(apiUrl.savepointsforts, params);
}

export function postLikePointsForts(params: { user_id: number; member_id: number; pf_id: number }) {
  return axiosPost(apiUrl.likeSkill, params);
}

export function useGetPostes({ sport = sportListApi.basket }: { sport?: sportListApi }, isActive?: boolean) {
  const { data, ...rest } = useApiGet<{ poste: string }[]>(
    isActive !== false ? `${apiUrl.postes}?sport=${sport}` : null
  );

  return {
    postes: data && data.length ? data.map(({ poste }) => ({ value: poste, label: poste })) : [],
    ...rest,
  };
}

export function useGetPlayersForTeam({ team_id, type }: { team_id: string; type: 'P' | 'C' }, isActive?: boolean) {
  const { data, ...rest } = useApiGet<IApiPlayer[]>(
    isActive !== false ? `${apiUrl.playersTeam}?team_id=${team_id}&type=${type}` : null
  );

  return { data: (data || []).map(formatPlayer), ...rest };
}
