import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import { useAuthModal } from 'features/auth/auth.hook';
import { POINT_FORT_ID_PREFIX, postPointsForts, useGetPlayerPointsForts, useGetPointsForts } from '../players.services';
import { Formik, Form } from 'formik';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import { PlayerPointsFortsFormFragment } from './PlayerPointsFortsFormFragment';
import { OmlYup } from 'features/form/form.validation';
import { Loader } from 'features/layout/components/Loader';
import FetchApiError from 'features/transversal/components/FetchApiError';
import { PlayerPointsForts, PlayerPointFortsDetail } from '../players.types';

export interface IProps {
  playerId: number;
  playerName: string;
  mutatePointsForts?: () => void;
  votedPointsForts?: PlayerPointsForts;
}

const pointsFortsSchema = OmlYup.object({
  mentaux: OmlYup.object().maxValues(3, 'compétences'),
  physiques: OmlYup.object().maxValues(3, 'compétences'),
  techniques: OmlYup.object().maxValues(6, 'compétences'),
});
export type PointsFortsFormState = OmlYup.Asserts<typeof pointsFortsSchema>;

const ContainerWrapper = styled.div`
  .title-modal {
    width: 319px;
    height: 43px;
    font-family: Anton;
    font-size: 22px;
    color: #0c15a0;
  }
  .subHeading-modal {
    width: 47px;
    height: 9px;
    font-family: Poppins;
    color: #0c15a0;
    font-size: 12px;
    font-weight: bold;
  }
  .okButton button {
    width: 132px;
    height: 34px;
    border-radius: 10px;
  }
`;

function formatPointsFortsToArrayId(pfs?: PointsFortsFormState) {
  if (!pfs) {
    return [];
  }
  function extractIdsFromEntries(entries: Record<string, boolean>) {
    return (
      Object.entries(entries)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_, val]) => val)
        .map(([key]) => Number(key.replace(POINT_FORT_ID_PREFIX, '')))
    );
  }
  const { mentaux, physiques, techniques } = pfs;
  return [...extractIdsFromEntries(mentaux), ...extractIdsFromEntries(physiques), ...extractIdsFromEntries(techniques)];
}

const PlayerPointsFortsModal: FC<IProps> = ({
  playerId,
  playerName,
  mutatePointsForts,
  votedPointsForts: votedPointsFortsFromProps,
}) => {
  const { hide, user_id, show } = useAuthModal();

  const { data, isValidating: playerPointsFortsIsValidating } = useGetPlayerPointsForts(
    {
      user_id,
      member_id: playerId,
    },
    !votedPointsFortsFromProps
  );
  const votedPointsForts = votedPointsFortsFromProps || data;

  async function handleOnSubmit(values: PointsFortsFormState) {
    if (user_id) {
      await postPointsForts({ user_id, member_id: playerId, ids: formatPointsFortsToArrayId(values) });
      if (mutatePointsForts) {
        mutatePointsForts();
      }
      hide();
    } else {
      show({
        informationMessage: 'Tu dois être connecté pour enregistrer des points forts',
        loginProps: {
          onLoginComplete: (userId: number) =>
            postPointsForts({
              user_id: userId,
              member_id: playerId,
              ids: formatPointsFortsToArrayId(values),
            }).then(() => {
              if (mutatePointsForts) {
                mutatePointsForts();
              }
            }),
        },
      });
    }
  }

  const { mental, physic, technic, isValidating, error, mutate } = useGetPointsForts();
  const initialState: PointsFortsFormState = useMemo(() => {
    function extractVotedSkillIds(pointsForts: PlayerPointFortsDetail[]) {
      return pointsForts.reduce<Record<string, boolean>>((acc, pf) => {
        if (pf.myVote) {
          acc[`pf-${pf.id}`] = true;
        }
        return acc;
      }, {});
    }

    return {
      mentaux: extractVotedSkillIds(votedPointsForts.mental),
      physiques: extractVotedSkillIds(votedPointsForts.physic),
      techniques: extractVotedSkillIds(votedPointsForts.technic),
    };
  }, [votedPointsForts]);

  return initialState ? (
    <ContainerWrapper>
      {playerPointsFortsIsValidating ? (
        <Loader />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialState}
          onSubmit={handleOnSubmit}
          validateOnMount={true}
          validationSchema={pointsFortsSchema}
        >
          {({ submitCount, errors }) => {
            const isSubmited = submitCount > 0;
            return (
              <Form>
                <Container className="p-4">
                  <h3 className="title-modal text-uppercase mb-5">
                    {playerId === user_id
                      ? `QUELS SONT TES POINTS FORTS`
                      : `QUELS SONT LES POINTS FORTS DE ${playerName}?`}{' '}
                  </h3>
                  {isValidating ? (
                    <Loader />
                  ) : error ? (
                    <FetchApiError onRetry={mutate} />
                  ) : (
                    <PlayerPointsFortsFormFragment
                      className="mb-5"
                      isSubmited={isSubmited}
                      mental={mental}
                      physic={physic}
                      technic={technic}
                    />
                  )}
                  <OmlSubmitButton disabled={isSubmited && !!Object.keys(errors).length} />
                </Container>
              </Form>
            );
          }}
        </Formik>
      )}
    </ContainerWrapper>
  ) : (
    <Loader />
  );
};

export default PlayerPointsFortsModal;
