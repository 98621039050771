import { ToastContainer, toast, ToastOptions, UpdateOptions, Slide } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { severityLevel } from '../layout.enums';
import 'react-toastify/dist/ReactToastify.css';

export interface IToaster {
  id?: number;
  message: string;
  severity: severityLevel;
}

export const newToast = (toaster: IToaster) => {
  let options: ToastOptions = { position: toast.POSITION.BOTTOM_RIGHT };
  let icon: JSX.Element | null = null;
  const message = <span>{toaster.message}</span>;
  switch (toaster.severity) {
    case severityLevel.success: {
      options = {
        ...options,
        type: toast.TYPE.SUCCESS,
        autoClose: 1500,
      };
      icon = <FontAwesomeIcon className="mr-3" icon="check" />;
      break;
    }
    case severityLevel.warning: {
      options = {
        ...options,
        type: toast.TYPE.WARNING,
        autoClose: 3000,
      };
      icon = <FontAwesomeIcon className="mr-3" icon="exclamation" />;
      break;
    }
    case severityLevel.error: {
      options = {
        ...options,
        type: toast.TYPE.ERROR,
        autoClose: 3000,
      };
      icon = <FontAwesomeIcon className="mr-3" icon="exclamation-triangle" />;
      break;
    }
    case severityLevel.loader: {
      options = {
        ...options,
        type: toast.TYPE.DEFAULT,
        autoClose: false,
      };
      icon = <FontAwesomeIcon className="mr-3 fa-spin" icon="circle-notch" />;
      break;
    }
    case severityLevel.info:
    default: {
      options = {
        ...options,
        type: toast.TYPE.INFO,
        autoClose: 3000,
      };
      icon = <FontAwesomeIcon className="mr-3" icon="info" />;
      break;
    }
  }
  return toast(
    <div className="d-flex">
      <div>{icon}</div>
      <div>{message}</div>
    </div>,
    options
  );
};

export const updateToast = (toaster: IToaster) => {
  if (toaster.id) {
    let options: UpdateOptions = {};
    switch (toaster.severity) {
      case severityLevel.success: {
        const icon = <FontAwesomeIcon className="mr-3" icon="info" />;
        options = {
          render: (
            <div className="d-flex">
              <div>{icon}</div>
              <div>{toaster.message}</div>
            </div>
          ),
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
          transition: Slide,
        };
        break;
      }
      case severityLevel.error: {
        const icon = <FontAwesomeIcon className="mr-3" icon="exclamation-triangle" />;
        options = {
          render: (
            <div className="d-flex">
              <div>{icon}</div>
              <div>{toaster.message}</div>
            </div>
          ),
          type: toast.TYPE.ERROR,
          autoClose: 3000,
          transition: Slide,
        };
        break;
      }
      default:
        return;
    }
    toast.update(toaster.id, options);
  }
};

const Toaster: React.FC = () => {
  return <ToastContainer className="mt-0 mt-sm-5" />;
};

export default Toaster;
