import { IUser, IApiUser } from './user.types';
import { routeList } from 'App';

import NoPhotoImg from 'assets/img/no_photo_thumb.png';

export function formatUser(user: IApiUser): IUser {
  const { user_id, username, comment_count, email, image, image_icon, image_normal, image_profile, nom_prenom } = user;
  return {
    id: user_id,
    nom: nom_prenom,
    nom_prenom,
    link: routeList.profil,
    username,
    comment_count,
    email,
    images: {
      default: image || NoPhotoImg,
      icon: image_icon || NoPhotoImg,
      normal: image_normal || NoPhotoImg,
      profile: image_profile || NoPhotoImg,
    },
  };
}
