import { ComponentPropsWithRef, FC, useContext, useMemo } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'reactstrap';
import OmlUnderline from 'features/transversal/components/OmlUnderline';
import OmlLink from 'features/transversal/components/OmlLink';
import { champNiveau } from 'features/championnat/championnat.enums';
import { TabNameFavoris } from '../menu.enum';
import { IOmlEntity } from 'features/transversal/transversal.types';
import { MenuContext } from './Menu';

export const SubMenuNavItem = (props: {
  name: React.ReactNode;
  children?: React.ReactNode;
  isOpen?: boolean;
  onClick?: () => void;
  level?: number;
  hideBorder?: boolean;
}) => {
  const { children, isOpen, name, onClick, level, hideBorder } = props;
  const className = classnames(
    'sub-menu-item d-flex justify-content-between',
    level ? `level-${level}` : '',
    hideBorder ? '' : 'with-border'
  );

  return (
    <>
      <div className={className} onClick={onClick}>
        {!children ? (
          name
        ) : (
          <>
            <div>{name}</div>
            <div>
              <FontAwesomeIcon icon={isOpen ? 'minus' : 'plus'} />
            </div>
          </>
        )}
      </div>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </>
  );
};

export const DisplayMenuResultsMobile = <T extends IOmlEntity>({
  champs,
  nbResultMax = 10,
  noResultText = 'Aucun résultat',
}: {
  champs: T[];
  nbResultMax?: number;
  noResultText?: string;
}) => {
  const { setMobileMenuIsOpen } = useContext(MenuContext);

  function handleOnClick() {
    if (setMobileMenuIsOpen) {
      setMobileMenuIsOpen(false);
    }
  }

  const nbChamp = champs.length;
  if (nbChamp === 0) {
    return <SubMenuNavItem level={3} name={noResultText} />;
  }
  if (nbChamp < nbResultMax) {
    return (
      <>
        {champs.map((champ) => (
          <SubMenuNavItem
            key={champ.id}
            level={2}
            name={
              <OmlLink onClick={handleOnClick} to={champ.link}>
                {champ.nom}
              </OmlLink>
            }
          />
        ))}
      </>
    );
  }
  return <SubMenuNavItem level={2} name="Trop de résultats, veuillez utiliser les filtres" />;
};

export const DisplayMenuResultsDesktop = <T extends IOmlEntity>({
  champs,
  customTitle,
  nbResultPerPage = 4,
  isWide = true,
  nbResultMax = 16,
  noResultText = 'Aucun résultat',
  className,
  ...rest
}: {
  champs: T[];
  customTitle?: string;
  nbResultPerPage?: number;
  isWide?: boolean;
  nbResultMax?: number;
  noResultText?: string;
} & ComponentPropsWithRef<'div'>) => {
  const rootClassName = classnames('liste-result', className);
  const { setSelectedItem } = useContext(MenuContext);
  const nbChamp = useMemo(() => champs.length, [champs]);
  const champCols = useMemo(() => {
    function handleOnClick() {
      if (setSelectedItem) {
        setSelectedItem(undefined);
      }
    }
    const tempCols: JSX.Element[] = [];
    for (let i = 0; i < Math.trunc(nbChamp / nbResultPerPage) + 1; i += 1) {
      const champLinks: JSX.Element[] = [];
      for (let j = 0; j < nbResultPerPage; j += 1) {
        if (i * nbResultPerPage + j < nbChamp) {
          const champ = champs[i * nbResultPerPage + j];
          champLinks.push(
            <li key={champ.id}>
              <OmlLink onClick={handleOnClick} to={champ.link}>
                {champ.nom}
              </OmlLink>
            </li>
          );
        }
      }
      tempCols.push(
        <div key={i} className={classnames('liste-result', isWide ? '' : 'wide')}>
          {customTitle && <div className="title">{i === 0 ? customTitle : '\u00A0'}</div>}
          <ul>{champLinks}</ul>
        </div>
      );
    }
    return tempCols;
  }, [champs, customTitle, isWide, nbChamp, nbResultPerPage, setSelectedItem]);

  if (nbChamp === 0) {
    return (
      <div className={rootClassName} {...rest}>
        {customTitle && <div className="title">{customTitle}</div>}
        <div>{noResultText}</div>
      </div>
    );
  }
  if (nbChamp < nbResultMax) {
    return (
      <div className={classnames(isWide && 'd-flex', className)} {...rest}>
        {champCols}
      </div>
    );
  }
  return (
    <div className={rootClassName} {...rest}>
      {customTitle && <div className="title">{customTitle}</div>}
      <div>Trop de résultats, veuillez utiliser les filtres</div>
    </div>
  );
};

export const MenuTab = <T extends champNiveau | TabNameFavoris>({
  tab,
  selectedTab,
  title,
  changeTab,
}: {
  tab: T;
  selectedTab: T | undefined;
  title: string;
  changeTab: (tab: T) => void;
}) => {
  return (
    <li
      key={tab}
      onClick={(event: React.MouseEvent) => {
        event.stopPropagation();
        event.preventDefault();
        changeTab(tab);
      }}
    >
      <div className="nav-link">
        <OmlUnderline className="font-weight-bold" selected={selectedTab === tab}>
          {title}
        </OmlUnderline>
      </div>
    </li>
  );
};

export const VerticalSeparator: FC = () => <div className="separator" />;
