import { FC, useState, useMemo, useContext, ComponentPropsWithRef, useRef } from 'react';
import { Navbar, Nav, Container, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import classnames from 'classnames';
import SubMenuSport from './SubMenuSport';
import SubMenuChampionnat from './SubMenuChampionnat';
import SubMenuEquip from './SubMenuEquip';
import SubMenuFavoris from './SubMenuFavoris';
import { MenuLabel } from '../menu.enum';
import { OMLLogo } from './OMLLogo';
import { MenuContext } from './Menu';
import { MenuItem } from './MenuItem';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { OmlText } from 'features/transversal/components/OmlText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { authLocalStorage } from 'features/auth/auth.localStorage';
import { useNavigate } from 'react-router-dom';
import { routeList } from 'App';
import { newToast } from 'features/layout/components/Toaster';
import { severityLevel } from 'features/layout/layout.enums';
import { useModal } from 'features/layout/layout.utils';
import ConfirmationModal from 'features/layout/components/ConfirmationModal';

const classNameForSubMenu = {
  [MenuLabel.championnats]: 'championnats',
  [MenuLabel.equipes]: 'equipes',
  [MenuLabel.favoris]: 'favoris',
  [MenuLabel.sports]: 'sports',
  // [MenuLabel.recherche]: 'search',
};

const DropDownMenuIcon: FC<ComponentPropsWithRef<'div'>> = ({ className, ...rest }) => {
  return (
    <div
      className={classnames(
        'd-flex align-items-center justify-content-center mr-3 rounded-circle dark background-light-grey overflow-hidden',
        className
      )}
      style={{ width: 30, height: 30 }}
      {...rest}
    />
  );
};

const DesktopMenu: FC = () => {
  const { selectedItem, setSelectedItem, clickSubMenu } = useContext(MenuContext);
  const clickOutsideRef = useRef<NodeJS.Timeout | null>(null);
  const { user, setUser } = useGeneralContextValue();
  const [isCollapsing, setIsCollapsing] = useState(false);
  const [dropdownProfileOpen, setDropdownProfileOpen] = useState(false);
  const navigate = useNavigate();
  const { show } = useModal();

  const toggleDropdownProfile = () => setDropdownProfileOpen(!dropdownProfileOpen);

  const displaySubMenu = useMemo(
    () =>
      selectedItem === MenuLabel.sports ||
      selectedItem === MenuLabel.championnats ||
      selectedItem === MenuLabel.equipes ||
      selectedItem === MenuLabel.favoris,
    [selectedItem]
  );

  // function hoverSubMenu(menuName: MenuLabel) {
  //   if (!setSelectedItem) {
  //     return;
  //   }
  //   if (menuName === selectedItem) {
  //     return;
  //   }
  //   if (!displaySubMenu) {
  //     setIsCollapsing(true);
  //     setTimeout(() => {
  //       setIsCollapsing(false);
  //     }, 200);
  //   }
  //   setSelectedItem(menuName);
  // }

  function handleClickSubMenu(menuItem: MenuLabel) {
    if (!clickSubMenu || !setSelectedItem) {
      return;
    }
    if (menuItem === selectedItem) {
      return;
    }
    if (!displaySubMenu) {
      setIsCollapsing(true);
      setTimeout(() => {
        setIsCollapsing(false);
      }, 200);
    }
    setSelectedItem(menuItem);
    clickSubMenu(menuItem);
  }

  function handleClickOutside() {
    if (setSelectedItem) {
      setSelectedItem(undefined);
    }
  }

  function setTimeoutClickOutside() {
    clickOutsideRef.current = setTimeout(() => {
      if (clickOutsideRef.current) {
        handleClickOutside();
      }
    }, 500);
  }

  function cancelClickOutside() {
    if (clickOutsideRef.current === null) {
      return;
    }
    clearTimeout(clickOutsideRef.current);
    clickOutsideRef.current = null;
  }

  function handleLogout() {
    authLocalStorage.logout();
    if (setUser) {
      setUser(undefined);
    }
    navigate(routeList.home);
    newToast({ message: 'Déconnecté avec succès', severity: severityLevel.success });
  }

  function showLogoutConfirmationModal() {
    show({
      children: (
        <ConfirmationModal
          message="Es tu sur de vouloir te déconnecter ?"
          onConfirm={handleLogout}
          title="Déconnexion"
        />
      ),
    });
  }

  return (
    <>
      <Navbar className="oml-navbar border-bottom" color="light" fixed="top" light={true}>
        <Nav className="oml-nav justify-content-between" navbar={true}>
          <OMLLogo className="flex-1" />
          <div className="d-flex align-items-center">
            <MenuItem
              className="mr-32"
              hasSubMenu
              itemType={MenuLabel.sports}
              onClick={handleClickSubMenu}
              // onHover={hoverSubMenu}
            />
            <MenuItem
              className="mr-32"
              hasSubMenu
              itemType={MenuLabel.championnats}
              onClick={handleClickSubMenu}
              // onHover={hoverSubMenu}
            />
            <MenuItem
              className="mr-32"
              hasSubMenu
              itemType={MenuLabel.equipes}
              onClick={handleClickSubMenu}
              // onHover={hoverSubMenu}
            />
            <MenuItem
              itemType={MenuLabel.joueurs}
              onClick={handleClickSubMenu}
              //  onHover={hoverSubMenu}
            />
            {user && (
              <MenuItem
                className="ml-32"
                hasSubMenu
                itemType={MenuLabel.favoris}
                onClick={handleClickSubMenu}
                // onHover={hoverSubMenu}
              />
            )}
          </div>
          <div className="d-flex align-items-center flex-1 justify-content-end">
            {/* <MenuItem
              className="mr-24"
              isIcon
              itemType={MenuLabel.recherche}
              onClick={handleClickSubMenu}
              // onHover={hoverSubMenu}
            /> */}
            <Dropdown isOpen={dropdownProfileOpen} toggle={toggleDropdownProfile}>
              <DropdownToggle tag="div">
                <MenuItem
                  className="mr-24"
                  isIcon
                  itemType={MenuLabel.profil}
                  onClick={user ? () => {} : handleClickSubMenu}
                />
              </DropdownToggle>
              {user && (
                <DropdownMenu className="mt-3" style={{ borderRadius: 10 }}>
                  <DropdownItem className="d-flex align-items-center" href={user.link} tag="a">
                    <DropDownMenuIcon>
                      <img alt={user.nom} src={user.images.icon} />
                    </DropDownMenuIcon>
                    <OmlText tag="span" variant="p">
                      Mon profil
                    </OmlText>
                  </DropdownItem>

                  <DropdownItem divider />
                  <DropdownItem className="d-flex align-items-center" onClick={showLogoutConfirmationModal}>
                    <DropDownMenuIcon className="p-2">
                      <FontAwesomeIcon icon="sign-out-alt" />
                    </DropDownMenuIcon>
                    <OmlText tag="span" variant="p">
                      Déconnection
                    </OmlText>
                  </DropdownItem>
                </DropdownMenu>
              )}
            </Dropdown>

            <MenuItem isIcon itemType={MenuLabel.notifications} onClick={handleClickSubMenu} />
          </div>
        </Nav>
      </Navbar>
      <div
        className={classnames(
          'sub-menu-container',
          selectedItem && classNameForSubMenu[selectedItem],
          isCollapsing && 'collapsing'
        )}
      >
        <div className={classnames('sub-menu-wrapper', 'border-bottom')}>
          <Container className="pt-2">
            <SubMenuSport />
            <SubMenuChampionnat />
            <SubMenuEquip />
            <SubMenuFavoris />
          </Container>
        </div>
        <div
          className={classnames('voile-noir', displaySubMenu && 'd-block')}
          onClick={handleClickOutside}
          onMouseEnter={setTimeoutClickOutside}
          onMouseLeave={cancelClickOutside}
        />
      </div>
    </>
  );
};

export { DesktopMenu };
