import { useState, useEffect, useMemo, FC, useContext } from 'react';
import { genreList } from 'features/transversal/transversal.enum';
import {
  DisplayMenuResultsDesktop,
  DisplayMenuResultsMobile,
  VerticalSeparator,
  SubMenuNavItem,
} from './MenuFragments';
import { MenuLabel } from '../menu.enum';
import { Loader } from 'features/layout/components/Loader';
import { useGetChampionnatList } from 'features/championnat/championnat.services';
import { MenuContext } from './Menu';
import { champNiveau } from 'features/championnat/championnat.enums';
import FetchApiError from 'features/transversal/components/FetchApiError';
import { menuItemloadingError } from '../menu.const';

const SubMenuChampionnatNat: FC<{
  isPhone: boolean;
  isSelected: boolean;
}> = ({ isPhone, isSelected }) => {
  const { selectedItem } = useContext(MenuContext);
  const [firstDisplay, setFirstDisplay] = useState(false);

  useEffect(() => {
    if (isSelected && selectedItem === MenuLabel.championnats) {
      setFirstDisplay(true);
    }
  }, [isSelected, selectedItem]);
  const { championnats, isValidating, error, mutate } = useGetChampionnatList(
    { division: champNiveau.national, location: 'NAT' },
    firstDisplay
  );

  const [selectedId, setSelectedId] = useState<number>();

  const femChamp = useMemo(() => championnats.filter((champ) => champ.genre === genreList.feminin), [championnats]);
  const mascChamp = useMemo(() => championnats.filter((champ) => champ.genre === genreList.masculin), [championnats]);

  function toggle(id: number) {
    return id === selectedId ? setSelectedId(undefined) : setSelectedId(id);
  }

  useEffect(() => {
    if (isPhone && selectedItem !== MenuLabel.championnats) {
      setSelectedId(undefined);
    }
  }, [isPhone, selectedItem]);

  if (!isSelected) {
    return null;
  }

  if (isPhone) {
    return isValidating ? (
      <SubMenuNavItem isOpen={false} level={2} name={<Loader />} />
    ) : error ? (
      <SubMenuNavItem isOpen={false} level={2} name={menuItemloadingError} />
    ) : (
      <>
        <SubMenuNavItem isOpen={selectedId === 1} level={2} name="Féminin" onClick={() => toggle(1)}>
          <DisplayMenuResultsMobile champs={femChamp} />
        </SubMenuNavItem>
        <SubMenuNavItem isOpen={selectedId === 2} level={2} name="Masculin" onClick={() => toggle(2)}>
          <DisplayMenuResultsMobile champs={mascChamp} />
        </SubMenuNavItem>
      </>
    );
  }
  return isValidating ? (
    <div className="w-100">
      <Loader />
    </div>
  ) : error ? (
    <div className="w-100 align-self-center">
      <FetchApiError onRetry={mutate} />
    </div>
  ) : (
    <>
      <DisplayMenuResultsDesktop champs={femChamp} customTitle="Féminin" isWide={true} nbResultPerPage={7} />
      <VerticalSeparator />
      <DisplayMenuResultsDesktop champs={mascChamp} customTitle="Masculin" isWide={true} nbResultPerPage={7} />
    </>
  );
};

export default SubMenuChampionnatNat;
