import classNames from 'classnames';
import { ComponentPropsWithoutRef, FC } from 'react';
import { SocialIcon } from 'react-social-icons';
import { Row, Col } from 'reactstrap';

import './FollowUsBar.scss';

const FollowUsBar: FC<ComponentPropsWithoutRef<'div'>> = ({ className, ...rest }) => {
  const socialIconStyle = { height: 30, width: 30, borderRadius: 30 };
  const instagramColor = '#bc2a8d'; // Instagram red violet
  return (
    <Col className={classNames('follow-us-bar', className)} {...rest}>
      <Row>
        <Col className="py-2 d-flex align-items-center">Suivez nous</Col>
        <Col className="py-2 d-flex justify-content-around align-items-center">
          <SocialIcon className="mr-3" style={socialIconStyle} url="http://facebook.com/onmyleague" />
          <SocialIcon className="mr-3" style={socialIconStyle} url="http://twitter.com/onmyleague" />
          <SocialIcon
            className="mr-3"
            color={instagramColor}
            style={socialIconStyle}
            url="http://instagram.com/onmyleague"
          />
        </Col>
      </Row>
    </Col>
  );
};

export default FollowUsBar;
