import { useRef, FC, useContext, useState, useMemo, useCallback } from 'react';
import { Formik, Form } from 'formik';
import Slider from 'react-slick';
import { AuthContainerContext } from './AuthContainer';
import { InscriptionStep } from 'features/inscription/inscription.services';
import { OmlYup } from 'features/form/form.validation';
import InscriptionStepWrapper from 'features/inscription/components/InscriptionStepWrapper';
import InscriptionFirstStep from 'features/inscription/components/InscriptionFirstStep';
import InscriptionStepSport from 'features/inscription/components/InscriptionStepSport';
import InscriptionStepQualite from 'features/inscription/components/InscriptionStepQualite';
import InscriptionStepClub from 'features/inscription/components/InscriptionStepClub';
import InscriptionStepPhoto from 'features/inscription/components/InscriptionStepPhoto';
import InscriptionStepConfirm from 'features/inscription/components/InscriptionStepConfirm';

export const GENRES_OPTIONS = [
  { id: 'M', value: 'Homme' },
  { id: 'F', value: 'Femme' },
] as const;

const inscriptionValidationSchema = OmlYup.object({
  activeStep: OmlYup.string(),

  // First step
  prenom: OmlYup.string().required().name('prénom').required(),
  nom: OmlYup.string().required().name('nom').required(),
  genre: OmlYup.string()
    .required()
    .oneOf(
      GENRES_OPTIONS.map((o) => o.id),
      'La sélection du genre est obligatoire'
    )
    .required(),
  email: OmlYup.string().required().email('Adresse email invalide').required(),
  password: OmlYup.string().required().password().required(),
  passwordConfirm: OmlYup.string().oneOf([OmlYup.ref('password'), null], 'Les mots de passe ne correspondent pas'),
  termsOfUse: OmlYup.boolean().oneOf([true], "Veuillez valider les conditions d'utilisation"),
  captcha: OmlYup.string().nullable().required(),

  // Sport
  isPlayer: OmlYup.bool().required(),
  isTrainer: OmlYup.bool().required(),

  // Qualités
  birthdate: OmlYup.date(),
  poste: OmlYup.string().when(['activeStep', 'isPlayer'], {
    is: (activeStep, isPlayer) => activeStep === InscriptionStep.qualites && isPlayer,
    then: OmlYup.string().required(),
  }),
  taille: OmlYup.number().when(['activeStep', 'isPlayer'], {
    is: (activeStep, isPlayer) => activeStep === InscriptionStep.qualites && isPlayer,
    then: OmlYup.number().required(),
  }),
  mentaux: OmlYup.object().maxValues(3, 'compétences'),
  physiques: OmlYup.object().maxValues(3, 'compétences'),
  techniques: OmlYup.object().maxValues(6, 'compétences'),

  // Club
  isPlayerThisYear: OmlYup.bool(),
  playerPostal: OmlYup.string().when(['isPlayer', 'isPlayerThisYear'], {
    is: true,
    then: OmlYup.string().required('La sélection de la ville est obligatoire'),
  }),
  playerClub: OmlYup.string().when(['isPlayer', 'isPlayerThisYear', 'playerPostal'], {
    is: true,
    then: OmlYup.string().required('La sélection du club est obligatoire'),
  }),
  playerTeam: OmlYup.string(),
  playerMaillot: OmlYup.string(),
  playerId: OmlYup.string(),

  isTrainerThisYear: OmlYup.bool(),
  trainerPostal: OmlYup.string().when(['isTrainer', 'isTrainerThisYear', 'isPlayerThisYear'], {
    is: (isTrainer, isTrainerThisYear, isPlayerThisYear) => (isPlayerThisYear ? false : isTrainer && isTrainerThisYear),
    then: OmlYup.string().required('La sélection de la ville est obligatoire'),
  }),
  trainerClub: OmlYup.string().when(['isTrainer', 'isTrainerThisYear', 'trainerPostal'], {
    is: true,
    then: OmlYup.string().required('La sélection du club est obligatoire'),
  }),
  trainerTeam: OmlYup.string(),
  trainerId: OmlYup.string(),

  // Photo
  avatar: OmlYup.mixed().test('fileSize', "L'image est trop volumineuse (taille maximum 2Mo)", (value) => {
    if (!value?.length) {
      return true;
    }
    return value[0].size <= 2000 * 1000;
  }),
});
export type InscriptionFormState = Omit<OmlYup.Asserts<typeof inscriptionValidationSchema>, 'avatar'> & {
  avatar?: File;
};
const initialState: InscriptionFormState = {
  activeStep: 'first',
  prenom: '',
  nom: '',
  genre: '',
  email: '',
  password: '',
  passwordConfirm: '',
  termsOfUse: false,
  captcha: '',
  birthdate: undefined,
  poste: undefined,
  taille: undefined,
  isPlayer: false,
  isTrainer: false,
  mentaux: {},
  physiques: {},
  techniques: {},
  isPlayerThisYear: false,
  playerPostal: undefined,
  playerClub: undefined,
  playerTeam: undefined,
  playerMaillot: undefined,
  playerId: undefined,
  isTrainerThisYear: false,
  trainerPostal: undefined,
  trainerClub: undefined,
  trainerTeam: undefined,
  trainerId: undefined,
};

const initialStepsArray = [
  InscriptionStep.first,
  InscriptionStep.sport,
  InscriptionStep.qualites,
  InscriptionStep.club,
  InscriptionStep.photo,
  InscriptionStep.confirm,
];

const Inscription: FC = () => {
  const { setProgress } = useContext(AuthContainerContext);
  const slider = useRef<Slider>(null);
  const [activeStep, setActiveStep] = useState(InscriptionStep.first);
  const [stepsArray, setStepsArray] = useState(initialStepsArray);
  const [submitedSteps, setSubmitedSteps] = useState<InscriptionStep[]>([]);
  const [globalErrorMessages, setGlobalErrorMessages] = useState<string[]>([]);

  const goToNextStep = useCallback(
    (forward: boolean, stepsArrayTemp: InscriptionStep[] = stepsArray) => {
      const activeStepIndex = stepsArrayTemp.findIndex((step) => step === activeStep);
      let newActiveStep = activeStep;
      const modifier = forward ? 1 : -1;
      if ((forward && activeStepIndex < stepsArrayTemp.length - 1) || (!forward && activeStepIndex > 0)) {
        newActiveStep = stepsArrayTemp[activeStepIndex + modifier];
      }
      setActiveStep(newActiveStep);
      if (setProgress) {
        setProgress(((activeStepIndex + modifier + 1) / stepsArrayTemp.length) * 100);
      }
      slider.current?.slickGoTo(activeStepIndex + modifier);
    },
    [activeStep, setProgress, stepsArray]
  );

  async function handleSubmit(values: InscriptionFormState) {
    const { isPlayer, isTrainer } = values;

    if (activeStep === InscriptionStep.sport) {
      const tempStepsArray = [...initialStepsArray];
      if (isPlayer === false) {
        const qualiteIndex = tempStepsArray.findIndex((step) => step === InscriptionStep.qualites);
        tempStepsArray.splice(qualiteIndex, 1);
      }
      if (isTrainer === false && isPlayer === false) {
        const clubIndex = tempStepsArray.findIndex((step) => step === InscriptionStep.club);
        tempStepsArray.splice(clubIndex, 1);
      }
      setStepsArray(tempStepsArray);
      // Need to add tempStepsArray in goToNextStep to make sure we work on an up to date version of the array, otherwise we might compute before the state is updated
      goToNextStep(true, tempStepsArray);
    } else {
      goToNextStep(true);
    }
  }

  const stepsElements = useMemo(() => {
    function handleSubmitStep(newStepSubmited: InscriptionStep, isValid: boolean, goToFirstStep: boolean) {
      if (!submitedSteps.includes(newStepSubmited)) {
        setSubmitedSteps([...submitedSteps, newStepSubmited]);
      }
      // Force display of first slide in case of captcha expiration
      if (goToFirstStep) {
        slider?.current?.slickGoTo(0);
      }
      // If not valid refresh the slider to make sure to display the error messages
      if (!isValid) {
        slider?.current?.slickGoTo(stepsArray.findIndex((s) => s === newStepSubmited));
      }
    }

    function handleSubmitError(message: Record<string, string>) {
      setGlobalErrorMessages(Object.values(message));
      setActiveStep(InscriptionStep.first);
      slider.current?.slickGoTo(stepsArray.findIndex((s) => s === InscriptionStep.first));
    }

    function onBack() {
      goToNextStep(false);
    }

    return stepsArray.map((step) => {
      const isStepActive = activeStep === step;
      const stepWrapperCommonProps = {
        onSubmitStep: handleSubmitStep,
        submitedSteps,
        stepName: step,
        submitButtonLabel: 'Continuer',
        onBack,
        isStepActive,
      };

      switch (step) {
        case InscriptionStep.first:
          return (
            <InscriptionStepWrapper
              key={step}
              {...stepWrapperCommonProps}
              submitButtonLabel="S'inscrire"
              title="Créer un compte"
              withBackButton={false}
            >
              {({ stepSubmited }) => (
                <InscriptionFirstStep globalErrorMessages={globalErrorMessages} stepSubmited={stepSubmited} />
              )}
            </InscriptionStepWrapper>
          );
        case InscriptionStep.sport:
          return (
            <InscriptionStepWrapper key={step} {...stepWrapperCommonProps} title="Mon sport">
              {({ stepSubmited }) => <InscriptionStepSport stepSubmited={stepSubmited} />}
            </InscriptionStepWrapper>
          );

        case InscriptionStep.qualites:
          return (
            <InscriptionStepWrapper key={step} {...stepWrapperCommonProps} title="Mes qualités">
              {({ stepSubmited }) => <InscriptionStepQualite isStepActive={isStepActive} stepSubmited={stepSubmited} />}
            </InscriptionStepWrapper>
          );
        case InscriptionStep.club:
          return (
            <InscriptionStepWrapper key={step} {...stepWrapperCommonProps} title="Mon club">
              {({ stepSubmited }) => <InscriptionStepClub isStepActive={isStepActive} stepSubmited={stepSubmited} />}
            </InscriptionStepWrapper>
          );
        case InscriptionStep.photo:
          return (
            <InscriptionStepWrapper
              key={step}
              {...stepWrapperCommonProps}
              submitButtonLabel="Finaliser l'inscription"
              title="Ma photo"
            >
              {({ stepSubmited }) => <InscriptionStepPhoto stepSubmited={stepSubmited} />}
            </InscriptionStepWrapper>
          );
        case InscriptionStep.confirm:
          return (
            <InscriptionStepConfirm
              key={step}
              isStepActive={isStepActive}
              onBack={onBack}
              onError={handleSubmitError}
            />
          );
        default:
          return null;
      }
    });
  }, [stepsArray, submitedSteps, goToNextStep, activeStep, globalErrorMessages]);

  return (
    <Formik
      initialValues={initialState}
      onSubmit={handleSubmit}
      validateOnMount={true}
      validationSchema={inscriptionValidationSchema}
    >
      <Form>
        {stepsElements.length && (
          <Slider
            ref={slider}
            adaptiveHeight={true}
            arrows={false}
            autoplay={false}
            dots={false}
            infinite={false}
            speed={500}
            swipe={false}
          >
            {stepsElements}
          </Slider>
        )}
      </Form>
    </Formik>
  );
};

export { Inscription };
