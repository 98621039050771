import { FC, useState } from 'react';
import { Col, Row } from 'reactstrap';
import PageTitle from 'features/layout/components/PageTitle';
import PageContainer from 'features/layout/components/PageContainer';
import Alphabet from 'features/players/components/Alphabet';
import PlayersSearch from 'features/players/components/PlayersSearch';
import PlayersTable from 'features/players/components/PlayersTable';
import FetchApiError from 'features/transversal/components/FetchApiError';
import { IPlayer } from 'features/players/players.types';
import { useGetPlayers } from 'features/players/players.services';

const Players: FC = () => {
  const [selectedLetter, setSelectedLetter] = useState<string>('A');
  const [query, setQuery] = useState<string>('');

  const { players, error, mutate, isValidating } = useGetPlayers();

  const handleChangeLetter = (letter: string) => {
    setSelectedLetter(letter);
    if (letter.length > 0) {
      setQuery('');
    }
  };
  const handleChangeQuery = (queryChanged: string) => {
    if (queryChanged.length > 0) {
      setSelectedLetter('');
    }
    setQuery(queryChanged);
  };

  let filteredPlayers: IPlayer[] = players;

  if (query) {
    filteredPlayers = filteredPlayers.filter(
      (player) =>
        (player.prenom && player.prenom.concat(' ', player.nom).toUpperCase().indexOf(query.toUpperCase()) >= 0) ||
        player.nom.toUpperCase().indexOf(query.toUpperCase()) >= 0
    );
  } else if (selectedLetter) {
    filteredPlayers = filteredPlayers.filter((player) => player.nom[0].toUpperCase() === selectedLetter);
  }
  filteredPlayers.sort((a, b) =>
    `${a.nom} ${a.prenom}` > `${b.nom} ${b.prenom}` ? 1 : `${b.nom} ${b.prenom}` > `${a.nom} ${a.prenom}` ? -1 : 0
  );

  return (
    <PageContainer>
      <Row className="d-flex justify-content-between mr-3">
        <Col className="mb-3 px-2" md={5} xs={12}>
          <PageTitle browserTabTitle="Joueurs">Liste des joueurs</PageTitle>
        </Col>

        {!error && (
          <Col className="players-search-input pt-2" md={3} xs={12}>
            <PlayersSearch defaultQuery={query || ''} onQueryChange={handleChangeQuery} />
          </Col>
        )}
      </Row>

      {error ? (
        <FetchApiError onRetry={mutate} />
      ) : (
        <>
          <Row className="d-flex justify-content-center mr-3">
            <Alphabet onClickLetter={handleChangeLetter} selectedLetter={selectedLetter} />
          </Row>

          <PlayersTable isLoading={isValidating} players={filteredPlayers} />
        </>
      )}
    </PageContainer>
  );
};

export default Players;
