import { useFormikContext } from 'formik';
import { FC } from 'react';
import { FormFeedback, FormText } from 'reactstrap';

export type FormikErrorOrFeedbackProps = { name: string; helperLabel?: string; stepSubmited?: boolean };

const FormikErrorOrFeedback: FC<FormikErrorOrFeedbackProps> = ({ name, helperLabel, stepSubmited }) => {
  const { errors, submitCount } = useFormikContext();
  const errorLabel = errors[name];
  const isSubmited = stepSubmited === undefined ? submitCount > 0 : stepSubmited;
  const displayErrorLabel = errorLabel && isSubmited;
  return (
    <>
      {displayErrorLabel && <FormFeedback className="d-block">{errorLabel}</FormFeedback>}
      {!displayErrorLabel && helperLabel && <FormText>{helperLabel}</FormText>}
    </>
  );
};

export { FormikErrorOrFeedback };
