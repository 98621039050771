import { FC, useMemo } from 'react';
import { useGetMemberInfo } from 'features/member/member.services';
import { useGetPlayerPointsForts, useGetStatsPlayer } from 'features/players/players.services';
import { OmlPageTab, PageContainerWithHeaderAndTabs } from 'features/layout/components/PageContainerWithHeaderAndTabs';
import { MemberPageHeader } from 'features/member/components/MemberPageHeader';
import { MemberStatsTab } from './MemberStatsTab';
import { MemberPointsFortsTab } from './MemberPointsFortsTab';
import { MemberPublicationTab } from './MemberPublicationTab';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { useGetPublications } from 'features/publication/publication.services';

const MemberLayout: FC<{ member_id: number }> = ({ member_id }) => {
  const { user_id } = useGeneralContextValue();

  const memberSwrState = useGetMemberInfo({ member_id });
  const member = useMemo(() => memberSwrState.data, [memberSwrState]);

  const statsSwrState = useGetStatsPlayer({ member_id });
  // Compliqué de renseigner isActive sur l'appel useGetPlayerPointsForts étant donné que le nombre de tabs est variable
  const pointsFortsSwrState = useGetPlayerPointsForts({ member_id, user_id });
  const publicationsSwrState = useGetPublications();

  const tabs = useMemo(() => {
    const tempTabs: OmlPageTab[] = [
      {
        name: "Fil d'actu",
        component: (
          <MemberPublicationTab
            mutatePublications={publicationsSwrState.mutate}
            publications={publicationsSwrState.data}
          />
        ),
        tabSwrState: publicationsSwrState,
      },
    ];
    if (statsSwrState.data?.isError === false) {
      tempTabs.push({
        name: 'Statistiques',
        component: statsSwrState.data ? (
          <MemberStatsTab mutateStats={statsSwrState.mutate} playerStats={statsSwrState.data} />
        ) : (
          <></>
        ),
        tabSwrState: statsSwrState,
      });
    }
    tempTabs.push({
      name: 'Points forts',
      component: member ? (
        <MemberPointsFortsTab
          member={member}
          mutatePointsForts={pointsFortsSwrState.mutate}
          playerPointsForts={pointsFortsSwrState.data}
        />
      ) : (
        <></>
      ),
      tabSwrState: pointsFortsSwrState,
    });
    return tempTabs;
  }, [statsSwrState, pointsFortsSwrState, publicationsSwrState, member]);

  return (
    <PageContainerWithHeaderAndTabs
      browserTabTitle={member?.nom_prenom}
      headerComponent={member ? <MemberPageHeader member={member} mutateMember={memberSwrState.mutate} /> : <></>}
      headerSwrState={memberSwrState}
      onTabChange={() => undefined}
      pageTitle="Profil membre"
      tabs={tabs}
    />
  );
};

export { MemberLayout };
