import { FormikState } from 'formik';

// Un bouton de submission est désactivé si le formulaire est en cours de submission ou s'il y a une erreur
// Si on fournit un submit count on le prend en compte également
export function isSubmitButtonDisabled<T>({
  isSubmitting,
  submitCount,
  errors = {},
}: Partial<Pick<FormikState<T>, 'isSubmitting' | 'submitCount' | 'errors'>>) {
  if (isSubmitting) {
    return true;
  }
  if (submitCount === 0) {
    return false;
  }
  return Object.values(errors).length > 0;
}
