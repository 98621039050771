export enum severityLevel {
  info = 'INFO',
  success = 'SUCCESS',
  warning = 'WARNING',
  error = 'ERROR',
  loader = 'LOADER',
}

export const metadata = {
  siteTitle: 'OnMyLeague',
  description: 'La référence du sport amateur',
  lang: 'fr',
  author: 'onMyLeague',
};
