import OmlDatePicker from 'features/form/components/OmlDatepicker';
import { OmlYup } from 'features/form/form.validation';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';

const testValidationSchema = OmlYup.object({ birthdate: OmlYup.date() });

const Test = () => {
  const [value, setValue] = useState<Date | undefined>();
  return (
    <div className="fullHeight">
      <Container>
        <Row className="my-5">
          <Col lg={{ size: 6, offset: 3 }}>On peut tester des choses</Col>

          <Formik initialValues={{}} onSubmit={() => {}} validateOnMount={true} validationSchema={testValidationSchema}>
            <Form>
              <OmlDatePicker isDropdown onChange={setValue} value={value} />
            </Form>
          </Formik>
        </Row>
      </Container>
    </div>
  );
};
export default Test;
