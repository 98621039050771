import { IApiClassement, IClassement } from './classement.types';
import { formatEquipeLight } from '../equipe/equipe.services';

export function formatClassement(classement: IApiClassement): IClassement {
  const {
    Profil_id,
    rang,
    diff,
    storage_path,
    equipe: equipeNom,
    Pts,
    Game_num,
    Won,
    Lost,
    Avg_offense,
    Avg_defense,
    Rk_offense,
    Rk_defense,
  } = classement;
  const equipe = formatEquipeLight({
    Profil_id,
    logoUrl: storage_path,
    nom: equipeNom,
  });
  return {
    equipe,
    nbPts: Pts,
    nbLost: Lost,
    nbWin: Won,
    nbDraw: 0,
    journee: Game_num,
    avgDefense: Avg_defense,
    avgOffense: Avg_offense,
    rank: rang,
    rankDefense: Rk_defense,
    rankOffense: Rk_offense,
    diffWithPreviousJournee: diff,
  };
}
