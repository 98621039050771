import { FC } from 'react';
import { Button } from 'reactstrap';
import { useModal } from 'features/layout/layout.utils';
import { OmlText } from 'features/transversal/components/OmlText';

const ConfirmationModal: FC<{ title: string; message: string; onConfirm: () => void }> = ({
  title,
  message,
  onConfirm,
}) => {
  const { hide } = useModal();

  function handleOnConfirm() {
    onConfirm();
    hide();
  }

  return (
    <div className="m-5">
      <OmlText className="mb-4 text-uppercase" flavour="color-title" tag="div" variant="h3">
        {title}
      </OmlText>
      <OmlText className="mb-5" variant="p">
        {message}
      </OmlText>
      <div className="d-flex justify-content-end">
        <Button className="mr-3" color="secondary" onClick={hide} type="button">
          Annuler
        </Button>
        <Button color="primary" onClick={handleOnConfirm} type="button">
          Confirmer
        </Button>
      </div>
    </div>
  );
};

export default ConfirmationModal;
