import {
  ChangeEvent,
  forwardRef,
  ComponentPropsWithRef,
  useImperativeHandle,
  ForwardRefRenderFunction,
  useRef,
} from 'react';
import classnames from 'classnames';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';

import './OmlInput.scss';

type IProps = Omit<TextareaAutosizeProps, 'onSubmit' | 'onChange' | 'value'> & {
  isInvalid?: boolean;
  onChange: (newValue: string[], options?: any) => void;
  value: string[];
} & Pick<ComponentPropsWithRef<'textarea'>, 'ref'>;

export type OmlTextAreaHandle = { updateValue: (newValue: string[], options?: any) => void };

const OmlTextArea: ForwardRefRenderFunction<OmlTextAreaHandle, IProps> = (
  { id, value, isInvalid, className, onChange, style, ...rest },
  handleRef
) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
    const splitedValue = event.target.value.split('\n');
    onChange(splitedValue);
  }

  useImperativeHandle(handleRef, () => ({
    updateValue(newValue, options) {
      const textAreaElement = textAreaRef?.current;
      if (!textAreaElement) {
        return;
      }
      onChange(newValue, options);
      textAreaElement.focus();
      textAreaElement.setSelectionRange(textAreaElement.value.length, textAreaElement.value.length);
      textAreaElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    },
  }));

  return (
    <TextareaAutosize
      ref={textAreaRef}
      className={classnames(
        'oml-textarea',
        'oml-input',
        'w-100',
        isInvalid && 'invalid',
        value && typeof value !== 'number' && value.length > 0 && 'filled',
        className
      )}
      id={id}
      name={id}
      onChange={handleChange}
      style={{ resize: 'none', ...style }}
      value={value.join('\n')}
      {...rest}
    />
  );
};

export default forwardRef(OmlTextArea);
