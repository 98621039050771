export enum champCategorie {
  all = 'All',
  senior = 'Senior',
  espoir = 'Espoir',
  ancien = 'Ancien',
  u11 = 'U11',
  u13 = 'U13',
  u15 = 'U15',
  u17 = 'U17',
  u18 = 'U18',
  u20 = 'U20',
}

export enum champNiveau {
  national = 'National',
  regional = 'Regional',
  departemental = 'Departemental',
}
