import { AxiosError, AxiosResponse } from 'axios';
import { apiUrl, oauthTokens } from 'features/api/apiCalls.enum';
import { axiosPost, axiosInstance } from 'features/api/apiCalls.utils';
import { getMyIp } from 'features/transversal/transversal.utils';
import { formatUser } from 'features/user/user.services';
import { LoginFormState } from 'features/auth/components/LoginForm';
import { authLocalStorage, userIdKey } from './auth.localStorage';
import { IApiLogin } from './auth.types';
import { IApiUser } from 'features/user/user.types';

export function managePostLoginOperation({ data }: AxiosResponse<IApiLogin>) {
  const { oauth_secret, oauth_token, user } = data;
  const { user_id } = user;

  authLocalStorage.setToken(oauthTokens.secret, oauth_secret);
  authLocalStorage.setToken(oauthTokens.token, oauth_token);
  authLocalStorage.setToken(userIdKey, user_id);

  return formatUser(user);
}

export async function callLogin({ email, password }: LoginFormState) {
  const ip = await getMyIp();
  return axiosPost<IApiLogin>(apiUrl.login, {
    email,
    password,
    ip,
  })
    .then(managePostLoginOperation)
    .catch(({ name }: AxiosError) => {
      let errorMessage = 'Erreur inconnue';
      if (typeof name === 'string') {
        switch (name) {
          case 'unauthorized': {
            errorMessage = "Nom d'utilisateur ou mot de passe incorrect";
            break;
          }
          case 'validation_fail': {
            errorMessage = 'Formulaire incomplet';
            break;
          }
          case 'user_login_default': {
            errorMessage = "L'utilisateur est déjà logué";
            break;
          }
          default: {
            errorMessage = 'Impossible de se connecter';
          }
        }
      }
      return Promise.reject(errorMessage);
    });
}
interface IApiUserProfile {
  gutterMenu: never;
  profileTabs: never;
  response: IApiUser;
}
export function callUserProfile(userId?: string | null) {
  let userIdToCall = userId;
  if (!userIdToCall) {
    userIdToCall = authLocalStorage.getToken(userIdKey);
  }
  if (!userIdToCall) {
    return Promise.reject(new Error('notLoggedIn'));
  }
  return axiosInstance
    .get<IApiUserProfile>(`${apiUrl.userProfile}/${userIdToCall}`)
    .then(({ data: { response } }) => formatUser(response));
}
