import { FC } from 'react';
import PageContainer from 'features/layout/components/PageContainer';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { MemberLayout } from 'features/member/components/MemberLayout';

const Profil: FC = () => {
  const { user_id } = useGeneralContextValue();

  if (!user_id) {
    return (
      <PageContainer>
        <div>Erreur, l&apos;utilisateur n&apos;est pas défini</div>
      </PageContainer>
    );
  }

  return <MemberLayout member_id={user_id} />;
};

export { Profil };
