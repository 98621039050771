import { apiUrl } from 'features/api/apiCalls.enum';

import { axiosInstance, axiosPost, useApiGet } from 'features/api/apiCalls.utils';
import { formatChampionnatLight } from 'features/championnat/championnat.services';
import { formatClubLight } from 'features/club/club.services';
import { formatEquipeLight } from 'features/equipe/equipe.services';
import { formatPlayerLight } from 'features/players/players.services';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { FavorisType, IApiFavorisList, IFavoris } from './favoris.type';

export const FAVORITES_DEFAULT: IFavoris = { championnat: [], member: [], club: [], equipe: [] };

function formatFavoris({ championnat, member, equipe, club }: IApiFavorisList): IFavoris {
  return {
    championnat: (championnat || []).map(({ follow_id, name }) =>
      formatChampionnatLight({ id: follow_id, nom: name, type: 'withId' })
    ),
    member: (member || []).map(({ follow_id, name }) => formatPlayerLight({ Player_id: follow_id, name })),
    equipe: (equipe || []).map(({ follow_id, name }) => formatEquipeLight({ Profil_id: follow_id, nom: name })),
    club: (club || []).map(({ follow_id, name }) => formatClubLight({ Club_id: follow_id, Club_name: name })),
  };
}

export async function callUserFavoris(user_id?: number, type?: FavorisType) {
  return axiosInstance
    .get<IApiFavorisList>(`${apiUrl.favoris}`, { params: { user_id, type } })
    .then(({ data }) => formatFavoris(data));
}

export function useGetFavoris(params: { type?: FavorisType }, isActive?: boolean) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGet<IApiFavorisList>(isActive !== false ? apiUrl.favoris : null, {
    user_id,
    ...params,
  });
  return { favoris: data ? formatFavoris(data) : undefined, ...rest };
}

export const postFavori = async ({
  isAdd,
  user_id,
  follow_id,
  follow_type,
}: {
  isAdd: boolean;
  user_id: number;
  follow_id: number;
  follow_type: FavorisType;
}) => {
  return axiosPost<IApiFavorisList>(isAdd ? apiUrl.ajoutFavori : apiUrl.deleteFavori, {
    user_id,
    follow_id,
    follow_type,
  }).then(({ data }) => formatFavoris(data));
};
