import { FC, useState, useMemo } from 'react';
import { OmlPageTab, PageContainerWithHeaderAndTabs } from 'features/layout/components/PageContainerWithHeaderAndTabs';
import { useParams } from 'react-router-dom';
import { useGetClub } from 'features/club/club.services';
import ClubPageHeader from 'features/club/components/ClubPageHeader';
import ClubTeamTab from 'features/club/components/ClubTeamTab';

const Club: FC = () => {
  const [tab, setTab] = useState(0);
  const { id } = useParams<{ id: string }>();
  const club_id = useMemo(() => (id ? parseInt(id, 10) : -1), [id]);

  const clubSwrState = useGetClub({ club_id });
  const club = useMemo(() => clubSwrState.data, [clubSwrState]);

  const tabs: OmlPageTab[] = useMemo(
    () => [
      {
        name: 'Equipes du club',
        component: <ClubTeamTab equipes={club?.equipes} />,
        tabSwrState: clubSwrState,
      },
      {
        name: 'Mur',
        component: <div>TODO tab={tab}</div>,
        tabSwrState: clubSwrState,
      },
    ],
    [club?.equipes, clubSwrState, tab]
  );

  return (
    <PageContainerWithHeaderAndTabs
      browserTabTitle={club?.nom}
      headerComponent={club ? <ClubPageHeader club={club} /> : <></>}
      headerSwrState={clubSwrState}
      onTabChange={setTab}
      pageTitle="Profil club"
      tabs={tabs}
    />
  );
};

export default Club;
