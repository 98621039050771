import { FC } from 'react';
import { StyledPanelSubTitle } from 'styles/components/titles';
import { InjectedStepWrapperProps } from './InscriptionStepWrapper';
import { OmlFormikField } from 'features/form/components/OmlFormikField';
import { Row, Col } from 'reactstrap';
import { PlayerPointsFortsFormFragment } from 'features/players/components/PlayerPointsFortsFormFragment';
import { useGetPointsForts, useGetPostes } from 'features/players/players.services';
import FetchApiError from 'features/transversal/components/FetchApiError';
import { Loader } from 'features/layout/components/Loader';

const InscriptionStepQualite: FC<InjectedStepWrapperProps & { isStepActive?: boolean }> = ({
  stepSubmited,
  isStepActive,
}) => {
  const {
    postes,
    isValidating: isValidatingPostes,
    error: errorPostes,
    mutate: mutatePostes,
  } = useGetPostes({}, isStepActive);

  const {
    mental,
    physic,
    technic,
    isValidating: isValidatingPointsForts,
    error: errorPointsForts,
    mutate: mutatePointsForts,
  } = useGetPointsForts(isStepActive);

  return (
    <>
      <StyledPanelSubTitle className="mb-3">Mes caractéristiques</StyledPanelSubTitle>
      {isValidatingPostes ? (
        <Loader />
      ) : errorPostes ? (
        <FetchApiError onRetry={mutatePostes} />
      ) : (
        <>
          <OmlFormikField
            className="mb-5"
            fieldLabel="Ma date de naissance"
            isDropdown={true}
            name="birthdate"
            stepSubmited={stepSubmited}
            type="date"
          />
          <Row className="mb-5">
            <Col xs={7}>
              <OmlFormikField
                fieldLabel="Mon poste"
                name="poste"
                options={postes}
                placeholder="Selectionner le poste"
                stepSubmited={stepSubmited}
                type="select"
                isMulti={false}
              />
            </Col>
            <Col xs={5}>
              <OmlFormikField
                fieldClassName="text-right"
                fieldLabel="Ma taille"
                max="230"
                min="100"
                name="taille"
                step="1"
                stepSubmited={stepSubmited}
                sufix="cm"
                type="number"
              />
            </Col>
          </Row>
        </>
      )}

      <StyledPanelSubTitle className="mb-3">Mes points forts</StyledPanelSubTitle>
      {isValidatingPointsForts ? (
        <Loader />
      ) : errorPointsForts ? (
        <FetchApiError onRetry={mutatePointsForts} />
      ) : (
        <PlayerPointsFortsFormFragment
          className="mb-5"
          isSubmited={stepSubmited}
          mental={mental}
          physic={physic}
          technic={technic}
        />
      )}
    </>
  );
};

export default InscriptionStepQualite;
