import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export interface WindowSize {
  innerWidth: number;
  deviceType: 'mobile' | 'desktop';
}

const BREAKPOINT = { phone: 1024 };

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>();

  useEffect(() => {
    const handleResize = debounce(() => {
      const width = window.innerWidth;
      setWindowSize({ innerWidth: width, deviceType: width < BREAKPOINT.phone ? 'mobile' : 'desktop' });
    }, 166); // 166 corresponds to 10 frames at 60 Hz
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      handleResize.cancel();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
