import { FieldProps } from 'formik';
import { FC, ComponentPropsWithRef, useMemo, useState } from 'react';
import styled from 'styled-components';
import UploadIcon from 'assets/img/pictos/upload.png';
import { colors } from 'styles/variables/colors';

const Photo = styled.div<{ minHeight: number }>`
  display: flex;
  justify-content: center;
  border: 2px dashed ${colors.color_title};
  cursor: pointer;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-image: url(${(props) => props.placeholder});
  min-height: ${(props) => `${props.minHeight}px`};
`;

const PhotoPreview = styled.img`
  height: auto;
  width: 100%;
  z-index: 1;
  object-fit: cover;
`;

const PhotoInput = styled.input<{ minHeight: number }>`
  display: block;
  opacity: 0;
  position: absolute;
  cursor: pointer;
  min-height: ${(props) => `${props.minHeight}px`};
  z-index: 2;
`;

const FormikPhotoUploader: FC<
  FieldProps<string, any> &
    ComponentPropsWithRef<'div'> & {
      defaultImg?: File;
      minHeight?: number;
    }
> = ({ field, form, defaultImg, minHeight = 150, ...props }) => {
  const [currentImage, setCurrentImage] = useState(defaultImg);
  const imgSrc = useMemo(() => (currentImage ? URL.createObjectURL(currentImage) : undefined), [currentImage]);

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageToUpload = e.target.files ? e.target.files[0] : undefined;
    setCurrentImage(imageToUpload);
    form.setFieldValue(field.name, imageToUpload);
  };

  return (
    <Photo minHeight={minHeight} placeholder={currentImage ? undefined : UploadIcon} {...props}>
      {currentImage && <PhotoPreview src={imgSrc} />}
      <PhotoInput accept={'image/*'} minHeight={minHeight} onChange={onImageChange} type="file" />
    </Photo>
  );
};

export { FormikPhotoUploader };
