import { ComponentPropsWithoutRef, FC } from 'react';
import { FieldProps } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import classnames from 'classnames';

const OmlWysiwyg: FC<
  { onChange?: (markup: string) => void; isInvalid?: boolean } & Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> &
    Partial<FieldProps<string, any>>
> = ({ onChange, form, field, isInvalid, className, ...rest }) => {
  const { name } = field || {};
  const { setFieldValue } = form || {};
  function onContentStateChange(newContent: string) {
    if (onChange) {
      onChange(newContent);
    }
    if (name && setFieldValue) {
      setFieldValue(name, newContent);
    }
  }

  return (
    <div className={classnames(className, 'styled-text')} {...rest}>
      <CKEditor
        config={{
          mediaEmbed: { previewsInData: true },
          toolbar: {
            items: [
              'bold',
              'italic',
              '|',
              'numberedList',
              'bulletedList',
              '|',
              'link',
              'uploadImage',
              'blockQuote',
              '|',
              'undo',
              'redo',
            ],
          },
          placeholder: 'Exprime-toi',
        }}
        editor={ClassicEditor}
        onChange={(_, editor) => {
          const data = editor.getData();
          onContentStateChange(data);
        }}
      />
    </div>
  );
};

export { OmlWysiwyg };
