import { FieldProps } from 'formik';
import { ComponentProps, FC } from 'react';
import OmlCheckbox from './OmlCheckbox';

const FormikOmlCheckbox: FC<FieldProps<string, any> & ComponentProps<typeof OmlCheckbox>> = ({
  field,
  form,
  isInvalid,
  ...props
}) => {
  return <OmlCheckbox isInvalid={isInvalid} {...field} {...props} />;
};

export { FormikOmlCheckbox };
