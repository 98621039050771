import { GeneralContext } from 'features/transversal/general.context';
import { useContext } from 'react';
import Slider from 'react-slick';
import { Container } from 'reactstrap';
import { IResultat } from '../match.types';
import BoxResultat from './BoxResultat';

import '../styles/ResultatsSlider.scss';

interface IProps {
  resultats?: IResultat[];
}

const ResultatsSlider = (props: IProps) => {
  const { resultats } = props;
  const { deviceType } = useContext(GeneralContext);

  const slides =
    resultats && resultats.length > 0 ? (
      resultats.map((resultat) => <BoxResultat key={resultat.id} displayChampName={true} resultat={resultat} />)
    ) : (
      <>
        <BoxResultat />
        <BoxResultat />
      </>
    );

  return (
    <Container>
      <Slider
        arrows={deviceType === 'desktop'}
        autoplay={true}
        autoplaySpeed={5000}
        className="results-slider"
        dots={false}
        infinite={true}
        slidesToShow={deviceType === 'mobile' ? 1 : 2}
        speed={1000}
      >
        {slides}
      </Slider>
    </Container>
  );
};

export default ResultatsSlider;
