/* eslint-disable @typescript-eslint/no-use-before-define */
import { Row } from 'reactstrap';
import { IPlayer } from '../players.types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Loader } from 'features/layout/components/Loader';
import { championnatFormatter, equipeFormatter, rowLinkFormatter, photoFormatter } from 'utils/oml-table.utils';

interface IProps {
  players: IPlayer[];
  isLoading: boolean;
}

const columns = [
  {
    dataField: 'id',
    text: '',
    hidden: true,
  },
  {
    dataField: 'image',
    text: '',
    headerStyle: {
      width: '9%',
    },
    classes: 'image-thumb',
    formatter: photoFormatter,
  },
  {
    dataField: 'nom_prenom',
    text: 'joueur',
    headerStyle: {
      width: '14%',
    },
    classes: 'capitalized-link',
    formatter: rowLinkFormatter,
  },
  {
    dataField: 'equipe',
    text: 'équipe',
    headerStyle: {
      width: '22%',
    },
    classes: 'capitalized-link',
    formatter: equipeFormatter,
  },
  {
    dataField: 'categorie',
    text: 'catégorie',
    headerStyle: {
      width: '13%',
    },
    // formatter: championnatCategorieFormatter,
  },
  {
    dataField: 'championnat',
    text: 'championnat',
    headerStyle: {
      width: '22%',
    },
    classes: 'fw-400',
    formatter: championnatFormatter,
  },
  {
    dataField: 'poste',
    text: 'poste',
    headerStyle: {
      width: '12%',
    },
  },
  {
    dataField: 'taille',
    text: 'taille',
    headerStyle: {
      width: '8%',
    },
  },
];

const PlayersTable = (props: IProps) => {
  const { players, isLoading } = props;
  const paginationOptions = {
    sizePerPage: 12,
    hideSizePerPage: true,
  };

  return (
    <Row className="d-flex justify-content-center mr-3">
      {isLoading ? (
        <Loader />
      ) : (
        <BootstrapTable
          bootstrap4={true}
          bordered={false}
          classes="oml-table players-table"
          columns={columns}
          data={players}
          keyField="id"
          pagination={paginationFactory(paginationOptions)}
        />
      )}
    </Row>
  );
};
export default PlayersTable;
