import dayjs, { ConfigType, Dayjs } from 'dayjs';

import customParseFormat from 'dayjs/plugin/customParseFormat';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import weekOfTheYear from 'dayjs/plugin/weekOfYear';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import relativeTime from 'dayjs/plugin/relativeTime';

// Locales MUST BE imported after plugins
import 'dayjs/locale/fr';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekOfTheYear);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(relativeTime);
dayjs.locale('fr');

export const omlDayjs = dayjs;

export type DateType = ConfigType;

export function formatToTwoDigitString(input: number) {
  return input < 10 ? `0${input}` : input.toString();
}

export function formatDateWithUntilNow(input: Dayjs) {
  return `${input.format('Do MMM YYYY HH[h]mm')}, ${input.fromNow()}`;
}
