import { ComponentProps, FC } from 'react';
import classnames from 'classnames';
import { ModalTitle } from 'features/layout/components/Modal/ModalTitle';
import { ModalHeroImage } from 'features/layout/components/Modal/ModalHeroImage';
import { ModalActionBar } from 'features/layout/components/Modal/ModalActionBar';

const ModalBody: FC<
  Pick<ComponentProps<'div'>, 'children' | 'className'> & {
    title: string;
    img: ComponentProps<'img'>['src'];
    actionButtons: ComponentProps<'div'>['children'];
  }
> = ({ className, children, title, img, actionButtons }) => {
  return (
    <>
      {img && <ModalHeroImage src={img} />}
      {title && <ModalTitle>{title}</ModalTitle>}
      <div className={classnames('w-100 px-5 mb-4', className)}>
        <div className="mb-5">{children}</div>
        {actionButtons && <ModalActionBar>{actionButtons}</ModalActionBar>}
      </div>
    </>
  );
};
export { ModalBody };
