import { ComponentPropsWithRef, FC } from 'react';
import styled from 'styled-components';

type IProps = {
  size?: number;
  shape?: 'three-dots' | 'circle';
};

const StyledContainer = styled.div`
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
`;

const Loader: FC<IProps & ComponentPropsWithRef<'div'>> = ({ size = 48, shape = 'circle', className, ...rest }) => {
  return (
    <StyledContainer className={className} {...rest}>
      {shape === 'circle' && (
        <svg
          height={size}
          preserveAspectRatio="xMidYMid"
          viewBox="0 0 100 100"
          width={size}
          xmlns="http://www.w3.org/2000/svg"
        >
          <g transform="translate(80,50)">
            <g transform="rotate(0)">
              <circle cx="0" cy="0" fill="currentColor" fillOpacity="1" r="6">
                <animateTransform
                  attributeName="transform"
                  begin="-0.875s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  type="scale"
                  values="1.5 1.5;1 1"
                />
                <animate
                  attributeName="fill-opacity"
                  begin="-0.875s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="1;0"
                />
              </circle>
            </g>
          </g>
          <g transform="translate(71.21320343559643,71.21320343559643)">
            <g transform="rotate(45)">
              <circle cx="0" cy="0" fill="currentColor" fillOpacity="0.875" r="6">
                <animateTransform
                  attributeName="transform"
                  begin="-0.75s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  type="scale"
                  values="1.5 1.5;1 1"
                />
                <animate
                  attributeName="fill-opacity"
                  begin="-0.75s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="1;0"
                />
              </circle>
            </g>
          </g>
          <g transform="translate(50,80)">
            <g transform="rotate(90)">
              <circle cx="0" cy="0" fill="currentColor" fillOpacity="0.75" r="6">
                <animateTransform
                  attributeName="transform"
                  begin="-0.625s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  type="scale"
                  values="1.5 1.5;1 1"
                />
                <animate
                  attributeName="fill-opacity"
                  begin="-0.625s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="1;0"
                />
              </circle>
            </g>
          </g>
          <g transform="translate(28.786796564403577,71.21320343559643)">
            <g transform="rotate(135)">
              <circle cx="0" cy="0" fill="currentColor" fillOpacity="0.625" r="6">
                <animateTransform
                  attributeName="transform"
                  begin="-0.5s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  type="scale"
                  values="1.5 1.5;1 1"
                />
                <animate
                  attributeName="fill-opacity"
                  begin="-0.5s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="1;0"
                />
              </circle>
            </g>
          </g>
          <g transform="translate(20,50.00000000000001)">
            <g transform="rotate(180)">
              <circle cx="0" cy="0" fill="currentColor" fillOpacity="0.5" r="6">
                <animateTransform
                  attributeName="transform"
                  begin="-0.375s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  type="scale"
                  values="1.5 1.5;1 1"
                />
                <animate
                  attributeName="fill-opacity"
                  begin="-0.375s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="1;0"
                />
              </circle>
            </g>
          </g>
          <g transform="translate(28.78679656440357,28.786796564403577)">
            <g transform="rotate(225)">
              <circle cx="0" cy="0" fill="currentColor" fillOpacity="0.375" r="6">
                <animateTransform
                  attributeName="transform"
                  begin="-0.25s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  type="scale"
                  values="1.5 1.5;1 1"
                />
                <animate
                  attributeName="fill-opacity"
                  begin="-0.25s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="1;0"
                />
              </circle>
            </g>
          </g>
          <g transform="translate(49.99999999999999,20)">
            <g transform="rotate(270)">
              <circle cx="0" cy="0" fill="currentColor" fillOpacity="0.25" r="6">
                <animateTransform
                  attributeName="transform"
                  begin="-0.125s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  type="scale"
                  values="1.5 1.5;1 1"
                />
                <animate
                  attributeName="fill-opacity"
                  begin="-0.125s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="1;0"
                />
              </circle>
            </g>
          </g>
          <g transform="translate(71.21320343559643,28.78679656440357)">
            <g transform="rotate(315)">
              <circle cx="0" cy="0" fill="currentColor" fillOpacity="0.125" r="6">
                <animateTransform
                  attributeName="transform"
                  begin="0s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  type="scale"
                  values="1.5 1.5;1 1"
                />
                <animate
                  attributeName="fill-opacity"
                  begin="0s"
                  dur="1s"
                  keyTimes="0;1"
                  repeatCount="indefinite"
                  values="1;0"
                />
              </circle>
            </g>
          </g>
        </svg>
      )}
      {shape === 'three-dots' && (
        <svg fill="currentColor" height={size} viewBox="0 0 120 30" width={size} xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15" r="15">
            <animate
              attributeName="r"
              begin="0s"
              calcMode="linear"
              dur="0.8s"
              from="15"
              repeatCount="indefinite"
              to="15"
              values="15;9;15"
            />
            <animate
              attributeName="fill-opacity"
              begin="0s"
              calcMode="linear"
              dur="0.8s"
              from="1"
              repeatCount="indefinite"
              to="1"
              values="1;.5;1"
            />
          </circle>
          <circle cx="60" cy="15" fillOpacity="0.3" r="9">
            <animate
              attributeName="r"
              begin="0s"
              calcMode="linear"
              dur="0.8s"
              from="9"
              repeatCount="indefinite"
              to="9"
              values="9;15;9"
            />
            <animate
              attributeName="fill-opacity"
              begin="0s"
              calcMode="linear"
              dur="0.8s"
              from="0.5"
              repeatCount="indefinite"
              to="0.5"
              values=".5;1;.5"
            />
          </circle>
          <circle cx="105" cy="15" r="15">
            <animate
              attributeName="r"
              begin="0s"
              calcMode="linear"
              dur="0.8s"
              from="15"
              repeatCount="indefinite"
              to="15"
              values="15;9;15"
            />
            <animate
              attributeName="fill-opacity"
              begin="0s"
              calcMode="linear"
              dur="0.8s"
              from="1"
              repeatCount="indefinite"
              to="1"
              values="1;.5;1"
            />
          </circle>
        </svg>
      )}
    </StyledContainer>
  );
};

export { Loader };
