import { apiUrl } from 'features/api/apiCalls.enum';
import { axiosDelete, axiosPost, useApiGet } from 'features/api/apiCalls.utils';
import { formatMemberLight } from 'features/member/member.services';
import { omlDayjs } from 'utils/date';
import { joinNomPrenom } from 'utils/strings';
import { IApiComment, IApiPublicationComment, IComment } from './comments.types';
import { useGeneralContextValue } from './general.context';

function formatActuComment({
  id,
  parent_id,
  author_id,
  author_nom,
  author_prenom,
  author_photo,
  body,
  like_count,
  reply_ids,
  liked,
  creation_date,
}: IApiComment): IComment {
  return {
    id,
    parent: parent_id,
    datePublication: omlDayjs(creation_date),
    auteur: formatMemberLight({
      user_id: author_id,
      nom: joinNomPrenom({ nom: author_nom, prenom: author_prenom }),
      photo: author_photo,
    }),
    contenu: body,
    nbLikes: like_count,
    answerIds:
      typeof reply_ids === 'string'
        ? reply_ids.split(',').map((i) => +i)
        : typeof reply_ids === 'number'
        ? [reply_ids]
        : [],
    isLiked: liked === 'true',
  };
}

function formatPublicationComment({
  comment_id,
  parent_id,
  comment_date,
  user_id,
  author_title,
  author_image,
  comment_body,
  like_count,
  like: { isLike },
  reply_on_comment,
}: IApiPublicationComment): IComment[] {
  const parentComment = {
    id: comment_id,
    parent: parent_id,
    datePublication: omlDayjs(comment_date),
    auteur: formatMemberLight({
      user_id,
      nom: author_title,
      photo: author_image,
      isPhotoFullPath: true,
    }),
    contenu: [comment_body],
    nbLikes: like_count,
    answerIds: reply_on_comment ? reply_on_comment.map((r) => r.comment_id) : [],
    isLiked: !!isLike,
  };
  const answerComments = (reply_on_comment || []).reduce<IComment[]>(
    (acc, r) => [...acc, ...formatPublicationComment({ ...r, parent_id: comment_id })],
    []
  );
  return [parentComment, ...answerComments];
}

export function useGetComments(
  { elementId, type }: { elementId: number; type: 'actu' | 'publication' },
  isActive?: boolean
) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGet<
    Record<string, IApiComment> | { status?: never; viewAllComments: IApiPublicationComment[] }
  >(
    isActive !== false ? (type === 'actu' ? apiUrl.getComments : apiUrl.userFeedLikesComments) : null,
    type === 'actu'
      ? {
          user_id,
          news_id: elementId,
        }
      : {
          action_id: elementId,
          viewAllComments: true,
        }
  );

  if (data?.status) {
    delete data.status;
  }
  if (type === 'actu') {
    return { data: data ? Object.values(data).map((d) => formatActuComment(d)) : undefined, ...rest };
  }

  const allComments = data ? (data.viewAllComments as IApiPublicationComment[]) : undefined;
  return {
    data: allComments
      ? allComments.reduce<IComment[]>((acc, d) => [...acc, ...formatPublicationComment(d)], [])
      : undefined,
    ...rest,
  };
}

export async function postComment({
  user_id,
  elementId,
  body: rawBody,
  type,
  comment_id,
  parent_id,
}: {
  user_id: number;
  elementId: number;
  body: string[];
  comment_id?: number;
  parent_id?: number;
  type: 'actu' | 'publication';
}) {
  const body = rawBody.join('\n');
  if (type === 'actu') {
    return axiosPost<never>(apiUrl.postComment, { user_id, news_id: elementId, body, comment_id, parent_id, type });
  }

  if (parent_id) {
    return axiosPost<never>(apiUrl.userFeedAnswerComment, {
      action_id: elementId,
      body,
      comment_id: parent_id,
      subject_id: elementId,
      subject_type: 'activity_action',
    });
  }
  return axiosPost<never>(apiUrl.userFeedComment, { action_id: elementId, body });
}

export async function deleteActuComment(body: { user_id: number; news_id: number; comment_id: number }) {
  return axiosPost<never>(apiUrl.deleteComment, body);
}

export async function deletePublicationComment({ action_id, comment_id }: { action_id: number; comment_id: number }) {
  return axiosDelete<never>(apiUrl.userFeedDeleteComment, {
    action_id,
    comment_id,
    subject_id: action_id,
    subject_type: 'activity_action',
  });
}
