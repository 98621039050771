export const colors = {
  // Partagés avec le CSS
  color_title: '#0c15a0',
  color_blue_background: '#2f38cd',
  color_white: 'white',
  color_focus: '#e8e8ee',
  color_content: '#777777',
  color_red: '#dc3545',
  color_menu: '#b6b6b6',
  color_border_default: '#e6e6e6',

  // Pas dans le css
  colorUnderline: 'rgba(1, 64, 221, .4)',
};
