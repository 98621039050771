import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { TooltipWrapper } from './TooltipWrapper';
import classnames from 'classnames';

const IconWithTooltip: FC<
  FontAwesomeIconProps & { tooltipContent: string; onClick: () => void; iconSize?: number }
> = ({ tooltipContent, className, iconSize, style, ...rest }) => {
  return (
    <TooltipWrapper className={classnames('pointer', className)} tooltipContent={tooltipContent}>
      <FontAwesomeIcon
        style={iconSize ? { width: style?.width || iconSize, height: style?.height || iconSize, ...style } : style}
        {...rest}
      />
    </TooltipWrapper>
  );
};

export { IconWithTooltip };
