import { Container, Row, Col, Alert } from 'reactstrap';

const Forbidden = () => {
  return (
    <div className="fullHeight">
      <Container>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <Alert className="text-center" color="danger">
              Tu ne dispos pas des droits nécessaires pour accéder à cette page
            </Alert>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Forbidden;
