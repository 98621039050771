import { FC, MouseEvent } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import classnames from 'classnames';

type OmlLinkProps = {
  to?: string;
  children: React.ReactNode;
  className?: string;
  disableHover?: boolean;
  colorInverted?: boolean;
  onClick?: (e?: MouseEvent) => void;
};

const OmlLink: FC<OmlLinkProps> = ({ to, children, className, disableHover, colorInverted, onClick }) => {
  const rootClassName = classnames(
    className,
    !to && 'pointer',
    disableHover && 'disable-hover',
    colorInverted && 'color-inverted'
  );

  function handleOnClick(e: MouseEvent) {
    e.stopPropagation();
    if (onClick) {
      onClick(e);
    }
  }
  return to ? (
    <LinkContainer onClick={handleOnClick} to={to}>
      <a className={rootClassName}>{children}</a>
    </LinkContainer>
  ) : (
    <a className={rootClassName} onClick={handleOnClick}>
      {children}
    </a>
  );
};

export function wrapWithOmlLink(link: string, element: React.ReactElement | string): React.ReactElement {
  return link ? <OmlLink to={link}>{element}</OmlLink> : <span>{element}</span>;
}

export default OmlLink;
