import { IApiClub, IClub, IClubLight } from './club.types';
import { routeList } from 'App';
import { useApiGet } from 'features/api/apiCalls.utils';
import { apiUrl } from 'features/api/apiCalls.enum';
import { IApiEquipeLight } from 'features/equipe/equipe.types';
import { formatEquipeLight } from 'features/equipe/equipe.services';
import { formatImage } from 'utils/formatter';

export function formatClubLight({ Club_id, Club_name }: IApiClub): IClubLight {
  return {
    id: Club_id,
    nom: Club_name,
    link: `${routeList.club}/${Club_id}`,
  };
}
export function formatClub(
  club_id: number,
  { header: { city, codePostal, nbJoueur, nbSupporter, followers, name, photo }, Feminin, Masculin, Mixte }: IApiGetClub
): IClub {
  return {
    id: club_id,
    nom: name,
    ville: city,
    link: `${routeList.club}/${club_id}`,
    image: photo ? formatImage(photo) : undefined,
    equipes: {
      feminines: Feminin.map(formatEquipeLight),
      masculines: Masculin.map(formatEquipeLight),
      mixtes: Mixte.map(formatEquipeLight),
    },
    codePostal,
    nbJoueur,
    nbSupporter,
    followers,
  };
}

type IApiGetClub = {
  Feminin: IApiEquipeLight[];
  Masculin: IApiEquipeLight[];
  Mixte: IApiEquipeLight[];
  header: {
    city: string;
    codePostal: string;
    nbJoueur: number;
    nbSupporter: number;
    followers: number;
    name: string;
    photo: string;
  };
};

export function useGetClub({ club_id }: { club_id?: number }) {
  const { data, ...rest } = useApiGet<IApiGetClub>(apiUrl.club, { club_id });
  return { data: data && club_id ? formatClub(club_id, data) : undefined, ...rest };
}

export function useGetClubList() {
  const { data, ...rest } = useApiGet<IApiClub[]>(apiUrl.clubs);
  return { clubs: (data || []).map(formatClubLight), ...rest };
}

export function useGetClubsFromTown(params: { postal?: string }, isActive?: boolean) {
  const { data, ...rest } = useApiGet<IApiClub[]>(isActive !== false ? apiUrl.clubsTown : null, params);
  return { clubs: (data || []).map(formatClubLight), ...rest };
}
