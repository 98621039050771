import { FC, useMemo, useState } from 'react';
import GameImg from 'assets/img/game/img.png';
import { useAuthModal } from 'features/auth/auth.hook';
import { OmlYup } from 'features/form/form.validation';
import { Formik, Form } from 'formik';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import { isSubmitButtonDisabled } from 'features/form/form.utils';
import { IPlayerStatistics } from 'features/players/players.types';
import { OmlFormikField } from 'features/form/components/OmlFormikField';
import { ISelectOption } from 'features/form/form.types';
import OmlCheckbox from 'features/form/components/OmlCheckbox';
import { OmlText } from 'features/transversal/components/OmlText';
import { formatPercent } from 'utils/numbers';
import { postStats } from 'features/players/players.services';

export interface IProps {
  details: IPlayerStatistics['details'];
  mutateStats: () => void;
}

const updateStatsValidationSchema = OmlYup.object({
  gameId: OmlYup.number().required(),
  hasPlayed: OmlYup.boolean(),
  starter: OmlYup.boolean(),
  minutes: OmlYup.number().optional(),
  totalPoints: OmlYup.number(),
  twoPointsSuccess: OmlYup.number(),
  twoPointsAttempt: OmlYup.number(),
  threePointsSuccess: OmlYup.number(),
  threePointsAttempt: OmlYup.number(),
  freeShotsSuccess: OmlYup.number(),
  freeShotsAttempt: OmlYup.number(),
  rebounds: OmlYup.number(),
  counters: OmlYup.number(),
  decisivePasses: OmlYup.number(),
  stls: OmlYup.number(),
  lb: OmlYup.number(),
  fouls: OmlYup.number(),
});
export type UpdateStatsFormState = OmlYup.Asserts<typeof updateStatsValidationSchema>;

type DetailsWithGame = (Omit<IPlayerStatistics['details'][number], 'game'> & {
  game: NonNullable<IPlayerStatistics['details'][number]['game']>;
})[];

const UpdateMemberStatsModal: FC<IProps> = ({ details, mutateStats }) => {
  const detailsFiltered = useMemo(
    () => details.filter((d) => d.type === 'game' && d.game) as DetailsWithGame,
    [details]
  );
  const initialState: Partial<UpdateStatsFormState> = {};
  const [pointDetailsOpened, setPointDetailsOpened] = useState(false);
  const [continueAfterSubmit, setContinueAfterSubmit] = useState(false);
  const [updateIteration, setUpdateIteration] = useState(0);

  const { show, hide, user_id } = useAuthModal();

  const gameOptions: ISelectOption[] = useMemo(
    () =>
      detailsFiltered.map(({ game }) => ({
        label: `Journée ${game.sched_num} - ${game.opponent}`,
        value: game.Sched_id.toString(),
      })),
    [detailsFiltered]
  );

  function handleSubmit({
    gameId,
    hasPlayed,
    starter,
    minutes,
    totalPoints,
    twoPointsAttempt,
    twoPointsSuccess,
    threePointsSuccess,
    threePointsAttempt,
    freeShotsSuccess,
    freeShotsAttempt,
    rebounds,
    counters,
    decisivePasses,
    stls,
    lb,
    fouls,
  }: Partial<UpdateStatsFormState>) {
    if (typeof gameId === 'undefined') {
      return;
    }
    const gameName = gameOptions.find((o) => o.value === gameId.toString())?.label;
    const postStatsParams: Omit<Parameters<typeof postStats>[0], 'user_id'> = {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: detailsFiltered.find((d) => d.game.Sched_id === gameId)!.id,
      played: typeof hasPlayed === 'undefined' ? undefined : hasPlayed ? 1 : 0,
      starter: typeof starter === 'undefined' ? undefined : starter ? 1 : 0,
      mins: minutes,
      pts: totalPoints,
      made2: twoPointsSuccess,
      att2: twoPointsAttempt,
      made3: threePointsSuccess,
      att3: threePointsAttempt,
      made_lf: freeShotsSuccess,
      att_lf: freeShotsAttempt,
      reb: rebounds,
      ctr: counters,
      asst: decisivePasses,
      stls,
      lb,
      fte: fouls,
    };
    if (!user_id) {
      show({
        informationMessage: 'Tu dois être connecté pour soumettre tes stats',
        loginProps: {
          onLoginComplete: async (userId: number) => {
            await postStats({ user_id: userId, ...postStatsParams }, gameName);
            return mutateStats();
          },
        },
      });
    } else {
      postStats({ user_id, ...postStatsParams }, gameName)
        .then(() => {
          return mutateStats();
        })
        .then(() => {
          if (continueAfterSubmit) {
            setUpdateIteration((v) => v + 1);
          } else {
            hide();
          }
        });
    }
  }

  function togglePointDetails() {
    setPointDetailsOpened((v) => !v);
  }
  function toggleContinueAfterSubmit() {
    setContinueAfterSubmit((v) => !v);
  }

  return (
    <Formik
      key={updateIteration}
      initialValues={initialState}
      onSubmit={handleSubmit}
      validationSchema={updateStatsValidationSchema}
    >
      {({ errors, submitCount, isSubmitting, values, setFieldValue, setValues }) => (
        <Form>
          <img alt="" className="cover w-100" src={GameImg} />
          <div className="px-3 pt-5">
            <OmlFormikField
              name="gameId"
              onChange={(e) => {
                const newValue = Number(e.value);
                const detailExistingForThisGame = detailsFiltered.find((d) => d.game.Sched_id === newValue);
                if (!detailExistingForThisGame) {
                  return;
                }
                const {
                  starter,
                  minutes,
                  totalPoints,
                  twoPoints,
                  threePoints,
                  freeShots,
                  rebounds,
                  counters,
                  decisivePasses,
                  stls,
                  lb,
                  fouls,
                } = detailExistingForThisGame;

                setValues({
                  gameId: newValue,
                  hasPlayed: true,
                  starter,
                  minutes,
                  totalPoints,
                  twoPointsSuccess: twoPoints.success,
                  twoPointsAttempt: twoPoints.attempt,
                  threePointsSuccess: threePoints.success,
                  threePointsAttempt: threePoints.attempt,
                  freeShotsSuccess: freeShots.success,
                  freeShotsAttempt: freeShots.attempt,
                  rebounds,
                  counters,
                  decisivePasses,
                  stls,
                  lb,
                  fouls,
                });
              }}
              options={gameOptions}
              isMulti={false}
              placeholder="Sélectionner le match à mettre à jour"
              type="select"
            />

            {values.gameId && (
              <>
                <OmlFormikField className="mt-4" name="hasPlayed" switchLabel="J'ai joué ce match" type="switch" />
                {values.hasPlayed && (
                  <>
                    <OmlFormikField
                      className="mt-3"
                      name="starter"
                      switchLabel="J'ai fait partie du 5 de départ"
                      type="switch"
                    />
                    <OmlFormikField
                      fieldLabel="Nombre de minutes jouées"
                      max="40"
                      min="0"
                      name="minutes"
                      oneLine
                      step="1"
                      style={{ width: 200 }}
                      sufix="min"
                      type="number"
                    />
                    <div className="pointer" onClick={!pointDetailsOpened ? togglePointDetails : undefined}>
                      <OmlFormikField
                        className="mb-0"
                        disabled={pointDetailsOpened}
                        fieldLabel="Nombre de points marqués"
                        min="0"
                        name="totalPoints"
                        onClick={(e) => e.stopPropagation()}
                        oneLine
                        step="1"
                        style={{ width: 200 }}
                        sufix="pts"
                        type="number"
                      />
                      {!pointDetailsOpened && (
                        <OmlText tag="div" variant="tiny">
                          Cliquer pour renseigner le détail
                        </OmlText>
                      )}
                    </div>
                    {pointDetailsOpened && (
                      <div className="ml-3">
                        {[
                          { name: 'à 2 points', id: 'twoPoints' },
                          { name: 'à 3 points', id: 'threePoints' },
                          { name: 'francs', id: 'freeShots' },
                        ].map(({ name, id }) => (
                          <div key={id} className="d-flex justify-content-between align-items-center">
                            <OmlText className="mr-3" tag="div" variant="p">
                              Lancers {name}
                            </OmlText>
                            <div className="d-flex align-items-center">
                              <div className="mr-3">
                                <OmlFormikField
                                  fieldLabel="réussis"
                                  min="0"
                                  name={`${id}Success`}
                                  onChange={(e) => {
                                    const newValue = Number(e.target.value);
                                    setFieldValue(e.target.name, newValue);
                                    const twoPoints =
                                      (id === 'twoPoints' ? newValue : values.twoPointsSuccess || 0) * 2;
                                    const threePoints =
                                      (id === 'threePoints' ? newValue : values.threePointsSuccess || 0) * 3;
                                    const freeShotsPoints =
                                      id === 'freeShots' ? newValue : values.freeShotsSuccess || 0;
                                    setFieldValue('totalPoints', twoPoints + threePoints + freeShotsPoints);
                                    if ((values[`${id}Attempt`] || 0) < newValue) {
                                      setFieldValue(`${id}Attempt`, newValue);
                                    }
                                  }}
                                  oneLine
                                  step="1"
                                  style={{ width: 50 }}
                                  type="number"
                                />
                              </div>
                              <div className="mr-3">
                                <OmlFormikField
                                  fieldLabel="tentés"
                                  min={values[`${id}Success`]}
                                  name={`${id}Attempt`}
                                  oneLine
                                  step="1"
                                  style={{ width: 50 }}
                                  type="number"
                                />
                              </div>
                              <OmlText tag="div" variant="p">
                                {`Taux: ${formatPercent(
                                  values[`${id}Attempt`] > 0
                                    ? +values[`${id}Success`] / +values[`${id}Attempt`]
                                    : undefined
                                )}`}
                              </OmlText>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <OmlFormikField
                      fieldLabel="Rebonds"
                      min="0"
                      name="rebounds"
                      oneLine
                      step="1"
                      style={{ width: 200 }}
                      type="number"
                    />
                    <OmlFormikField
                      fieldLabel="Contres"
                      min="0"
                      name="counters"
                      oneLine
                      step="1"
                      style={{ width: 200 }}
                      type="number"
                    />
                    <OmlFormikField
                      fieldLabel="Passes décisives"
                      min="0"
                      name="decisivePasses"
                      oneLine
                      step="1"
                      style={{ width: 200 }}
                      type="number"
                    />
                    <OmlFormikField
                      fieldLabel="Interceptions"
                      min="0"
                      name="stls"
                      oneLine
                      step="1"
                      style={{ width: 200 }}
                      type="number"
                    />
                    <OmlFormikField
                      fieldLabel="Balles perdues"
                      min="0"
                      name="lb"
                      oneLine
                      step="1"
                      style={{ width: 200 }}
                      type="number"
                    />
                    <OmlFormikField
                      fieldLabel="Fautes"
                      min="0"
                      name="fouls"
                      oneLine
                      step="1"
                      style={{ width: 200 }}
                      type="number"
                    />
                  </>
                )}
              </>
            )}

            <div className="mt-4 mb-3">
              <OmlCheckbox
                checkboxLabel="Je veux continuer à mettre à jour des matchs après celui-ci"
                checked={continueAfterSubmit}
                onClick={toggleContinueAfterSubmit}
                readOnly
              />
            </div>
            <div className="d-flex justify-content-center mb-4">
              <OmlSubmitButton
                disabled={isSubmitButtonDisabled({ isSubmitting, errors, submitCount })}
                isLoading={isSubmitting}
                texte="valider"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { UpdateMemberStatsModal };
