export enum MenuLabel {
  sports = 'Sports',
  championnats = 'Championnats',
  equipes = 'Equipes',
  joueurs = 'Joueurs',
  favoris = 'Mes favoris',
  recherche = 'Recherche',
  profil = 'Mon compte',
  notifications = 'Notifications',
}

export enum TabNameFavoris {
  joueurs = 'joueurs',
  championnats = 'championnats',
  clubs = 'clubs',
  equipes = 'equipes',
}
