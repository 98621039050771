import { FC } from 'react';
import { IClub } from '../club.types';
import { PageHeader } from 'features/layout/components/PageHeader';
import AddToFavorite from 'features/transversal/components/AddToFavorite';

export interface IProps {
  club: IClub;
}

const ClubPageHeader: FC<IProps> = ({ club: { nom, id, image, ville, codePostal, nbJoueur, nbSupporter } }: IProps) => {
  return (
    <PageHeader
      heading1={
        <>
          {nom} <span className="font-intermediate">(club)</span>
        </>
      }
      logo={image}
      statistics={[
        { count: nbJoueur, singular: 'Joueur membre', plural: 'Joueurs membres' },
        { count: nbSupporter, singular: 'Supporter', plural: 'Supporters' },
      ]}
      subHeading={
        <>
          {ville} {codePostal}
        </>
      }
      topRightElement={<AddToFavorite id={id} type="club" />}
    />
  );
};

export default ClubPageHeader;
