import { FC } from 'react';
import { Modal, ModalProps } from 'reactstrap';

const ModalContainer: FC<ModalProps> = ({ centered = true, ...rest }) => {
  return (
    <div>
      <Modal centered={centered} {...rest} />
    </div>
  );
};

export default ModalContainer;
