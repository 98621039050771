import { routeList } from 'App';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const AuthRoute: FC = () => {
  const { user } = useGeneralContextValue();
  const location = useLocation();

  return user ? <Outlet /> : <Navigate to={routeList.login} state={{ from: location }} />;
};

export default AuthRoute;
