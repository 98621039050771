export function dataURLtoFile(dataUrl: string, filename: string) {
  const arr = dataUrl.split(',');
  if (!arr.length) {
    return undefined;
  }
  const match = arr[0].match(/:(.*?);/);
  if (!match?.length || match.length < 2) {
    return undefined;
  }
  const mime = match[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n) {
    u8arr[n] = bstr.charCodeAt(n);
    n -= 1;
  }

  return new File([u8arr], filename, { type: mime });
}
