import { FieldProps } from 'formik';
import { FC, ComponentPropsWithRef, useMemo, useState } from 'react';
import styled from 'styled-components';
import NoPhotoImg from 'assets/img/no_photo_thumb.png';

const Avatar = styled.div<{ size: number }>`
  display: flex;
  justify-content: center;
  width: ${(props) => `${props.size}px`};
  height: ${(props) => `${props.size}px`};
  border: 2px dashed #9b9b9b;
  border-radius: 50%;
  cursor: pointer;
  background-color: #f7f7f7;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: 100%;
  background-image: url(${(props) => props.placeholder});
`;

const AvatarPreview = styled.img`
  height: auto;
  width: 100%;
  border-radius: 50%;
  z-index: 1;
  object-fit: cover;
`;

const AvatarInput = styled.input<{ size: number }>`
  display: block;
  opacity: 0;
  border-radius: 50%;
  position: absolute;
  width: ${(props) => (props.size ? `${props.size}px` : '150px')};
  height: ${(props) => (props.size ? `${props.size}px` : '150px')};
  cursor: pointer;
  z-index: 2;
`;

const SIZE = 150;

const FormikAvatarUploader: FC<
  FieldProps<string, any> &
    ComponentPropsWithRef<'div'> & {
      defaultImg?: File;
    }
> = ({ field, form, defaultImg, ...props }) => {
  const [currentImage, setCurrentImage] = useState(defaultImg);
  const imgSrc = useMemo(() => (currentImage ? URL.createObjectURL(currentImage) : undefined), [currentImage]);

  const onImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const imageToUpload = e.target.files ? e.target.files[0] : undefined;
    setCurrentImage(imageToUpload);
    form.setFieldValue(field.name, imageToUpload);
  };

  return (
    <Avatar placeholder={currentImage ? undefined : NoPhotoImg} size={SIZE} {...props}>
      {currentImage && <AvatarPreview src={imgSrc} />}
      <AvatarInput accept={'image/*'} onChange={onImageChange} size={SIZE} type="file" />
    </Avatar>
  );
};

export { FormikAvatarUploader };
