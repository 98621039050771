import { OmlYup } from 'features/form/form.validation';
import { ComponentPropsWithRef, FC, useState } from 'react';
import { Formik, Form } from 'formik';
import { OmlFormikField } from 'features/form/components/OmlFormikField';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import { Row, Col } from 'reactstrap';
import OmlLink from 'features/transversal/components/OmlLink';
import { routeList } from 'App';
import { callLogin } from 'features/auth/auth.services';
import { useNavigate } from 'react-router-dom';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { isSubmitButtonDisabled } from 'features/form/form.utils';
import { callUserFavoris, FAVORITES_DEFAULT } from 'features/user/favoris.services';

const loginValidationSchema = OmlYup.object({
  email: OmlYup.string().required().email('Adresse email invalide').required(),
  password: OmlYup.string().required().password().required(),
});
export type LoginFormState = OmlYup.Asserts<typeof loginValidationSchema>;
const initialState: LoginFormState = {
  email: '',
  password: '',
};

export type LoginFormProps = {
  callbackUrl?: string;
  onLoginComplete?: (user_id: number) => Promise<any>;
} & ComponentPropsWithRef<'form'>;

const LoginForm: FC<LoginFormProps> = ({ callbackUrl, onLoginComplete, ...rest }) => {
  const { setUser, setFavoris } = useGeneralContextValue();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>();
  async function handleSubmit(values: LoginFormState) {
    setErrorMessage(undefined);
    return callLogin(values)
      .then((user) => {
        if (setUser) {
          setUser(user);
        }
        return user.id;
      })
      .then(async (userId) => {
        if (!userId) {
          return Promise.resolve({ userId, favoris: FAVORITES_DEFAULT });
        }
        return { userId, favoris: await callUserFavoris(userId) };
      })
      .then(({ userId, favoris }) => {
        if (setFavoris) {
          setFavoris(favoris);
        }
        return userId;
      })
      .then((user_id) => {
        if (onLoginComplete) {
          return onLoginComplete(user_id);
        }
        const redirectUrl = callbackUrl || `${routeList.profil}`;
        return navigate(redirectUrl);
      })
      .catch((message) => setErrorMessage(message));
  }

  return (
    <Formik initialValues={initialState} onSubmit={handleSubmit} validationSchema={loginValidationSchema}>
      {({ errors, submitCount, isSubmitting }) => (
        <Form {...rest}>
          {errorMessage && <div className="text-danger pre-wrap mb-5">{errorMessage}</div>}

          <OmlFormikField className="mb-2" name="email" placeholder="Adresse e-mail" type="text" />
          <OmlFormikField className="mb-3" name="password" placeholder="Mot de passe" type="password" />
          <Row className="mb-5" style={{ fontSize: 13 }}>
            <Col className="text-right">
              <OmlLink to={routeList.forgotPassword}>Mot de passe oublié ?</OmlLink>
            </Col>
          </Row>
          <OmlSubmitButton
            disabled={isSubmitButtonDisabled({ isSubmitting, errors, submitCount })}
            isLoading={isSubmitting}
            texte="Se connecter"
          />
        </Form>
      )}
    </Formik>
  );
};

export { LoginForm };
