import { createContext, FC, useState } from 'react';
import { Container, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Progress } from 'reactstrap';
import classnames from 'classnames';
import { LoginForm } from '../../features/auth/components/LoginForm';
import { Inscription } from './Inscription';
import PageTitle from 'features/layout/components/PageTitle';
import { routeList } from 'App';

import './Login.scss';
import { StyledPanelTitle } from 'styles/components/titles';

type AuthContainerTab = 'login' | 'inscription';

export const AuthContainerContext = createContext<{ progress: number; setProgress?: (n: number) => void }>({
  progress: 0,
});

const AuthContainer: FC<{ tab: AuthContainerTab }> = ({ tab }) => {
  const [progress, setProgress] = useState<number>(0);

  const title = tab === 'login' ? 'Se connecter' : "S'inscrire";

  return (
    <AuthContainerContext.Provider value={{ progress, setProgress }}>
      <Container className="mt-3">
        <PageTitle browserTabTitle={title}>{title}</PageTitle>
        <Row>
          <Col className="oml-login-container" lg={{ size: 6, offset: 3 }} xs={{ size: 10, offset: 1 }}>
            <Row>
              <Nav className="w-100" tabs={true}>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: tab === 'login',
                    })}
                    href={routeList.login}
                  >
                    Connexion
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: tab === 'inscription',
                    })}
                    href={routeList.inscription}
                  >
                    <Progress className="w-100 rounded-0" value={progress} />
                    <div>Inscription</div>
                  </NavLink>
                </NavItem>
              </Nav>
            </Row>
            <Row>
              <Col lg={{ size: 8, offset: 2 }} xs={{ size: 10, offset: 1 }}>
                <TabContent activeTab={tab} className="mt-5">
                  <TabPane tabId="login">
                    {tab === 'login' && (
                      <>
                        <StyledPanelTitle className="text-center my-5">
                          <div>Pour te connecter</div>
                          <div>entre ton e-mail et mot de passe ci-dessous</div>
                        </StyledPanelTitle>
                        <LoginForm />
                      </>
                    )}
                  </TabPane>
                  <TabPane tabId="inscription">{tab === 'inscription' && <Inscription />}</TabPane>
                </TabContent>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </AuthContainerContext.Provider>
  );
};

export { AuthContainer };
