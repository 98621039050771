import { FC, useContext, useEffect, useState } from 'react';
import { champNiveau } from 'features/championnat/championnat.enums';
import { SubMenuNavItem, MenuTab, VerticalSeparator } from './MenuFragments';
import { MenuLabel } from '../menu.enum';
import SubMenuChampionnatRegDept from './SubMenuChampionnatRegDept';
import SubMenuChampionnatNat from './SubMenuChampionnatNat';
import { Collapse } from 'reactstrap';
import { MenuContext } from './Menu';
import classnames from 'classnames';

interface IProps {
  isPhone?: boolean;
}

const SubMenuChampionnat: FC<IProps> = ({ isPhone }: IProps) => {
  const { selectedItem } = useContext(MenuContext);
  const [selectedTab, setSelectedTab] = useState<champNiveau | undefined>(isPhone ? undefined : champNiveau.national);

  useEffect(() => {
    if (isPhone && selectedItem !== MenuLabel.championnats) {
      setSelectedTab(undefined);
    }
  }, [isPhone, selectedItem]);

  function toggle(tab: champNiveau) {
    return setSelectedTab(tab === selectedTab ? undefined : tab);
  }

  // Menu mobile
  if (isPhone) {
    return (
      <Collapse className="sub-menu-items" isOpen={selectedItem === MenuLabel.championnats}>
        <SubMenuNavItem
          isOpen={selectedTab === champNiveau.national}
          name="Nationaux"
          onClick={() => toggle(champNiveau.national)}
        >
          <SubMenuChampionnatNat isPhone={true} isSelected={selectedTab === champNiveau.national} />
        </SubMenuNavItem>
        <SubMenuNavItem
          isOpen={selectedTab === champNiveau.regional}
          name="Régionaux"
          onClick={() => toggle(champNiveau.regional)}
        >
          <SubMenuChampionnatRegDept
            isPhone={true}
            isSelected={selectedTab === champNiveau.regional}
            level={champNiveau.regional}
          />
        </SubMenuNavItem>
        <SubMenuNavItem
          isOpen={selectedTab === champNiveau.departemental}
          name="Départementaux"
          onClick={() => toggle(champNiveau.departemental)}
        >
          <SubMenuChampionnatRegDept
            isPhone={true}
            isSelected={selectedTab === champNiveau.departemental}
            level={champNiveau.departemental}
          />
        </SubMenuNavItem>
      </Collapse>
    );
  }

  // Menu desktop => need to hide the menu instead of removing it in case it is not active to make sure not to loose the form contexts on menu closing
  return (
    <div className={classnames('championnats', selectedItem !== MenuLabel.championnats && 'd-none')}>
      <ul className="nav flex-column subMenu-tabs">
        <MenuTab changeTab={setSelectedTab} selectedTab={selectedTab} tab={champNiveau.national} title="Nationaux" />
        <MenuTab changeTab={setSelectedTab} selectedTab={selectedTab} tab={champNiveau.regional} title="Régionaux" />
        <MenuTab
          changeTab={setSelectedTab}
          selectedTab={selectedTab}
          tab={champNiveau.departemental}
          title="Départementaux"
        />
      </ul>
      <VerticalSeparator />

      <SubMenuChampionnatNat isPhone={false} isSelected={selectedTab === champNiveau.national} />
      <SubMenuChampionnatRegDept
        isPhone={false}
        isSelected={selectedTab === champNiveau.regional}
        level={champNiveau.regional}
      />
      <SubMenuChampionnatRegDept
        isPhone={false}
        isSelected={selectedTab === champNiveau.departemental}
        level={champNiveau.departemental}
      />
    </div>
  );
};
export default SubMenuChampionnat;
