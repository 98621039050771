import { FC, useContext } from 'react';
import { Collapse } from 'reactstrap';
import { MenuLabel } from '../menu.enum';
import { SubMenuNavItem } from './MenuFragments';
import { MenuContext } from './Menu';
import OmlLink from 'features/transversal/components/OmlLink';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { authLocalStorage } from 'features/auth/auth.localStorage';
import { useNavigate } from 'react-router-dom';
import { routeList } from 'App';
import { newToast } from 'features/layout/components/Toaster';
import { severityLevel } from 'features/layout/layout.enums';
import ConfirmationModal from 'features/layout/components/ConfirmationModal';
import { useModal } from 'features/layout/layout.utils';

const SubMenuProfilMobile: FC = () => {
  const { selectedItem, setMobileMenuIsOpen } = useContext(MenuContext);
  const { user, setUser } = useGeneralContextValue();
  const navigate = useNavigate();
  const { show } = useModal();

  function handleOnClick() {
    if (setMobileMenuIsOpen) {
      setMobileMenuIsOpen(false);
    }
  }

  function handleLogout() {
    authLocalStorage.logout();
    if (setUser) {
      setUser(undefined);
    }
    navigate(routeList.home);
    newToast({ message: 'Déconnecté avec succès', severity: severityLevel.success });
  }

  function showLogoutConfirmationModal() {
    handleOnClick();
    show({
      children: (
        <ConfirmationModal
          message="Es tu sur de vouloir te déconnecter ?"
          onConfirm={handleLogout}
          title="Déconnexion"
        />
      ),
    });
  }
  return user ? (
    <Collapse className="sub-menu-items" isOpen={selectedItem === MenuLabel.profil}>
      <SubMenuNavItem
        level={2}
        name={
          <OmlLink onClick={handleOnClick} to={user.link}>
            Mon profil
          </OmlLink>
        }
      />
      <SubMenuNavItem level={2} name={<div onClick={showLogoutConfirmationModal}>Déconnexion</div>} />
    </Collapse>
  ) : null;
};

export default SubMenuProfilMobile;
