/** NOTES
 *
 * Recaptcha
 * public: 6Ld-oxkaAAAAAJgemhRv1JK0o4f-WhdDdQMcQJ7n
 * secret: 6Ld-oxkaAAAAAHfWSwHhz9EK73TW6MZq-CM55oK1
 *
 *
 */

import { FC, useEffect, useRef } from 'react';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import Helmet from 'react-helmet';

import { Menu } from 'features/menu/components/Menu';
import Toaster from 'features/layout/components/Toaster';
import AuthRoute from 'features/auth/components/AuthRoute';
import ScrollToTop from 'features/layout/components/ScrollToTop';
import Footer from 'features/layout/components/Footer';

import Forbidden from 'scenes/Forbidden';
import NotFound from 'scenes/NotFound';
import Home from 'scenes/Home/Home';
import { AuthContainer } from 'scenes/Auth/AuthContainer';
import Players from 'scenes/Players';
import Actus from 'scenes/Actus';
// import Search from 'scenes/Search';
import Championnat from 'scenes/Championnat/Championnat';
import Match from 'scenes/Match/Match';
import Club from 'scenes/Club';
import { Profil } from 'scenes/Profil';
import Member from 'scenes/Member';
import Team from 'scenes/Team';
import { metadata } from './features/layout/layout.enums';
import {
  GeneralContext,
  GenericModalContext,
  useCreateGeneralContextValue,
} from 'features/transversal/general.context';
import { callUserProfile } from 'features/auth/auth.services';
import { FullPageLoader } from 'features/layout/components/FullPageLoader';
import Test from 'scenes/Test';
import GenericModal, { GenericModalProps } from 'features/layout/components/Modal/GenericModal';
import { callUserFavoris, FAVORITES_DEFAULT } from 'features/user/favoris.services';

export enum routeList {
  test = '/test',
  forbidden = '/interdit',
  unknown = '/404',

  // Gestion du compte
  home = '/',
  login = '/login',
  inscription = '/inscription',
  forgotPassword = '/mot-de-passe-oublie',
  profil = '/profil',

  search = '/recherche',
  players = '/joueurs',
  match = '/match',
  championnat = '/championnat',
  actus = '/actus',
  actu = '/actu',
  resultat = '/resultat',
  club = '/club',
  equipe = '/equipe',
  member = '/member',
  teamplayers = '/teamplayers',

  // Footer
  mentionsLegales = '/mentions-legales',
  conditions = '/conditions',
  reclamations = '/reclamations',
  contact = '/contact',
  viePrivee = '/vie-privee',
}

const { lang, siteTitle, description, author } = metadata;

const App: FC = () => {
  library.add(fas);
  library.add(far);

  const contextValue = useCreateGeneralContextValue();
  const { setUser, setIsAppInit, isAppInit, setFavoris } = contextValue;
  const genericModalRef = useRef<GenericModalProps>(null);

  useEffect(() => {
    callUserProfile()
      .then((userFetched) => {
        if (setUser) {
          setUser(userFetched);
        }
        return userFetched;
      })
      .then((userFetched) => {
        if (!userFetched) {
          return Promise.resolve(FAVORITES_DEFAULT);
        }
        return callUserFavoris(userFetched.id);
      })
      .then((favoris) => {
        if (setFavoris) {
          setFavoris(favoris);
        }
      })
      .catch((e: Error) => {
        if (e.message === 'notLoggedIn') {
          return;
        }
        console.error("Erreur lors de l'appel du profil utilisateur ", e);
      })
      .finally(() => {
        if (setIsAppInit) {
          setIsAppInit(true);
        }
      });
  }, [setUser, setIsAppInit, setFavoris]);

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{siteTitle}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={author} />
        <meta name="twitter:title" content={siteTitle} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      <Router>
        {isAppInit ? (
          <GeneralContext.Provider value={contextValue}>
            <GenericModalContext.Provider value={genericModalRef}>
              <ScrollToTop>
                <div className="app">
                  {process.env.REACT_APP_SAFE_MODE !== 'ON' && <Menu />}
                  <main className="fullHeight">
                    <Toaster />
                    <Routes>
                      <Route element={<Home />} path={routeList.home} />
                      <Route element={<AuthContainer tab="login" />} path={routeList.login} />
                      <Route element={<AuthContainer tab="inscription" />} path={routeList.inscription} />
                      <Route element={<Players />} path={routeList.players} />

                      <Route element={<Championnat />} path={`${routeList.championnat}/:id/journee/:journee`} />
                      <Route element={<Championnat />} path={`${routeList.championnat}/:id`} />

                      <Route element={<Actus />} path={routeList.actus} />
                      <Route element={<Match />} path={`${routeList.match}/:id`} />
                      <Route element={<Club />} path={`${routeList.club}/:id`} />
                      {/* <Route component={Search} path={routeList.search} /> */}
                      <Route element={<Member />} path={`${routeList.member}/:id`} />
                      <Route element={<Team />} path={`${routeList.equipe}/:id`} />

                      <Route element={<AuthRoute />} path={`${routeList.profil}`}>
                        <Route element={<Profil />} path={`${routeList.profil}`} />
                      </Route>

                      {/* TODO : to remove */}
                      <Route element={<Test />} path={routeList.test} />

                      <Route element={<AuthRoute />} path={routeList.forbidden}>
                        <Route element={<Forbidden />} path={routeList.forbidden} />
                      </Route>
                      <Route element={<AuthRoute />} path="*">
                        <Route element={<NotFound />} path="*" />
                      </Route>
                    </Routes>
                  </main>
                  <Footer />
                </div>
                <GenericModal ref={genericModalRef} />
              </ScrollToTop>
            </GenericModalContext.Provider>
          </GeneralContext.Provider>
        ) : (
          <FullPageLoader />
        )}
      </Router>
    </>
  );
};

export default App;
