import { IApiChampionnat, IChampionnat, IChampionnatLight } from './championnat.types';
import { routeList } from 'App';
import { formatSport } from '../sport/sports.service';
import { IApiRegion, IRegion, IApiDepartement } from '../transversal/transversal.types';
import { useApiGet } from 'features/api/apiCalls.utils';
import { apiUrl } from 'features/api/apiCalls.enum';
import { champNiveau } from './championnat.enums';
import { IApiClassement } from './classement.types';
import { formatClassement } from './classement.services';
import { useGeneralContextValue } from 'features/transversal/general.context';

const getPouleLong = (poule: IApiChampionnat['poule']) =>
  poule && poule.length < 3 ? ['Poule', poule].join(' ') : poule;

const getNomLong = (
  nom: IApiChampionnat['nom'],
  poule: IApiChampionnat['poule'] | null,
  categorie: IApiChampionnat['categorie'] | null
) => {
  let nomLong = nom;
  if (poule) {
    nomLong += ` - ${getPouleLong(poule)}`;
  }
  if (categorie) {
    nomLong += ` - ${categorie}`;
  }
  return nomLong;
};

const buildEntitiesFromString = (
  optionsStr = ''
): {
  id: string;
  nom: string;
}[] => {
  const entities: {
    id: string;
    nom: string;
  }[] = [];
  const tab = optionsStr.split(',');

  for (let i = 0; i < tab.length; i += 1) {
    const values = tab[i].split(':');
    const id = values[1];
    const entity = {
      nom: values[0],
      id,
    };
    entities.push(entity);
  }

  return entities;
};

export function formatChampionnat(championnat: IApiChampionnat): IChampionnat {
  const {
    id,
    nom,
    poule,
    categorie,
    Sport_cod,
    Ligue,
    nomCourt,
    journee,
    journees,
    genre,
    niveau,
    poules,
    regionId,
    departementId,
    Menu,
  } = championnat;
  const pouleLong = getPouleLong(poule);
  return {
    id,
    nom,
    link: `${routeList.championnat}/${id}`,
    poule,
    pouleLong,
    categorie,
    nomLong: getNomLong(nom, poule || null, categorie),
    sport: Sport_cod ? formatSport(Sport_cod) : undefined,
    ligue: Ligue,
    nomCourt,
    journee,
    genre,
    niveau,
    regionId,
    departementId,
    otherPoules: buildEntitiesFromString(poules).map((p) => ({
      ...p,
      id: Number(p.id),
      link: `${routeList.championnat}/${id}`,
    })),
    nbJournees: journees,
    resultsList: [],
    classementsList: [],
    Menu,
  };
}

export function formatChampionnatLight(
  champ: {
    nom: IApiChampionnat['nom'];
    poule?: IApiChampionnat['poule'];
    categorie?: IApiChampionnat['categorie'];
    genre?: IApiChampionnat['genre'];
  } & (
    | {
        champLink: string;
        type: 'withLink';
      }
    | {
        id: IApiChampionnat['id'];
        type: 'withId';
      }
  )
): IChampionnatLight {
  const { nom, poule, categorie, genre } = champ;
  const pouleLong = poule ? getPouleLong(poule) : undefined;
  let champId: number;
  if (champ.type === 'withId') {
    champId = champ.id;
  } else {
    const linkAsArray = champ.champLink.split('/');
    champId = Number(linkAsArray[linkAsArray.length - 1]);
  }

  return {
    id: champId,
    nom,
    poule,
    categorie,
    pouleLong,
    nomLong: getNomLong(nom, poule || null, categorie || null),
    link: `${routeList.championnat}/${champId}`,
    genre,
  };
}

export function formatRegion(region: IApiRegion): IRegion {
  const { Reg_cod, Reg_name } = region;
  return {
    id: Reg_cod,
    nom: Reg_name,
  };
}

export function formatDepartment(department: IApiDepartement): IRegion {
  const { Dpt_cod, Dpt_name } = department;
  return {
    id: Dpt_cod,
    nom: Dpt_name,
  };
}

export function useGetChampionnat(params: { id: number }) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGet<IApiChampionnat[]>(apiUrl.championnat, { ...params, user_id });
  return { data: data && data.length ? formatChampionnat(data[0]) : undefined, ...rest };
}

export function useGetChampionnatList(params: { division?: champNiveau; location?: string }, isActive: boolean) {
  const { data, ...rest } = useApiGet<IApiChampionnat[]>(isActive !== false ? apiUrl.championnats : null, params);
  return { championnats: (data || []).map(formatChampionnat), ...rest };
}

export function useGetClassements(params: { id: number; journee?: number }, isActive: boolean) {
  const { data, ...rest } = useApiGet<{ elements: IApiClassement[] }>(
    isActive !== false ? apiUrl.classement : null,
    params
  );
  return { classements: (data?.elements || []).map(formatClassement), ...rest };
}

export function useGetRegionsAndDepartment(mode: champNiveau.departemental | champNiveau.regional, isActive?: boolean) {
  const { data, ...rest } = useApiGet<(IApiRegion | IApiDepartement)[]>(isActive !== false ? apiUrl.regions : null, {
    dpt_only: mode === champNiveau.departemental,
  });
  return {
    regDepts: (data || []).map((d) =>
      mode === champNiveau.departemental ? formatDepartment(d as IApiDepartement) : formatRegion(d as IApiRegion)
    ),
    ...rest,
  };
}
