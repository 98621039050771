import { useState, useEffect, FormEvent } from 'react';
import '../styles/PlayersSearch.scss';

interface IProps {
  defaultQuery: string;
  onQueryChange: (query: string) => void;
}

const PlayersSearch = (props: IProps) => {
  const { onQueryChange, defaultQuery } = props;
  const [query, setQuery] = useState(defaultQuery);

  useEffect(() => {
    if (defaultQuery === '') {
      setQuery(defaultQuery);
    }
  }, [defaultQuery]);

  const handleQueryChange = (event: FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setQuery(value);
    if (value.length === 0 || value.length > 2) {
      onQueryChange(value);
    }
  };
  return <input onChange={handleQueryChange} placeholder="Filtrer par nom" type="text" value={query} />;
};
export default PlayersSearch;
