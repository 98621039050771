import { FC, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { Props as SelectProps, PropsValue } from 'react-select';
import { ISelectOption } from '../form.types';
import { colors } from 'styles/variables/colors';
import { FieldProps } from 'formik';

type Props = Omit<SelectProps<ISelectOption>, 'component' | 'styles' | 'isMulti' | 'onChange' | 'options'> & {
  invertedColors?: boolean;
  width?: string;
  isInvalid?: boolean;
  isAsync?: boolean;
  options?: ISelectOption[];
} & (
    | { isMulti: true; onChange?: (val: ISelectOption[]) => void }
    | {
        isMulti: false;
        onChange?: (val: ISelectOption) => void;
      }
  );

type OmlDropdownValue = PropsValue<ISelectOption>;
const OmlDropdown: FC<Props & Partial<FieldProps<OmlDropdownValue, any>>> = (props) => {
  const {
    field,
    form,
    invertedColors,
    width,
    isInvalid,
    onChange,
    placeholder = 'Selectionner...',
    loadingMessage = () => 'Chargement...',
    noOptionsMessage = () => '',
    value,
    isAsync,
    options = [],
    isMulti,
    ...rest
  } = props;

  const { name, value: fieldValue } = field || {};
  const { setFieldValue } = form || {};
  const { color_white, color_title, color_focus, colorUnderline, color_content, color_red } = colors;

  const handleChange = useCallback(
    (v: OmlDropdownValue) => {
      if (setFieldValue && name) {
        if (isMulti) {
          setFieldValue(name, v || undefined);
        } else {
          setFieldValue(name, (v as ISelectOption)?.value || undefined);
        }
      }
      if (v && onChange) {
        if (isMulti) {
          onChange(v as ISelectOption[]);
        } else {
          onChange(v as ISelectOption);
        }
      }
    },
    [setFieldValue, name, onChange, isMulti]
  );

  const omlDropDownStyle = {
    option: (provided: any, state: any) => {
      if (state.isFocused) {
        return {
          ...provided,
          backgroundColor: color_focus,
          borderLeft: `3px solid ${colorUnderline}`,
          color: color_content,
        };
      }
      if (state.isSelected) {
        return {
          ...provided,
          backgroundColor: color_white,
          color: color_content,
        };
      }
      return { ...provided, color: color_content };
    },
    control: () => ({
      display: 'flex',
      border: 'none',
      borderBottom: `1px solid ${isInvalid ? color_red : invertedColors ? color_white : color_title}`,
      backgroundColor: 'transparent',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: invertedColors ? color_white : provided.color,
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: invertedColors ? color_white : provided.color,
    }),
    input: (provided: any) => ({
      ...provided,
      color: invertedColors ? color_white : provided.color,
    }),
    container: (provided: any) => ({ ...provided, width }),
  };

  return (
    <Select
      components={{
        DropdownIndicator: () => <FontAwesomeIcon icon="caret-down" />,
      }}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
      onChange={handleChange}
      options={options}
      placeholder={placeholder}
      styles={omlDropDownStyle}
      isMulti={isMulti}
      value={
        value ||
        (isMulti
          ? [] // TODO
          : fieldValue
          ? options.find((o) => o.value === (fieldValue as ISelectOption).value)
          : undefined)
      }
      {...rest}
    />
  );
};

export default OmlDropdown;
