import { FC, ComponentPropsWithRef } from 'react';
import { Label, Input } from 'reactstrap';
import styled from 'styled-components';
import { colors } from 'styles/variables/colors';

type IProps = ComponentPropsWithRef<'input'> & {
  checkboxLabel: string;
  isInvalid?: boolean;
};

const InputWithHiddenCheckbox = styled(Input)`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${colors.color_white};
  stroke-width: 4px;
  display: flex;
`;

const checkboxHeigth = '12px';
const borderWidth = 2;
type StyledCheckboxProps = { checked?: boolean; isInvalid?: boolean };
const StyledCheckbox = styled.div<StyledCheckboxProps>`
  width: ${checkboxHeigth};
  min-width: ${checkboxHeigth};
  height: ${checkboxHeigth};
  min-height: ${checkboxHeigth};
  background: ${({ isInvalid, checked }: StyledCheckboxProps) =>
    checked ? (isInvalid ? colors.color_red : colors.color_title) : colors.color_white};
  border: ${borderWidth}px solid
    ${({ isInvalid }: StyledCheckboxProps) => (isInvalid ? colors.color_red : colors.color_title)};
  margin-top: ${borderWidth + 2}px;
  transition: all 150ms;
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const OmlCheckbox: FC<IProps> = ({ id, checked, isInvalid, checkboxLabel, ...rest }) => {
  return (
    <Label check={true} className="d-flex">
      <InputWithHiddenCheckbox checked={checked} invalid={isInvalid} {...rest} />
      <StyledCheckbox checked={checked} className="mr-2" isInvalid={isInvalid}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      {checkboxLabel}
    </Label>
  );
};

export default OmlCheckbox;
