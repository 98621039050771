import { ComponentPropsWithoutRef, FC, useRef, useState, useEffect } from 'react';
import { AxiosError } from 'axios';
import FetchApiError from 'features/transversal/components/FetchApiError';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import { SWRResponse } from 'swr';
import { Loader } from './Loader';
import PageTitle from './PageTitle';
import PageContainer from './PageContainer';
import styled from 'styled-components';
import classnames from 'classnames';

export const TabContainer = styled.ul`
  text-transform: uppercase;
  .tab {
    cursor: pointer;
    padding-right: 24px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.51;
    letter-spacing: 0.8px;
    white-space: nowrap;
  }
  .active {
    border-bottom: 5px solid #0c15a0;
  }
`;

export type OmlPageTab = {
  name: string;
  component: JSX.Element;
  tabSwrState: SWRResponse<any, AxiosError>;
  options?: Partial<{ scrollable: boolean }>;
};

const PageContainerWithHeaderAndTabs: FC<
  {
    headerSwrState: SWRResponse<any, AxiosError>;
    pageTitle: string;
    pageTitleComponent?: JSX.Element;

    browserTabTitle?: string;
    headerComponent: JSX.Element;
    tabs: OmlPageTab[];
    onTabChange: (newTab: number) => void;
  } & ComponentPropsWithoutRef<'div'>
> = ({
  headerSwrState: { data, isValidating, error, mutate },
  pageTitle,
  pageTitleComponent,
  browserTabTitle,
  headerComponent,
  children,
  tabs,
  onTabChange,
  ...rest
}) => {
  const [tab, setTab] = useState(0);
  const containerRef = useRef<HTMLUListElement>(null);

  // Effect responsable de la mise à jour de la position du scroll horizontal sur les tabs
  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const currentTab = container.querySelector(`#tab-${tab}`);
    if (!currentTab) {
      return;
    }
    const { offsetLeft } = currentTab as HTMLLIElement;
    container.scroll(offsetLeft, 0);
  }, [tab]);

  function handleTabChange(newTab: number) {
    setTab(newTab);
    onTabChange(newTab);
  }

  return (
    <PageContainer {...rest}>
      {isValidating ? (
        <Loader />
      ) : error ? (
        <FetchApiError onRetry={mutate} />
      ) : data ? (
        <>
          <Row className="d-flex justify-content-between">
            <Col className="mb-3 px-2">
              {pageTitleComponent || <PageTitle browserTabTitle={browserTabTitle || pageTitle}>{pageTitle}</PageTitle>}
            </Col>
          </Row>
          <Row>{headerComponent}</Row>
          <Row className="mt-4">
            <TabContainer
              ref={containerRef}
              className="nav nav-tabs flex-nowrap overflow-x-scroll overflow-y-hidden hide-scrollbar"
            >
              {tabs.map(({ name }, i) => (
                <li
                  key={name}
                  className={classnames('tab', 'nav-item', 'pl-2', 'pb-1', tab === i && 'active')}
                  id={`tab-${i}`}
                  onClick={() => handleTabChange(i)}
                >
                  {name}
                </li>
              ))}
            </TabContainer>
          </Row>
          <Row>
            <TabContent activeTab={tab} className="w-100 px-3 px-lg-0">
              {tabs.map(({ component, name, tabSwrState, options }, i) => {
                const { scrollable = true } = options || {};
                return (
                  <TabPane key={name} className={classnames('w-100', scrollable && 'overflow-x-scroll')} tabId={i}>
                    {tab === i ? (
                      tabSwrState.isValidating ? (
                        <Loader className="w-100 mt-3" />
                      ) : tabSwrState.error ? (
                        <FetchApiError className="w-100 mt-3" onRetry={tabSwrState.mutate} />
                      ) : (
                        component
                      )
                    ) : null}
                  </TabPane>
                );
              })}
            </TabContent>
          </Row>
        </>
      ) : null}
    </PageContainer>
  );
};
export { PageContainerWithHeaderAndTabs };
