import { FC, useMemo } from 'react';
import PlayerPointsFortsModal from '../../players/components/PlayerPointsFortsModal';
import { IPlayer, IPlayerLight } from 'features/players/players.types';
import { useModal } from 'features/layout/layout.utils';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { basicCellFormatter, rowLinkFormatter, photoFormatter } from 'utils/oml-table.utils';

import '../styles/Team.scss';

export interface IProps {
  players: IPlayer[];
  coach?: IPlayerLight;
  assistant?: IPlayerLight;
}

const TeamPlayersTab: FC<IProps> = ({ players, coach, assistant }) => {
  const { show } = useModal();

  const columns: ColumnDescription[] = useMemo(() => {
    function handleClickPointFort(player: IPlayer) {
      show({
        children: <PlayerPointsFortsModal playerId={player.id} playerName={player.nom_prenom} />,
      });
    }
    return [
      {
        dataField: 'id',
        text: '',
        hidden: true,
      },
      {
        dataField: 'image',
        text: '',
        classes: 'p-0',
        formatter: photoFormatter,
        headerStyle: {
          width: '60px',
        },
      },
      {
        dataField: 'maillot',
        text: 'N°',
        formatter: basicCellFormatter,
        headerStyle: {
          width: '60px',
        },
      },
      {
        dataField: 'nom_prenom',
        text: 'Nom',
        formatter: rowLinkFormatter,
      },
      {
        dataField: 'poste',
        text: 'Poste',
        formatter: basicCellFormatter,
      },
      {
        dataField: 'tailleWithSuffix',
        text: 'Taille',
        formatter: basicCellFormatter,
      },
      {
        dataField: 'id',
        text: '',
        formatter: (_: any, player: IPlayer) => (
          <button
            className="points-popup p-1"
            color="primary"
            onClick={() => handleClickPointFort(player)}
            type="button"
          >
            points forts
          </button>
        ),
      },
    ];
  }, [show]);

  return (
    <div className="w-100">
      <header className="tab-title">
        <div>{coach ? `${coach.nom} - Entraineur` : 'Entraineur inconnu'}</div>
        <div>{assistant ? `${assistant.nom} - Assistant` : ''}</div>
      </header>
      <BootstrapTable
        bootstrap4={true}
        bordered={false}
        classes="oml-table"
        columns={columns}
        data={players}
        keyField="id"
      />
    </div>
  );
};

export default TeamPlayersTab;
