export function formatCurrency(num: number | null | undefined) {
  if (num || num === 0) {
    return Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
    }).format(num);
  }
  return 'N/A';
}

export function formatPercent(num: number | null | undefined, precision = 0) {
  if (num || num === 0) {
    return Intl.NumberFormat('fr-FR', {
      style: 'percent',
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    }).format(num);
  }
  return 'N/A';
}

export function formatNumber(num: number | null | undefined, precision = 0) {
  if (num || num === 0) {
    const mult = 10 ** precision;
    return Math.round(num * mult) / mult;
  }
  return 0;
}
