import { FC, useMemo, memo } from 'react';
import { Row, Col, Container } from 'reactstrap';
import Slider, { Settings } from 'react-slick';
import PageTitle from 'features/layout/components/PageTitle';
import More from 'features/transversal/components/More';
import ResultatsSlider from 'features/match/components/ResultatsSlider';
import ActusList from 'features/actus/components/ActusList';
import PageContainer from 'features/layout/components/PageContainer';
import { routeList } from 'App';
import ResultatImg from 'assets/img/slider/resultats.jpg';
import StatImg from 'assets/img/slider/statistiques.jpg';
import EvalImg from 'assets/img/slider/evaluation.jpg';
import { useGetActuList } from 'features/actus/actus.services';
import { useGetJourneeResultats } from 'features/match/match.services';

import './home.scss';
import FetchApiError from 'features/transversal/components/FetchApiError';

const features = [
  {
    title: 'Résultats',
    desc: 'Retrouvez les resultats de tous les matches nationaux, régionaux et départementaux',
    color: '#ee1c25',
    image: ResultatImg,
  },
  {
    title: 'Statistiques',
    desc: "Consultez les statistiques individuelles et d'équipe",
    color: '#03792f',
    image: StatImg,
  },
  {
    title: 'évaluation',
    desc: 'Notation des joueurs par les coachs, arbitres, managers, le public et les joueurs eux-memes',
    color: '#fce152',
    image: EvalImg,
  },
];

const sharedSettings: Settings = {
  arrows: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 1,
};
const SliderComponent: FC = memo(() => {
  const featureSlidesText = useMemo(
    () =>
      features.map((feature) => (
        <div key={feature.title} className="feature-slide">
          <div className="h-100">
            <Col className="slide-text">
              <h4>{feature.title}</h4>
              <div className="list_description">
                <p>{feature.desc}</p>
              </div>
            </Col>
          </div>
        </div>
      )),
    []
  );

  const featureSlidesBackgroud = useMemo(
    () =>
      features.map((feature) => (
        <div key={feature.title} className="feature-slide">
          <div className="h-100" style={{ backgroundColor: feature.color }}>
            <Col className="h-100 d-flex flex-column justify-content-center" xs={8} />
          </div>
        </div>
      )),
    []
  );

  const featureSlidesImages = useMemo(
    () => features.map((feature) => <img key={feature.title} alt="" className="my-auto" src={feature.image} />),
    []
  );

  let imageSlider: null | Slider = null;
  let backgroundSlider: null | Slider = null;
  return (
    <div className="home-slider">
      <div className="align-items-center row-without-container">
        <div className="background-slider">
          <Slider
            {...sharedSettings}
            ref={(slider) => {
              backgroundSlider = slider;
            }}
            dots={false}
          >
            {featureSlidesBackgroud}
          </Slider>
        </div>
        <div className="image-slider">
          <Slider
            {...sharedSettings}
            ref={(slider) => {
              imageSlider = slider;
            }}
            dots={false}
          >
            {featureSlidesImages}
          </Slider>
        </div>
      </div>

      <Container className="text-slider">
        <Row className="h-100">
          <Col className="h-100" md={6} xs={12}>
            <Slider
              {...sharedSettings}
              appendDots={(dots) => <div id="home-slider-dots">{dots}</div>}
              autoplay={true}
              autoplaySpeed={5000}
              beforeChange={(_oldIndex, newIndex) => {
                if (imageSlider && backgroundSlider) {
                  imageSlider.slickGoTo(newIndex);
                  backgroundSlider.slickGoTo(newIndex);
                }
              }}
              customPaging={(i: number) => <div>{features[i].title}</div>}
              dots={true}
              fade={true}
            >
              {featureSlidesText}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  );
});

const Home = () => {
  const { actus, isValidating: isValidatingActu, error: errorActus, mutate: mutateActus } = useGetActuList({});
  const {
    resultats,
    isValidating: isValidatingResultats,
    error: errorResultats,
    mutate: mutateResultats,
  } = useGetJourneeResultats({});

  const lastActus = useMemo(() => actus.slice(0, 5), [actus]);
  return (
    <>
      <SliderComponent />
      <PageContainer>
        <PageTitle classNames="mx-auto mb-5">Actus</PageTitle>
        <Container fluid>
          <ActusList actus={lastActus} error={errorActus} isLoading={isValidatingActu} mutate={mutateActus} />
        </Container>
        <Row className="my-3">
          <Col className="d-flex justify-content-end">
            <More texte="Plus d'actus" to={routeList.actus} />
          </Col>
        </Row>
        <PageTitle classNames="mx-auto mb-5">Derniers résultats</PageTitle>
        <Row className="mt-5">
          <Col>
            {isValidatingResultats ? (
              <ResultatsSlider />
            ) : errorResultats ? (
              <FetchApiError onRetry={mutateResultats} />
            ) : resultats.length === 0 ? (
              <Col className="text-center mb-5">Aucun résultat</Col>
            ) : (
              <ResultatsSlider resultats={resultats} />
            )}
          </Col>
        </Row>
      </PageContainer>
    </>
  );
};

export default Home;
