import styled, { css } from 'styled-components';
import { colors } from 'styles/variables/colors';
import { fonts } from 'styles/variables/fonts';

const underlineHeight = '0.5em';
const underlineOffset = '6px';

const omlUnderlineClass = css`
  text-decoration: none;
  position: relative;
  display: block;
  line-height: 1em;
  color: ${colors.color_title};
  font-weight: bold;
  width: fit-content;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: -0.1em;
    left: -${underlineOffset};
    height: ${underlineHeight};
    width: calc(100% + 2 * ${underlineOffset});
    background-color: ${colors.colorUnderline};
    transform: scale(1, 1);
    z-index: -1;
  }
`;

const animUnderlineClass = css<{ fat: boolean }>`
  ${(props) => props.fat && `text-transform: uppercase;`}
  line-height: 1em;
  text-decoration: none;
  position: relative;
  display: block;
  transition: color 0.1s, background-color 0.1s, padding 0.2s ease-in;
  cursor: pointer;
  width: fit-content;
  &::before {
    content: '';
    z-index: -1;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background-color: ${colors.color_title};
    transform-origin: right top;
    transform: scale(0, 1);
    transition: color 0.1s, transform 0.2s ease-out;
    ${(props) =>
      props.fat &&
      `
      left: -${underlineOffset};
      width: calc(100% + 2 * ${underlineOffset});
      height: ${underlineHeight};
      bottom: 0;
      background-color: ${colors.colorUnderline};
    `}
  }
  &:hover::before,
  &:focus::before {
    transform-origin: left top;
    transform: scale(1, 1);
  }
  &:hover,
  a:focus {
    color: ${colors.color_title};
  }
`;

const underlineOrAnim = css<{ isAnim: boolean; fat: boolean }>`
  ${(props) => (props.isAnim ? animUnderlineClass : omlUnderlineClass)}
`;

const OmlUnderlineSpan = styled.span<{ isAnim: boolean; fat: boolean }>`
  ${underlineOrAnim}
`;
const OmlUnderlineH1 = styled.h1<{ isAnim: boolean; fat: boolean }>`
  ${underlineOrAnim}
  font-size: ${fonts.font_size_title};
  font-family: ${fonts.font_underline_title};
  letter-spacing: 1.2px;
`;
const OmlUnderlineH2 = styled.h2<{ isAnim: boolean; fat: boolean }>`
  ${underlineOrAnim}
`;
const OmlUnderlineH3 = styled.h3<{ isAnim: boolean; fat: boolean }>`
  ${underlineOrAnim}
`;

const OmlUnderline = (props: {
  titleLevel?: 1 | 2 | 3;
  className?: string;
  children: React.ReactNode;
  selected?: boolean;
}) => {
  const { titleLevel, className, children, selected } = props;
  const isAnim = selected === false;
  // if (!titleLevel)
  //   return (
  //     <OmlUnderlineSpan isAnim={isAnim} fat={isAnim} className={className}>
  //       {children}
  //     </OmlUnderlineSpan>
  //   );
  switch (titleLevel) {
    case 1:
      return (
        <OmlUnderlineH1 className={className} fat={isAnim} isAnim={isAnim}>
          {children}
        </OmlUnderlineH1>
      );
    case 2:
      return (
        <OmlUnderlineH2 className={className} fat={isAnim} isAnim={isAnim}>
          {children}
        </OmlUnderlineH2>
      );
    case 3:
      return (
        <OmlUnderlineH3 className={className} fat={isAnim} isAnim={isAnim}>
          {children}
        </OmlUnderlineH3>
      );
    default:
      return (
        <OmlUnderlineSpan className={className} fat={isAnim} isAnim={isAnim}>
          {children}
        </OmlUnderlineSpan>
      );
  }
};

export default OmlUnderline;
