/* eslint-disable no-param-reassign */
import { ComponentPropsWithRef } from 'react';
import ReactContentLoader from 'react-content-loader';

export interface IDefaultProps {
  type: ContentLoaderTypes;
  height: number;
  width: number;
  lineNumber: number;
  linePadding: number;
}

export type IProps = Partial<IDefaultProps>;

export enum ContentLoaderTypes {
  lines,
  image,
}

// http://danilowoz.com/create-content-loader/
const ContentLoader = ({
  type = ContentLoaderTypes.lines,
  height = 100,
  width = 50,
  lineNumber = 2,
  linePadding = 10,
  ...rest
}: IProps & Omit<ComponentPropsWithRef<'svg'>, 'type' | 'speed'>) => {
  width = Math.ceil(width);
  height = Math.ceil(height);
  lineNumber = Math.ceil(lineNumber);

  const image = <rect height={height} rx="5" ry="5" width={width} x="0" y="0" />;

  const xOffset = linePadding < width / 3 ? linePadding : Math.floor(width / 3);
  const lineWidth = width - 2 * xOffset;

  const lineHeight = 5;
  const yOffset =
    height > 2 * linePadding + lineHeight * lineNumber
      ? linePadding
      : Math.floor(height / (lineHeight * (lineNumber + 1)) / 2);

  const lines: JSX.Element[] = [];
  for (let i = 0; i < lineNumber; i += 1) {
    lines.push(
      <rect
        key={i}
        height={lineHeight}
        rx="4"
        ry="4"
        width={i % 2 === 0 ? lineWidth : lineWidth * 0.8}
        x={xOffset}
        y={yOffset * (i + 1) + lineHeight * i}
      />
    );
  }

  return (
    <ReactContentLoader
      backgroundColor="#ecebeb"
      foregroundColor="#f3f3f3"
      height={height}
      speed={2}
      width={width}
      {...rest}
    >
      {(() => {
        switch (type) {
          case ContentLoaderTypes.image:
            return <>{image}</>;
          case ContentLoaderTypes.lines:
          default:
            return <>{lines}</>;
        }
      })()}
    </ReactContentLoader>
  );
};

export default ContentLoader;
