import { apiUrl } from 'features/api/apiCalls.enum';
import { axiosPost } from 'features/api/apiCalls.utils';

export type LikeType = 'actu' | 'comment' | 'publication';

function transcodeLikeType(type: LikeType) {
  return type === 'actu' ? 'N' : type === 'comment' ? 'C' : undefined;
}

export function postLike({
  type,
  unlike = false,
  resource_id,
  ...restBody
}: {
  user_id: number;
  resource_id: number;
  type: LikeType;
  unlike?: boolean;
}) {
  if (type === 'publication') {
    return axiosPost<never>(unlike ? apiUrl.userFeedUnlike : apiUrl.userFeedLike, {
      action_id: resource_id,
    });
  }
  return axiosPost<never>(unlike ? apiUrl.unlike : apiUrl.like, {
    resource_type: transcodeLikeType(type),
    resource_id,
    ...restBody,
  });
}
