import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { authLocalStorage } from 'features/auth/auth.localStorage';
import { oauthTokens } from './apiCalls.enum';
import useSWR, { SWRResponse, SWRConfiguration } from 'swr';
import useSWRInfinite, { SWRInfiniteResponse, SWRInfiniteConfiguration } from 'swr/infinite';
import { useCallback, useMemo } from 'react';
import { PaginationParams } from './apiCalls.types';

export const successCodes = [200, 204];

export type OmlAxiosConfig = Partial<AxiosRequestConfig & { withTokens: boolean }>;

export function generateAxiosRequestConfig(config?: OmlAxiosConfig): AxiosRequestConfig {
  const { params: userParams, headers: userHeaders, withTokens = true, ...restUserConfig } = config || {};
  const params: AxiosRequestConfig['params'] = { language: 'fr' };
  const headers: AxiosRequestConfig['headers'] = {};

  const oauthConsumerKey: string | undefined = process.env.REACT_APP_API_CONSUMER_KEY;
  const oauthConsumerSecret: string | undefined = process.env.REACT_APP_API_CONSUMER_SECRET;
  const oauthToken = authLocalStorage.getToken(oauthTokens.token);
  const oauthSecret = authLocalStorage.getToken(oauthTokens.secret);

  // VERSION 1 : WITH TOKEN IN HEADERS => doesn't work until the API will have corrected a CORS issue
  // if (oauthConsumerKey) {
  //   headers[oauthTokens.consumerKey] = oauthConsumerKey;
  // }
  // if (oauthConsumerSecret) {
  //   headers[oauthTokens.consumerSecret] = oauthConsumerSecret;
  // }
  // if (oauthToken) {
  //   headers[oauthTokens.token] = oauthToken;
  // }
  // if (oauthSecret) {
  //   headers[oauthTokens.secret] = oauthSecret;
  // }

  // VERSION 2 : WITH TOKENS IN PARAMS
  if (oauthConsumerKey) {
    params[oauthTokens.consumerKey] = oauthConsumerKey;
  }
  // Soome routes require to be authenticated and some other require not to be authenticated and crash if we send auth data (signup for instance) hence this behaviour
  if (withTokens) {
    if (oauthConsumerSecret) {
      params[oauthTokens.consumerSecret] = oauthConsumerSecret;
    }
    if (oauthToken) {
      params[oauthTokens.token] = oauthToken;
    }
    if (oauthSecret) {
      params[oauthTokens.secret] = oauthSecret;
    }
  } else {
    if (oauthConsumerSecret) {
      params[oauthTokens.consumerSecret] = undefined;
    }
    if (oauthToken) {
      params[oauthTokens.token] = undefined;
    }
    if (oauthSecret) {
      params[oauthTokens.secret] = undefined;
    }
  }

  return {
    baseURL: process.env.REACT_APP_API_DOMAIN,
    timeout: 10 * 1000,
    responseType: 'json',
    params: { ...params, ...userParams },
    headers: { ...headers, ...userHeaders },
    ...restUserConfig,
  };
}

export const axiosInstance = axios.create(generateAxiosRequestConfig());
axiosInstance.interceptors.response.use(
  (res) => {
    if (!successCodes.includes(Number(res.data?.status_code)) || res.data?.error) {
      const {
        status_code,
        error_code,
        message,
      }: { message: any; error: boolean; error_code: string; status_code: number } = res.data;
      const error: AxiosError = {
        name: error_code,
        message,
        config: {},
        isAxiosError: true,
        code: status_code.toString(),
        toJSON: () => ({}),
      };
      return Promise.reject(error);
    }
    if (res.data.body) {
      return Promise.resolve({ ...res, data: res.data.body });
    }
    return Promise.resolve(res);
  },
  async (error: AxiosError) => {
    // TODO: gérer les 401 (vérifier si c'est bien des 401)
    // const originalRequest = error.config;
    // const isSessionsRequest = /\/user\/sessions\//.test(originalRequest.url);

    // if (error.response && error.response.status === 401 && !isSessionsRequest && !originalRequest.retry) {
    //   originalRequest.retry = true;

    //   try {
    //     await refreshSession();
    //     return axiosInstance(originalRequest);
    //   } catch (e) {
    //     console.error(e);
    //     throw error;
    //   }
    // }
    return Promise.reject(error);
  }
);

export function useApiGet<Data>(
  key: string | null,
  params?: Record<string, any>,
  config?: SWRConfiguration<Data, AxiosError>
): SWRResponse<Data, AxiosError> {
  const fetcher = useCallback((url, p) => {
    return axiosInstance.get<Data>(url, { params: p ? JSON.parse(p) : undefined }).then(({ data }) => data);
  }, []);
  const results = useSWR(key ? (params ? [key, JSON.stringify(params)] : key) : null, fetcher, {
    revalidateOnFocus: false,
    ...config,
  });
  return results;
}

export function useApiGetWithPagination<Data extends { elements: any[]; total: number }>(
  key: string | null,
  params: PaginationParams & Record<string, any>,
  config?: SWRInfiniteConfiguration<Data, AxiosError>
): SWRInfiniteResponse<Data, AxiosError> & { isLastPageReached: boolean } {
  const getKey = useCallback(
    (pageIndex, previousPageData) => {
      const { maxItem = 10 } = params;
      if (!key) {
        return null;
      }
      if (previousPageData && !previousPageData.elements.length) {
        return null;
      }
      return [key, JSON.stringify({ ...params, offset: pageIndex * maxItem, maxItem })];
    },
    [key, params]
  );
  const fetcher = useCallback((url, p) => {
    return axiosInstance.get<Data>(url, { params: p ? JSON.parse(p) : undefined }).then(({ data }) => data);
  }, []);
  const results = useSWRInfinite(getKey, fetcher, {
    revalidateOnFocus: false,
    ...config,
  });
  const isLastPageReached = useMemo(() => {
    if (!results.data) {
      return false;
    }
    if (results.data && results.data.length === 0) {
      return false;
    }
    const lastItem = results.data[results.data.length - 1];
    const { total } = lastItem;
    const nbItemsFetched = results.data.reduce((acc, d) => acc + d.elements.length, 0);
    return nbItemsFetched >= total;
  }, [results]);

  return { ...results, isLastPageReached };
}

export function axiosPost<T>(url: string, body: Record<string, any>, config?: OmlAxiosConfig) {
  const data = new FormData();
  for (const formFieldKey of Object.keys(body)) {
    const value = body[formFieldKey];
    if (typeof value !== undefined) {
      data.append(formFieldKey, value);
    }
  }

  const baseConfig = generateAxiosRequestConfig(config);
  if (!baseConfig.headers) {
    baseConfig.headers = {};
  }
  baseConfig.headers['Content-Type'] = config?.headers?.['Content-Type'] || 'multipart/form-data';
  return axiosInstance.post<T>(url, data, baseConfig);
}

export function axiosDelete<T>(url: string, body: Record<string, any>) {
  return axiosInstance.delete<T>(url, body);
}
