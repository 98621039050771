/* eslint-disable no-param-reassign */
import { FC, useMemo, useRef, useEffect } from 'react';
import { IPlayerStatistics, IPlayerStatisticsDetail } from 'features/players/players.types';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { basicCellFormatter, doubleHeaderFormatter, tooltipHeaderFormatter } from 'utils/oml-table.utils';
import { CSSProperties } from 'styled-components';
import { colors } from 'styles/variables/colors';
import { Button } from 'reactstrap';
import { useModal } from 'features/layout/layout.utils';
import { UpdateMemberStatsModal } from './UpdateMemberStatsModal';

import '../styles/index.scss';

export interface IProps {
  playerStats: IPlayerStatistics;
  mutateStats: () => void;
}

function generateDataCol({
  dataCellWidthMultiplicator = 1,
  classes = (_, row) => (row.hasPlayed || row.type !== 'game' ? 'text-center align-middle no-wrap' : 'd-none'),
}: {
  dataCellWidthMultiplicator?: number;
  classes?: ColumnDescription['classes'];
}): Partial<ColumnDescription> {
  return {
    formatter: basicCellFormatter,
    classes,
    headerStyle: {
      width: `${dataCellWidthMultiplicator * 40}px`,
      verticalAlign: 'middle',
      textAlign: 'center',
    },
  };
}

const MemberStatsTab: FC<IProps> = ({ playerStats: { details, updateMode }, mutateStats }) => {
  const columns: ColumnDescription[] = useMemo(() => {
    return [
      {
        dataField: 'id',
        text: '',
        hidden: true,
      },
      {
        dataField: 'firstCol',
        text: 'Journée',
        ...generateDataCol({ dataCellWidthMultiplicator: 2, classes: `align-middle text-capitalize` }),
        attrs: (_, row) => (row.type !== 'game' ? { colSpan: `4` } : undefined),
      },
      {
        dataField: 'game.opponent',
        text: 'Adversaire',
        ...generateDataCol({
          dataCellWidthMultiplicator: 3,
          classes: (_, row) => `text-center align-middle ${row.type !== 'game' ? 'd-none' : ''}`,
        }),
      },
      {
        dataField: 'game.score',
        text: 'Score',
        ...generateDataCol({
          dataCellWidthMultiplicator: 2,
          classes: (_, row) => `text-center align-middle no-wrap ${row.type !== 'game' ? 'd-none' : ''}`,
        }),
      },
      {
        dataField: 'starter',
        text: '5D',
        headerFormatter: tooltipHeaderFormatter('5 de départ'),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
          classes: (_, row) => `align-middle no-wrap ${row.type !== 'game' ? 'd-none' : ''}`,
        }),
        attrs: (_, row) => (!row.hasPlayed ? { colSpan: `18` } : undefined),
        formatter: (cell: boolean | undefined, row: IPlayerStatisticsDetail) =>
          basicCellFormatter(
            !row.hasPlayed ? "N'a pas joué ce match" : cell === true ? 'Oui' : cell === false ? 'Non' : undefined
          ),
      },
      {
        dataField: 'minutes',
        text: 'Mins',
        headerFormatter: tooltipHeaderFormatter('Minutes jouées'),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'totalPoints',
        text: 'Pts',
        headerFormatter: tooltipHeaderFormatter('Total des points marqués'),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'twoPoints.success',
        text: 'R',
        headerFormatter: (col) =>
          doubleHeaderFormatter(<div>2 Pts</div>, tooltipHeaderFormatter('2 points réussis')(col)),
        ...generateDataCol({
          dataCellWidthMultiplicator: 3,
        }),
      },
      {
        dataField: 'twoPoints.attempt',
        text: 'T',
        headerFormatter: (col) =>
          doubleHeaderFormatter(<div>2 Pts</div>, tooltipHeaderFormatter('2 points tentés')(col)),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'twoPoints.rate',
        text: '%',
        headerFormatter: (col) =>
          doubleHeaderFormatter(<div>2 Pts</div>, tooltipHeaderFormatter('Pourcentage de réussite à 2 points')(col)),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'threePoints.success',
        text: 'R',
        headerFormatter: (col) =>
          doubleHeaderFormatter(<div>3 Pts</div>, tooltipHeaderFormatter('3 points réussis')(col)),
        ...generateDataCol({
          dataCellWidthMultiplicator: 3,
        }),
      },
      {
        dataField: 'threePoints.attempt',
        text: 'T',
        headerFormatter: (col) =>
          doubleHeaderFormatter(<div>3 Pts</div>, tooltipHeaderFormatter('3 points tentés')(col)),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'threePoints.rate',
        text: '%',
        headerFormatter: (col) =>
          doubleHeaderFormatter(<div>3 Pts</div>, tooltipHeaderFormatter('Pourcentage de réussite à 3 points')(col)),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'freeShots.success',
        text: 'R',
        headerFormatter: (col) =>
          doubleHeaderFormatter(<div>LF</div>, tooltipHeaderFormatter('Lancers francs réussis')(col)),
        ...generateDataCol({
          dataCellWidthMultiplicator: 3,
        }),
      },
      {
        dataField: 'freeShots.attempt',
        text: 'T',
        headerFormatter: (col) =>
          doubleHeaderFormatter(<div>LF</div>, tooltipHeaderFormatter('Lancer francs tentés')(col)),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'freeShots.rate',
        text: '%',
        headerFormatter: (col) =>
          doubleHeaderFormatter(<div>LF</div>, tooltipHeaderFormatter('Pourcentage de réussite au lancer francs')(col)),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'rebounds',
        text: 'Rebonds',
        ...generateDataCol({
          dataCellWidthMultiplicator: 2,
        }),
      },
      {
        dataField: 'counters',
        text: 'Contres',
        ...generateDataCol({
          dataCellWidthMultiplicator: 2,
        }),
      },
      {
        dataField: 'decisivePasses',
        text: 'PD',
        headerFormatter: tooltipHeaderFormatter('Passes décisives'),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'stls',
        text: 'INT',
        headerFormatter: tooltipHeaderFormatter('Interceptions'),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'lb',
        text: 'BP',
        headerFormatter: tooltipHeaderFormatter('Balles perdues'),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
      {
        dataField: 'fouls',
        text: 'Ftes',
        headerFormatter: tooltipHeaderFormatter('Fautes'),
        ...generateDataCol({
          dataCellWidthMultiplicator: 1,
        }),
      },
    ];
  }, []);

  const tableRef = useRef<BootstrapTable>(null);

  // Effect to create the second header row on table mount
  useEffect(() => {
    function findPreviousNonHiddenCell(nodeArray: HTMLTableHeaderCellElement[], startIndex: number) {
      if (startIndex <= 0) {
        return undefined;
      }

      const previous = nodeArray[startIndex - 1];
      if (previous.getAttribute('class')?.split(' ').includes('d-none')) {
        return findPreviousNonHiddenCell(nodeArray, startIndex - 1);
      }
      return previous;
    }

    const statTables = document.getElementsByClassName('member-stat-table');
    if (statTables.length) {
      const statTable = statTables[0] as HTMLTableElement;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const statTableHeader = statTable.getElementsByTagName('thead')![0];
      const initialTrs = statTableHeader.getElementsByTagName('tr');
      if (initialTrs.length !== 1) {
        return;
      }
      const initialTr = initialTrs[0];
      const initialTrNodes = Array.from(initialTr.childNodes) as HTMLTableHeaderCellElement[];
      const secondTr: ChildNode[] = [];

      initialTrNodes.forEach((initialTh, index) => {
        const headerChildren = Array.from(initialTh.childNodes);
        if (headerChildren.length === 2) {
          const elementToAddInSecondRow = headerChildren[1];
          const elementInFirstRow = headerChildren[0] as HTMLDivElement;
          secondTr.push(elementToAddInSecondRow);
          initialTh.setAttribute('class', `${initialTh.getAttribute('class')} no-bottom-padding`);

          const previousCol = findPreviousNonHiddenCell(initialTrNodes, index);
          if (previousCol && previousCol.firstChild.innerText === elementInFirstRow.innerText) {
            const previousColSpan = Number(previousCol.getAttribute('colspan')) || 1;
            previousCol.setAttribute('colspan', `${previousColSpan + 1}`);
            initialTr.removeChild(initialTh);
            initialTh.setAttribute('class', 'd-none');
          }
        } else {
          initialTh.setAttribute('rowspan', '2');
        }
      });

      if (!secondTr.length) {
        return;
      }
      const tr = document.createElement('tr');
      secondTr.forEach((el) => {
        const th = document.createElement('th');
        th.setAttribute('class', 'text-center align-middle no-top-padding no-x-padding');
        th.appendChild(el);
        tr.appendChild(th);
      });
      statTableHeader.appendChild(tr);
    }
  }, [tableRef]);

  const rowStyle = (row: IPlayerStatistics['details'][number]) => {
    const style: CSSProperties = {};
    if (row.type !== 'game') {
      style.backgroundColor = colors.color_border_default;
    }

    return style;
  };

  const detailsWithCustomFirstCol = useMemo(
    () =>
      details.map((d) => ({
        ...d,
        firstCol: d.type === 'game' ? d.game?.sched_num : d.type === 'average' ? 'Moyenne' : d.type,
      })),
    [details]
  );

  const { show } = useModal();
  function showUpdateStatsModal() {
    show({ children: <UpdateMemberStatsModal details={details} mutateStats={mutateStats} /> });
  }

  return (
    <div className="w-100">
      {updateMode && (
        <Button className="my-3" color="primary" onClick={showUpdateStatsModal}>
          Mettre à jour mes stats
        </Button>
      )}
      <BootstrapTable
        ref={tableRef}
        bootstrap4={true}
        bordered={false}
        classes="oml-table member-stat-table"
        columns={columns}
        data={detailsWithCustomFirstCol}
        keyField="id"
        rowStyle={rowStyle}
      />
    </div>
  );
};

export { MemberStatsTab };
