import { FC, useMemo } from 'react';
import PlayersListModal from './PlayersListModal';
import { IEquipe } from '../equipe.types';
import { routeList } from 'App';
import { useParams } from 'react-router-dom';
import { useModal } from 'features/layout/layout.utils';
import { IPlayerLight } from 'features/players/players.types';
import { PageHeader } from 'features/layout/components/PageHeader';

import '../styles/Team.scss';
import AddToFavorite from 'features/transversal/components/AddToFavorite';

export interface IProps {
  team: IEquipe;
  popupListPlayers: IPlayerLight[];
}

const TeamPageHeader: FC<IProps> = ({
  team: { category, gender, clubId, clubImage, shortName, nom, followers, image },
  popupListPlayers,
}: IProps) => {
  const { id } = useParams<{ id: string }>();
  const teamId = useMemo(() => Number(id), [id]);

  const { show } = useModal();

  function handleShowModal() {
    show({
      children: (
        <PlayersListModal popupListPlayers={popupListPlayers} teamId={teamId} teamImage={image} teamName={nom} />
      ),
    });
  }

  return (
    <PageHeader
      bottomRightElement={
        <button className="i-am-player-button px-2 py-1" onClick={handleShowModal} type="button">
          JE SUIS JOUEUR
        </button>
      }
      className="team-page-header"
      heading1={shortName}
      logo={clubImage}
      logoUrl={`${routeList.club}/${clubId}`}
      statistics={[{ count: followers, singular: 'Follower', plural: 'Followers' }]}
      subHeading={
        <>
          {category} {gender}
        </>
      }
      topRightElement={<AddToFavorite id={teamId} type="equipe" />}
    />
  );
};

export default TeamPageHeader;
