import { ComponentPropsWithoutRef, FC } from 'react';
import { Row, Col, Media } from 'reactstrap';
import ActuCard from 'features/actus/components/ActuCard';
import { IActu } from '../actus.types';
import { AxiosError } from 'axios';
import FetchApiError from 'features/transversal/components/FetchApiError';

interface IProps {
  actus: IActu[];
  isLoading?: boolean;
  error?: AxiosError<any>;
  mutate?: () => void;
  currentPage?: number;
  unfoldFirstElement?: boolean;
}

const ActuLoader: FC = () => (
  <>
    <Col className="mb-3" md={6} xs={12}>
      <ActuCard />
    </Col>
    <Col className="mb-3" md={6} xs={12}>
      <ActuCard />
    </Col>
  </>
);

const ActusList: FC<IProps & ComponentPropsWithoutRef<'div'>> = ({
  actus,
  isLoading,
  error,
  mutate,
  unfoldFirstElement,
  currentPage = 1,
  className,
  ...rest
}) => {
  const isFirstLoading = isLoading && currentPage === 1;
  const isNextLoading = isLoading && currentPage > 1;

  return (
    <Row className={className} {...rest}>
      {isFirstLoading ? (
        <ActuLoader />
      ) : error ? (
        <FetchApiError className="mx-auto" onRetry={mutate} />
      ) : actus.length === 0 ? (
        <Col>Aucune actu pour l'instant</Col>
      ) : (
        <Media className="col-12" list={true}>
          {actus.map((actu, i) => (
            <ActuCard key={actu.id} actu={actu} defaultDetailOpened={i === 0 && unfoldFirstElement} />
          ))}
          {isNextLoading && <ActuLoader />}
        </Media>
      )}
    </Row>
  );
};

export default ActusList;
