import { IEquipeLight } from 'features/equipe/equipe.types';
import { genreList } from 'features/transversal/transversal.enum';
import { FC, SyntheticEvent } from 'react';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';
import { basicCellFormatter, rowLinkFormatter } from 'utils/oml-table.utils';
import { IClub } from '../club.types';

function getGenreListFemininePlural(gender: genreList) {
  switch (gender) {
    case genreList.masculin: {
      return 'masculines';
    }
    case genreList.feminin: {
      return 'feminines';
    }
    case genreList.mixte: {
      return 'mixtes';
    }
    case genreList.all: {
      return '';
    }
    default: {
      const switchTest: never = gender;
      return switchTest;
    }
  }
}

const columns: ColumnDescription[] = [
  {
    dataField: 'id',
    text: '',
    hidden: true,
  },
  {
    dataField: 'championnatName',
    text: 'Championnat',
    formatter: rowLinkFormatter,
  },
  {
    dataField: 'category',
    text: 'catégorie',
    formatter: basicCellFormatter,
  },
  {
    dataField: 'rank',
    text: 'classement',
    formatter: basicCellFormatter,
  },
];

const ClubTeamTable: FC<{ equipes: IEquipeLight[]; gender: genreList }> = ({ equipes, gender }) => {
  const navigate = useNavigate();
  function handleRowClick(_: SyntheticEvent, { link }: IEquipeLight) {
    navigate(link);
  }
  return (
    <>
      <header className="tab-title">
        <div>{`Equipes ${getGenreListFemininePlural(gender)}`}</div>
      </header>
      <BootstrapTable
        bootstrap4={true}
        bordered={false}
        classes="oml-table"
        columns={columns}
        data={equipes}
        keyField="id"
        rowClasses="pointer"
        rowEvents={{ onClick: handleRowClick }}
      />
    </>
  );
};

const ClubTeamTab: FC<{ equipes?: IClub['equipes'] }> = ({ equipes }) => {
  if (!equipes) {
    return null;
  }
  const { masculines, feminines, mixtes } = equipes;
  return (
    <div className="w-100">
      {masculines.length ? <ClubTeamTable equipes={masculines} gender={genreList.masculin} /> : null}
      {feminines.length ? <ClubTeamTable equipes={feminines} gender={genreList.feminin} /> : null}
      {mixtes.length ? <ClubTeamTable equipes={mixtes} gender={genreList.mixte} /> : null}
    </div>
  );
};

export default ClubTeamTab;
