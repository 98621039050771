import { routeList } from 'App';
import { apiUrl } from 'features/api/apiCalls.enum';
import {
  IApiMatch,
  IApiMatchInPlanning,
  IMatch,
  IApiMatchPerf,
  IApiMatchScoreBox,
  IApiMatchHeader,
  IMatchPerf,
  IApiResultat,
  IResultat,
} from './match.types';
import { axiosPost, useApiGet } from 'features/api/apiCalls.utils';
import { formatEquipeLight } from 'features/equipe/equipe.services';
import { IPlayer, IApiPlayer } from 'features/players/players.types';
import { omlDayjs } from 'utils/date';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { formatChampionnatLight } from 'features/championnat/championnat.services';
import { formatPlayer } from 'features/players/players.services';
import { RateSubmitionFormState } from './components/RateSubmitionModal';

export function formatMatchForPlanning({
  A_team_scr,
  H_team_scr,
  away_team,
  away_team_id,
  date: apiDate,
  time,
  home_team,
  home_team_id,
  link,
}: IApiMatchInPlanning): IMatch {
  const linkAsArray = link.split('/');
  const id = Number(linkAsArray[linkAsArray.length - 1]);

  const equipeDomicile = formatEquipeLight({
    Profil_id: home_team_id,
    nom: home_team,
  });
  const equipeExterieur = formatEquipeLight({
    Profil_id: away_team_id,
    nom: away_team,
  });

  const date = omlDayjs(`${apiDate} ${time}`, 'DD-MM-YYYY HH:mm');

  return {
    id,
    nom: `${equipeDomicile.nom} - ${equipeExterieur.nom}`,
    link: `${routeList.match}/${id}`,
    equipeDomicile,
    equipeExterieur,
    score:
      typeof H_team_scr === 'number' && typeof A_team_scr === 'number'
        ? {
            domicile: H_team_scr,
            exterieur: A_team_scr,
          }
        : undefined,
    date,
    jour: date.format('DD/MM/YYYY'),
    heure: date.format('HH:mm'),
    rate: {},
    players: { domicile: [], exterieur: [] },
    coachNames: {},
    performances: [],
  };
}

function formatMatchPerf({ perf_id, perf_desc, perf_label, my_vote, users_votes }: IApiMatchPerf): IMatchPerf {
  function parseVote(vote?: string) {
    if (vote) {
      const voteAsArray = vote.split(':');
      if (voteAsArray.length === 2) {
        // eslint-disable-next-line prefer-destructuring
        const player = voteAsArray[1];
        return player;
      }
    }
    return undefined;
  }

  const myVote = parseVote(my_vote);

  const usersVotesAsArray = (users_votes || '').split(',');
  const totalVotes = usersVotesAsArray.map(parseVote).reduce((acc, vote) => {
    if (vote) {
      const currentCount = acc.get(vote) || 0;
      acc.set(vote, currentCount + 1);
    }
    return acc;
  }, new Map<string, number>());
  // Ajout de l'éventuel vote de l'utilisateur au total
  if (myVote) {
    const currentCount = totalVotes.get(myVote) || 0;
    totalVotes.set(myVote, currentCount + 1);
  }

  return { id: perf_id, desc: perf_desc, label: perf_label, votes: { user: myVote, total: totalVotes } };
}
function formatMatchDetail(game_id: number, { header, scoreBox, players, perfList }: IApiMatchDetail): IMatch {
  const {
    Home_Team_id,
    h_team_name,
    h_team_logo,
    Away_Team_id,
    a_team_name,
    a_team_logo,
    A_team_scr,
    H_team_scr,
    a_team_coach,
    h_team_coach,
  } = scoreBox[0];
  const { gamedate, Lvl_name, Gender, Cat_cod, Groupe, Sched_num, Champ_id, user_rate, game_rate } = header[0];
  const equipeDomicile = formatEquipeLight({
    Profil_id: Home_Team_id,
    nom: h_team_name,
    logoUrl: h_team_logo,
  });
  const equipeExterieur = formatEquipeLight({
    Profil_id: Away_Team_id,
    nom: a_team_name,
    logoUrl: a_team_logo,
  });

  const date = omlDayjs(gamedate, 'DD-MM-YYYY');

  const championnat = formatChampionnatLight({
    nom: Lvl_name,
    poule: Groupe,
    categorie: Cat_cod,
    genre: Gender,
    type: 'withId',
    id: Champ_id,
  });

  return {
    id: game_id,
    nom: `${equipeDomicile.nom} - ${equipeExterieur.nom}`,
    link: `${routeList.match}/${game_id}`,
    equipeDomicile,
    equipeExterieur,
    score:
      typeof H_team_scr === 'number' && typeof A_team_scr === 'number'
        ? {
            domicile: H_team_scr,
            exterieur: A_team_scr,
          }
        : undefined,
    date,
    jour: date.format('DD/MM/YYYY'),
    heure: date.format('HH:mm'),
    championnat,
    journee: Sched_num,
    rate: {
      user: typeof user_rate === 'number' ? user_rate : undefined,
      average: typeof game_rate === 'number' ? game_rate : undefined,
    },
    players: {
      domicile: players?.Home_Team ? players.Home_Team.map(formatPlayer) : [],
      exterieur: players?.Away_Team ? players.Away_Team.map(formatPlayer) : [],
    },
    coachNames: { domicile: h_team_coach, exterieur: a_team_coach },
    performances: perfList.map(formatMatchPerf),
  };
}

export function formatPlayerFromGame(game: IApiMatch): IPlayer {
  const { nom, prenom, photo, Player_id, Profil_id, numero, poste } = game;

  const equipe = formatEquipeLight({
    Profil_id: Player_id,
    nom,
    logoUrl: photo,
  });

  return {
    id: Player_id,
    nom,
    link: Profil_id !== null ? `${routeList.member}/${Profil_id}` : '',
    equipe,
    prenom,
    nom_prenom: `${prenom} ${nom}`,
    profil_id: Number(Profil_id),
    maillot: numero,
    poste,
    image: photo,
  };
}

export function formatResultat(resultat: IApiResultat): IResultat {
  const {
    schedId,
    champId,
    champName,
    champPoule,
    a_team_id,
    h_team_id,
    h_scr,
    a_scr,
    h_name,
    a_name,
    date_match,
    h_storage_path,
    a_storage_path,
  } = resultat;

  const championnat = formatChampionnatLight({
    id: champId,
    nom: champName,
    poule: champPoule,
    type: 'withId',
  });
  const equipeDomicile = formatEquipeLight({
    Profil_id: h_team_id,
    nom: h_name,
    logoUrl: h_storage_path,
  });
  const equipeExterieur = formatEquipeLight({
    Profil_id: a_team_id,
    nom: a_name,
    logoUrl: a_storage_path,
  });

  return {
    id: schedId,
    nom: `${equipeDomicile.nom} - ${equipeExterieur.nom}`,
    link: `${routeList.resultat}/${schedId}`,
    championnat,
    equipeDomicile,
    equipeExterieur,
    score: {
      domicile: h_scr,
      exterieur: a_scr,
    },
    date: omlDayjs(date_match),
  };
}

type IApiMatchDetail = {
  header: IApiMatchHeader[];
  perfList: IApiMatchPerf[];
  players?: { Away_Team: IApiPlayer[]; Home_Team: IApiPlayer[] };
  scoreBox: IApiMatchScoreBox[];
};
export function useGetMatch({ game_id }: { game_id: number }) {
  const { user_id } = useGeneralContextValue();
  const { data, ...rest } = useApiGet<IApiMatchDetail>(`${apiUrl.getMatch}`, { game_id, user_id });
  return { data: data ? formatMatchDetail(game_id, data) : undefined, ...rest };
}

export async function postMatchScore(body: {
  user_id: number;
  game_id: number;
  h_team_score: number;
  a_team_score: number;
}) {
  return axiosPost<never>(apiUrl.giveScrore, body);
}

export async function postRating(body: { user_id: number; game_id: number; rate: number }) {
  return axiosPost<never>(apiUrl.rategame, body);
}

async function postVotePerformance(body: { user_id: number; game_id: number; player_id: number; perf_id: number }) {
  return axiosPost<never>(apiUrl.votePerf, body);
}

export async function postVotePerformances({
  user_id,
  game_id,
  performances,
}: {
  user_id: number;
  game_id: number;
  performances: RateSubmitionFormState['performances'];
}) {
  return performances && performances.length > 0
    ? Promise.all(
        performances.map((p) => postVotePerformance({ user_id, game_id, player_id: p.player, perf_id: p.perfId }))
      )
    : Promise.resolve(undefined);
}

export function useGetJourneeResultats(params: { id?: number; sched?: number }, isActive?: boolean) {
  const { data, ...rest } = useApiGet<{ elements: IApiResultat[] }>(
    isActive !== false ? apiUrl.resultats : null,
    params
  );
  return { resultats: (data?.elements || []).map(formatResultat), ...rest };
}
