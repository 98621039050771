import { useAuthModal } from 'features/auth/auth.hook';
import { OmlFormikField } from 'features/form/components/OmlFormikField';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import { isSubmitButtonDisabled } from 'features/form/form.utils';
import { OmlYup } from 'features/form/form.validation';
import { newToast } from 'features/layout/components/Toaster';
import { severityLevel } from 'features/layout/layout.enums';
import { OmlText } from 'features/transversal/components/OmlText';
import { Formik, Form, FormikHelpers } from 'formik';
import { FC } from 'react';
import { postPublication } from '../publication.services';

const publicationValidationSchema = OmlYup.object({
  content: OmlYup.string().required(),
});
export type PublicationFormState = OmlYup.Asserts<typeof publicationValidationSchema>;

export type PublicationModalProps = { modalTitle: string; mutatePublications: () => void };
const PublicationModal: FC<PublicationModalProps> = ({ modalTitle, mutatePublications }) => {
  const { show, hide, user_id } = useAuthModal();

  function handleSubmit(
    { content }: Partial<PublicationFormState>,
    { setSubmitting }: FormikHelpers<Partial<PublicationFormState>>
  ) {
    if (!content) {
      return;
    }
    const postPublicationParams: Omit<Parameters<typeof postPublication>[0], 'user_id'> = { body: content };

    function handlePostPublication(userId: number) {
      const sucessMessage = 'Ta publication est en ligne';
      if (!userId) {
        return Promise.reject();
      }
      return postPublication({ user_id: userId, ...postPublicationParams })
        .then(() => {
          return mutatePublications();
        })
        .then(() => {
          newToast({ message: sucessMessage, severity: severityLevel.success });
          hide();
        })
        .catch((e) => {
          if (e.code === '201') {
            mutatePublications();
            newToast({ message: sucessMessage, severity: severityLevel.success });
            hide();
          } else {
            newToast({
              message: `Erreur lors de l'envoi de ta publication. Merci de réessayer plus tard`,
              severity: severityLevel.error,
            });
            setSubmitting(false);
          }
        });
    }

    if (!user_id) {
      show({
        informationMessage: 'Tu dois être connecté pour publier',
        loginProps: {
          onLoginComplete: async (userId: number) => handlePostPublication(userId),
        },
      });
    } else {
      handlePostPublication(user_id);
    }
  }

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit} validationSchema={publicationValidationSchema}>
      {({ errors, submitCount, isSubmitting }) => (
        <Form className="px-3 py-4">
          <OmlText className="text-uppercase text-center mt-4 mb-4" flavour="color-title" tag="div" variant="h3">
            {modalTitle}
          </OmlText>
          <OmlFormikField name="content" type="wysiwyg" />
          <div className="d-flex justify-content-center mt-3">
            <OmlSubmitButton
              disabled={isSubmitButtonDisabled({ isSubmitting, errors, submitCount })}
              isLoading={isSubmitting}
              texte="valider"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { PublicationModal };
