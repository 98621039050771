import { ICommentIndented } from 'features/transversal/comments.types';
import { ComponentPropsWithRef, FC } from 'react';
import classnames from 'classnames';
import { IconWithTooltip } from 'features/transversal/components/IconWithTooltip';

const ParentMessageWithoutWrapper: FC<ComponentPropsWithRef<'div'> & { comment: ICommentIndented }> = ({
  comment: { auteur, contenu },
  ...rest
}) => (
  <div {...rest}>
    <div className="font-weight-bold">{auteur.nom}</div>
    <div>
      {contenu.map((l, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={i} className="m-0">
          {l}
        </p>
      ))}
    </div>
  </div>
);

const ActuCommentParentMessage: FC<
  ComponentPropsWithRef<'div'> & { comment: ICommentIndented; onCancel: () => void }
> = ({ comment, className, style, onCancel, ...rest }) => {
  return (
    <div
      className={classnames('p-2 bg-light rounded border-primary', 'd-flex', className)}
      style={{ borderLeft: '4px solid', ...style }}
      {...rest}
    >
      <div className="flex-1">
        <ParentMessageWithoutWrapper comment={comment} />
        {comment.children && comment.children.length > 0 && (
          <ParentMessageWithoutWrapper
            className="ml-4 mt-2"
            comment={{ ...comment.children[comment.children.length - 1], children: [] }}
          />
        )}
      </div>
      <IconWithTooltip icon="times" iconSize={16} onClick={onCancel} tooltipContent="Annuler réponse" />
    </div>
  );
};

export { ActuCommentParentMessage };
