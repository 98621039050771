import { ComponentPropsWithRef, FC } from 'react';
import classnames from 'classnames';

const ActuAction: FC<
  ComponentPropsWithRef<'div'> & {
    nb: number;
    icon: JSX.Element;
    title: string;
  }
> = ({ nb, icon, title, className, ...rest }) => (
  <div className={classnames('d-flex', 'align-items-center', className)} {...rest}>
    {icon}
    <span className="font-weight-bold">
      {title} ({nb})
    </span>
  </div>
);

export { ActuAction };
