import { ReactNode, useMemo, useState } from 'react';
import { IMember } from '../member.types';
import { PageHeader } from 'features/layout/components/PageHeader';
import AddToFavorite from 'features/transversal/components/AddToFavorite';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { IconWithTooltip } from 'features/transversal/components/IconWithTooltip';
import OmlLink from 'features/transversal/components/OmlLink';
import { TabContainer } from 'features/layout/components/PageContainerWithHeaderAndTabs';
import classnames from 'classnames';
import { useModal } from 'features/layout/layout.utils';
import { UpdateMemberProfileModal } from './UpdateMemberProfileModal';

export interface IProps {
  member: IMember;
  mutateMember: () => void;
}

const MemberPageHeader = ({ member, mutateMember }: IProps) => {
  const { nom, prenom, image, id, informations, playerDetails, coachDetails } = member;
  const [activeTab, setActiveTab] = useState<'player' | 'coach'>(playerDetails ? 'player' : 'coach');
  const { user_id } = useGeneralContextValue();
  const isMyProfile = useMemo(() => id === user_id, [id, user_id]);
  const { show } = useModal();

  function handleEditProfile() {
    show({ children: <UpdateMemberProfileModal member={member} mutateMember={mutateMember} /> });
  }

  const teamAndMaillotRow = useMemo(() => {
    if (activeTab !== 'player' || !playerDetails) {
      return [];
    }
    const elementsArray: { key: string; val: ReactNode }[] = [];
    if (playerDetails.equipe) {
      elementsArray.push({
        key: 'team',
        val: <OmlLink to={playerDetails.equipe.link}>{playerDetails.equipe.nom}</OmlLink>,
      });
    }
    if (playerDetails.maillot) {
      elementsArray.push({ key: 'maillot', val: `Numéro ${playerDetails.maillot}` });
    }
    return elementsArray;
  }, [activeTab, playerDetails]);

  const posteAndTailleRow = useMemo(() => {
    if (activeTab !== 'player' || !playerDetails) {
      return [];
    }
    const elementsArray: { key: string; val: ReactNode }[] = [];
    if (playerDetails.poste) {
      elementsArray.push({
        key: 'poste',
        val: playerDetails.poste,
      });
    }
    if (playerDetails.tailleWithSuffix) {
      elementsArray.push({ key: 'taille', val: playerDetails.tailleWithSuffix });
    }
    return elementsArray;
  }, [activeTab, playerDetails]);

  const informationWordings: Record<keyof typeof informations, { singular: string; plural: string }> = {
    followers: { singular: 'Follower', plural: 'Followers' },
    comments: { singular: 'Commentaire', plural: 'Commentaires' },
    articles: { singular: 'Article', plural: 'Articles' },
    qualitiesApproved: { singular: 'Qualité approuvée', plural: 'Qualités approuvées' },
  };

  return (
    <>
      {coachDetails && (
        <TabContainer className="nav nav-tabs flex-nowrap overflow-x-scroll overflow-y-hidden hide-scrollbar ">
          {playerDetails && (
            <li
              className={classnames('tab', 'nav-item', 'pl-2', 'pb-1', activeTab === 'player' && 'active')}
              onClick={() => setActiveTab('player')}
            >
              Joueur
            </li>
          )}
          <li
            className={classnames('tab', 'nav-item', 'pl-2', 'pb-1', activeTab === 'coach' && 'active')}
            onClick={() => setActiveTab('coach')}
          >
            Entraineur
          </li>
        </TabContainer>
      )}
      <PageHeader
        heading1={
          <div>
            <div className="text-capitalize font-weight-normal">{prenom}</div>
            <div>{nom}</div>
          </div>
        }
        logo={image}
        statistics={Object.entries(informations)
          .filter((e) => e[1] > 0)
          .map((e) => ({
            count: e[1],
            singular: informationWordings[e[0]].singular,
            plural: informationWordings[e[0]].plural,
          }))}
        subHeading={
          <>
            {activeTab === 'player' && playerDetails && (
              <>
                <div>
                  {teamAndMaillotRow.map((e, i) => (
                    <span key={e.key}>
                      {i > 0 && <span>, </span>}
                      {e.val}
                    </span>
                  ))}
                </div>
                <div className="font-default font-weight-normal">
                  {posteAndTailleRow.map((e, i) => (
                    <span key={e.key}>
                      {i > 0 && <span>, </span>}
                      {e.val}
                    </span>
                  ))}
                </div>
              </>
            )}
          </>
        }
        topRightElement={
          isMyProfile ? (
            <IconWithTooltip
              icon="edit"
              iconSize={24}
              onClick={handleEditProfile}
              tooltipContent="Modifier mon profil"
            />
          ) : (
            <AddToFavorite id={id} type="member" />
          )
        }
      />
    </>
  );
};

export { MemberPageHeader };
