import { FC } from 'react';
import { Card, CardBody, CardHeader, CardProps, Collapse } from 'reactstrap';
import OmlLink from './OmlLink';

const OmlAccordion: FC<CardProps & { isOpen: boolean; onHeaderClick: () => void; headerTitle: string }> = ({
  isOpen,
  onHeaderClick,
  headerTitle,
  children,
  ...rest
}) => {
  return (
    <Card {...rest}>
      <CardHeader>
        <OmlLink onClick={onHeaderClick}>{headerTitle}</OmlLink>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody>{children}</CardBody>
      </Collapse>
    </Card>
  );
};

export { OmlAccordion };
