import { FC, useContext } from 'react';
import { Navbar, Collapse, Nav } from 'reactstrap';
import classnames from 'classnames';
import { OMLLogo } from './OMLLogo';
import { Spin } from 'react-burgers';
import { colors } from 'styles/variables/colors';
import { MenuItem } from './MenuItem';
import { MenuLabel } from '../menu.enum';
import { MenuContext } from './Menu';
import SubMenuSport from './SubMenuSport';
import SubMenuChampionnat from './SubMenuChampionnat';
import SubMenuEquip from './SubMenuEquip';
import SubMenuFavoris from './SubMenuFavoris';
import { useGeneralContextValue } from 'features/transversal/general.context';
import SubMenuProfilMobile from './SubMenuProfilMobile';

const MobileMenu: FC = () => {
  const { clickSubMenu, mobileMenuIsOpen, setMobileMenuIsOpen } = useContext(MenuContext);
  const { user } = useGeneralContextValue();

  function burgerMenuToggle() {
    if (!setMobileMenuIsOpen) {
      return;
    }
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  }

  function handleClickSubMenu(menuItem: MenuLabel) {
    if (!clickSubMenu) {
      return;
    }
    clickSubMenu(menuItem);
  }

  return (
    <>
      <Navbar
        className={classnames('oml-navbar', 'border-bottom', mobileMenuIsOpen ? 'open' : '')}
        color="light"
        fixed="top"
        light={true}
      >
        <OMLLogo />
        <Spin
          active={mobileMenuIsOpen}
          className="navbar-toggler"
          color={colors.color_title}
          onClick={burgerMenuToggle}
          width={30}
        />
      </Navbar>
      <Collapse className="bg-white" isOpen={mobileMenuIsOpen} navbar={true}>
        <Nav className={classnames('oml-nav')} navbar={true}>
          <MenuItem hasSubMenu itemType={MenuLabel.sports} onClick={handleClickSubMenu} />
          <SubMenuSport isPhone />
          <MenuItem hasSubMenu itemType={MenuLabel.championnats} onClick={handleClickSubMenu} />
          <SubMenuChampionnat isPhone />
          <MenuItem hasSubMenu itemType={MenuLabel.equipes} onClick={handleClickSubMenu} />
          <SubMenuEquip isPhone />
          <MenuItem itemType={MenuLabel.joueurs} onClick={handleClickSubMenu} />
          {user && (
            <>
              <MenuItem hasSubMenu itemType={MenuLabel.favoris} onClick={handleClickSubMenu} />
              <SubMenuFavoris isPhone />
            </>
          )}
          <MenuItem hasSubMenu={!!user} itemType={MenuLabel.profil} onClick={handleClickSubMenu} />
          <SubMenuProfilMobile />
          {/* <MenuItem itemType={MenuLabel.recherche} onClick={handleClickSubMenu} /> */}
          <MenuItem itemType={MenuLabel.notifications} onClick={handleClickSubMenu} />
        </Nav>
      </Collapse>
    </>
  );
};

export { MobileMenu };
