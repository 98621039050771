import { FC, useState, useEffect, ComponentPropsWithRef, useMemo } from 'react';
// import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
// import { fr } from 'date-fns/locale';
import OmlDropdown from './OmlDropdown';
import { ISelectOption } from '../form.types';
import classnames from 'classnames';
import { FieldProps } from 'formik';
import { formatToTwoDigitString, omlDayjs } from 'utils/date';

// import 'react-datepicker/dist/react-datepicker.css';
import '../styles/OmlDatePicker.scss';

// registerLocale('fr', fr);

type OmlDatePickerFormikProps = FieldProps<Date | undefined, any>;

export type OmlDatePickerProps = {
  id?: string;
  value?: Date;
  onChange?: (d: Date | undefined) => void;
  className?: string;
} & {
  isDropdown: true;
  containerProps?: ComponentPropsWithRef<'div'>;
  // | { isDropdown: false; calendarProps?: Partial<ReactDatePickerProps> }
} & { isInvalid?: boolean } & Partial<OmlDatePickerFormikProps>;

// TODO: a changer pour intégrer les mindate et maxdate proprement
const daysOptions: ISelectOption[] = [];
for (let i = 1; i <= 31; i += 1) {
  const dayAsText = formatToTwoDigitString(i);
  daysOptions.push({ value: dayAsText, label: dayAsText });
}
const monthsOptions: ISelectOption[] = [
  'Jan.',
  'Fév.',
  'Mars',
  'Avr.',
  'Mai',
  'Juin',
  'Juil.',
  'Août',
  'Sep.',
  'Oct.',
  'Nov.',
  'Déc.',
].map((m, k) => ({ value: formatToTwoDigitString(k + 1), label: m }));
const yearsOptions: ISelectOption[] = [];
for (let i = 16; i < 100; i += 1) {
  const yearAsText = omlDayjs().subtract(i, 'year').format('YYYY');
  yearsOptions.push({ value: yearAsText, label: yearAsText });
}

const OmlDatePicker: FC<OmlDatePickerProps> = ({ className, value, onChange, field, form, ...rest }) => {
  const { value: formikValue, name, onChange: _, ...restField } = field || {};
  const { setFieldValue } = form || {};
  // Cas des dropdown
  const [dayOption, setDayOption] = useState<ISelectOption>();
  const [monthOption, setMonthOption] = useState<ISelectOption>();
  const [yearOption, setYearOption] = useState<ISelectOption>();

  const valueAsDayJs = useMemo(() => {
    if (value !== undefined) {
      return omlDayjs(value).isValid() ? omlDayjs(value).startOf('day') : undefined;
    }
    if (formikValue !== undefined) {
      return omlDayjs(formikValue).isValid() ? omlDayjs(formikValue).startOf('day') : undefined;
    }
    return undefined;
  }, [formikValue, value]);

  useEffect(() => {
    if (valueAsDayJs) {
      const [day, month, year] = valueAsDayJs.format('DD/MM/YYYY').split('/');
      setDayOption(daysOptions.find((o) => o.value === day));
      setMonthOption(monthsOptions.find((o) => o.value === month));
      setYearOption(yearsOptions.find((o) => o.value === year));
    } else {
      setDayOption(undefined);
      setMonthOption(undefined);
      setYearOption(undefined);
    }
  }, [valueAsDayJs]);

  useEffect(() => {
    const handleDatePickerChange = (d: Date | null) => {
      if (setFieldValue && name) {
        setFieldValue(name, d || undefined);
      }
      if (onChange) {
        onChange(d || undefined);
      }
    };
    if (dayOption && monthOption && yearOption) {
      const date = omlDayjs([dayOption.value, monthOption.value, yearOption.value].join('/'), 'DD/MM/YYYY').startOf(
        'day'
      );
      if (!valueAsDayJs || !date.isSame(valueAsDayJs, 'day')) {
        handleDatePickerChange(date.toDate());
      }
    }
    // Need to disable this rule to avoid an infinite loop when updating date
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dayOption, monthOption, yearOption]);

  // if (rest.isDropdown) {
  const { className: restContainerClassName, ...restContainerProps } = rest.containerProps || {};
  return (
    <div
      className={classnames(className, restContainerClassName, 'd-flex', 'w-100')}
      {...restField}
      {...restContainerProps}
    >
      <OmlDropdown
        className="mr-2"
        isMulti={false}
        isSearchable={true}
        onChange={setDayOption}
        options={daysOptions}
        placeholder="Jour"
        value={dayOption}
        width="60px"
      />
      <OmlDropdown
        className="mr-2"
        isMulti={false}
        isSearchable={true}
        onChange={setMonthOption}
        options={monthsOptions}
        placeholder="Mois"
        value={monthOption}
        width="60px"
      />
      <OmlDropdown
        isMulti={false}
        isSearchable={true}
        onChange={setYearOption}
        options={yearsOptions}
        placeholder="Année"
        value={yearOption}
        width="80px"
      />
    </div>
  );
  // }
  // return (
  //   <DatePicker
  //     dateFormat="dd/MM/yyyy"
  //     locale="fr"
  //     onChange={handleDatePickerChange}
  //     selected={value}
  //     showMonthDropdown={true}
  //     showYearDropdown={true}
  //     {...restField}
  //     {...rest.calendarProps}
  //   />
  // );
};

export default OmlDatePicker;
