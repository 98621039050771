import { oauthTokens } from 'features/api/apiCalls.enum';

export const userIdKey = 'userId';
export const authLocalStorage = {
  logout: () => {
    delete localStorage[oauthTokens.token];
    delete localStorage[oauthTokens.secret];
    delete localStorage[oauthTokens.consumerSecret];
    delete localStorage[oauthTokens.consumerKey];
    delete localStorage[userIdKey];
  },

  setToken: (key: string, value: string | number): void => {
    if (key) {
      localStorage.setItem(key, value.toString());
    }
  },

  getToken: (key: string): string | null => {
    const value = localStorage.getItem(key) || sessionStorage.getItem(key);
    return value || null;
  },
};
