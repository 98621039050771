import styled from 'styled-components';
import { fonts } from '../variables/fonts';
import { colors } from '../variables/colors';

export const StyledPanelTitle = styled.h1`
  font-size: ${fonts.font_size_subtitle};
  font-family: ${fonts.font_text};
  font-weight: unset;
  text-align: center;
`;

export const StyledPanelSubTitle = styled.h2`
  font-size: ${fonts.font_size_intermediate};
  font-family: ${fonts.font_title};
  color: ${colors.color_title};
  font-weight: unset;
  text-transform: uppercase;
`;
