import { useEffect, useState, FC, useCallback } from 'react';
import { StyledPanelTitle } from 'styles/components/titles';
import { Button } from 'reactstrap';
import { Circle } from 'rc-progress';
import { colors } from 'styles/variables/colors';
import styled from 'styled-components';
import BackButton from './InscriptionBackButton';
import { useFormikContext } from 'formik';
import { InscriptionFormState } from 'scenes/Auth/Inscription';
import { postInscription, postMember } from 'features/inscription/inscription.services';
import { useGeneralContextValue } from 'features/transversal/general.context';
import { useNavigate } from 'react-router-dom';
import { routeList } from 'App';
import { IUser } from 'features/user/user.types';
import { callUserFavoris, FAVORITES_DEFAULT } from 'features/user/favoris.services';
import { postJoinTeam } from 'features/equipe/equipe.services';

const StyledCircle = styled(Circle)`
  height: 150px;
  display: block;
  margin: auto;
`;

const INITIAL_PROGRESS = 30;

const InscriptionStepConfirm: FC<{
  onBack: () => void;
  onError: (message: Record<string, string>) => void;
  isStepActive?: boolean;
}> = ({ onBack, isStepActive, onError }) => {
  const [isError, setIsError] = useState(false);
  const [progress, setProgress] = useState(INITIAL_PROGRESS);
  const { values } = useFormikContext<InscriptionFormState>();
  const { setUser, setFavoris } = useGeneralContextValue();
  const navigate = useNavigate();

  const callInscriptionCallback = useCallback(
    () =>
      postInscription(values)
        .then((user: IUser) => {
          setProgress(40);
          return postMember({ ...values, user_id: user.id });
        })
        .then((user) => {
          setProgress(60);
          if (setUser) {
            setUser(user);
          }
          return Promise.resolve(user);
        })
        .then((user) => {
          setProgress(70);
          if (!values.playerTeam) {
            return Promise.resolve(user);
          }
          return postJoinTeam({ user_id: user.id, team_id: values.playerTeam, type: 'P' }).then(() => user);
        })
        .then((user) => {
          setProgress(80);
          if (!values.trainerTeam) {
            return Promise.resolve(user);
          }
          return postJoinTeam({ user_id: user.id, team_id: values.trainerTeam, type: 'C' }).then(() => user);
        })
        .then((user) => {
          if (!user) {
            return Promise.resolve(FAVORITES_DEFAULT);
          }
          return callUserFavoris(user.id);
        })
        .then((favoris) => {
          if (setFavoris) {
            setFavoris(favoris);
          }
        })
        .then(() => navigate(routeList.profil))
        .catch((e) => {
          if (e.message) {
            onError(e.message);
            return;
          }
          console.error('e :', e);
          setIsError(true);
        }),
    [values, setUser, navigate, onError, setFavoris]
  );

  function handleRetry() {
    setIsError(false);
    setProgress(INITIAL_PROGRESS);
    callInscriptionCallback();
  }

  useEffect(() => {
    if (isStepActive) {
      callInscriptionCallback();
    }
  }, [callInscriptionCallback, isStepActive]);

  return (
    <div className="text-center">
      {isError ? (
        <>
          <BackButton onBack={onBack} />
          <StyledPanelTitle className="my-5">Erreur lors de l&apos;inscription. Merci de réessayer</StyledPanelTitle>

          <Button color="primary" onClick={handleRetry}>
            Réessayer
          </Button>
        </>
      ) : (
        <>
          <StyledPanelTitle className="my-5">Inscription en cours</StyledPanelTitle>
          <StyledCircle
            percent={progress}
            strokeColor={colors.color_title}
            strokeLinecap="butt"
            strokeWidth={10}
            trailWidth={10}
          />
        </>
      )}
    </div>
  );
};

export default InscriptionStepConfirm;
