import { useAuthModal } from 'features/auth/auth.hook';
import { OmlFormikField } from 'features/form/components/OmlFormikField';
import OmlSubmitButton from 'features/form/components/OmlSubmitButton';
import { isSubmitButtonDisabled } from 'features/form/form.utils';
import { OmlYup } from 'features/form/form.validation';
import { OmlText } from 'features/transversal/components/OmlText';
import { Formik, Form } from 'formik';
import { FC } from 'react';
import { postActu } from '../actus.services';
import { ActuType } from '../actus.types';

const actuValidationSchema = OmlYup.object({
  title: OmlYup.string().required(),
  photo: OmlYup.mixed().test('fileSize', "L'image est trop volumineuse (taille maximum 2Mo)", (value) => {
    if (!value?.length) {
      return true;
    }
    return value[0].size <= 2000 * 1000;
  }),
  // type: OmlYup.string()
  //   .oneOf([...actuTypes])
  //   .required(),
  // omlEntityId: OmlYup.string().when('type', {
  //   is: (type: ActuType) => type !== 'divers',
  //   then: OmlYup.string().required(),
  // }),
  text: OmlYup.string().required(),
});
export type ActuFormState = OmlYup.Asserts<typeof actuValidationSchema>;

const actuFormInitialValues: Partial<ActuFormState> = {};

export type NewActuModalProps = { modalTitle: string; mutateActus: () => void; type: ActuType; omlEntityId?: string };

const NewActuModal: FC<NewActuModalProps> = ({ modalTitle, mutateActus, type, omlEntityId }) => {
  const { show, hide, user_id } = useAuthModal();

  function handleSubmit({ title, text, photo }: Partial<ActuFormState>) {
    if (!title || !text) {
      return;
    }

    const postActuParams: Omit<Parameters<typeof postActu>[0], 'user_id'> = {
      title,
      text,
      photo,
      type,
      champId: omlEntityId,
    };
    if (!user_id) {
      show({
        informationMessage: 'Tu dois être connecté pour publier',
        loginProps: {
          onLoginComplete: async (userId: number) => {
            await postActu({ user_id: userId, ...postActuParams });
            return mutateActus();
          },
        },
      });
    } else {
      postActu({
        user_id,
        ...postActuParams,
      })
        .then(() => {
          return mutateActus();
        })
        .then(() => {
          hide();
        });
    }
  }

  return (
    <Formik initialValues={actuFormInitialValues} onSubmit={handleSubmit} validationSchema={actuValidationSchema}>
      {({ errors, submitCount, isSubmitting }) => (
        <Form className="px-3 py-4">
          <OmlText className="text-uppercase text-center mt-4 mb-4" flavour="color-title" tag="div" variant="h3">
            {modalTitle}
          </OmlText>
          <OmlFormikField fieldLabel="Titre" name="title" type="text" />
          <OmlFormikField fieldLabel="Illustation" name="photo" subType="photo" type="file" />
          <OmlFormikField name="text" type="wysiwyg" />
          <div className="d-flex justify-content-center">
            <OmlSubmitButton
              disabled={isSubmitButtonDisabled({ isSubmitting, errors, submitCount })}
              isLoading={isSubmitting}
              texte="valider"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export { NewActuModal };
