import { FieldProps } from 'formik';
import { ComponentProps, FC } from 'react';
import OmlInput from 'features/form/components/OmlInput/OmlInput';

const FormikOmlInput: FC<FieldProps<string, any> & ComponentProps<typeof OmlInput>> = ({
  field,
  form: _,
  isInvalid,
  ...props
}) => {
  return <OmlInput isInvalid={isInvalid} {...field} {...props} />;
};

export { FormikOmlInput };
