import { ComponentPropsWithRef, FC, forwardRef, useState, ChangeEvent, useMemo } from 'react';
import { Input, InputProps } from 'reactstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './OmlInput.scss';

export type IProps = ComponentPropsWithRef<'input'> & {
  sufix?: string;
  isInvalid?: boolean;
};

const OmlInput: FC<IProps> = forwardRef(
  ({ id, type: initialType, value, isInvalid, min, max, sufix, className, onChange, ref: _, ...rest }, inputRef) => {
    const [type, updateType] = useState(initialType);

    const input = useMemo(() => {
      const togglePasswordType = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        updateType(type === 'text' ? 'password' : 'text');
      };

      function handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (onChange) {
          onChange(event);
        }
      }

      return (
        <>
          <Input
            className={classnames(
              'oml-input',
              value && typeof value !== 'number' && value.length > 0 && 'filled',
              className
            )}
            id={id}
            innerRef={inputRef}
            invalid={isInvalid}
            max={max}
            min={min}
            name={id}
            onChange={handleChange}
            type={type as InputProps['type']}
            value={value}
            {...rest}
          />
          {initialType === 'password' && (
            <span className="display-password" onClick={togglePasswordType}>
              {type === 'password' ? <FontAwesomeIcon icon="eye" /> : <FontAwesomeIcon icon="eye-slash" />}
            </span>
          )}
        </>
      );
    }, [className, id, initialType, inputRef, isInvalid, max, min, onChange, rest, type, value]);

    return sufix ? (
      <div className="position-relative">
        {input}
        <span className="display-sufix">{sufix}</span>
      </div>
    ) : (
      input
    );
  }
);

export default OmlInput;
