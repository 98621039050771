import { FC, forwardRef, ComponentPropsWithRef, useMemo } from 'react';
import classnames from 'classnames';

type managedHeaders = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
export type managedComponents = managedHeaders | 'p' | 'span' | 'time' | 'div';

export interface TextProps {
  variant: managedHeaders | 'p' | 'big' | 'small' | 'tiny';
  tag?: managedComponents;
  flavour?: 'light' | 'dark' | 'red' | 'green' | 'grey' | 'color-title';
}

export type TextWithArrowProps = {
  withArrow?: boolean;
} & TextProps;

export type HTMLPropsHeaders = ComponentPropsWithRef<managedHeaders>;

export type TextPropsOptionalVariant = Omit<TextWithArrowProps, 'variant'> & {
  variant?: TextWithArrowProps['variant'];
};

const OmlText: FC<TextProps & HTMLPropsHeaders> = forwardRef(
  ({ tag, variant, flavour = 'dark', className, ...rest }, textRef) => {
    const rootClass = classnames(variant, flavour, className);

    const Component: managedComponents = useMemo(() => {
      if (tag) {
        return tag;
      }
      if (variant !== 'big' && variant !== 'small' && variant !== 'tiny') {
        return variant;
      }
      return 'p';
    }, [tag, variant]);

    if (Component === 'span') {
      return <Component ref={textRef as React.Ref<HTMLSpanElement>} className={rootClass} {...rest} />;
    }
    // @ts-expect-error TODO trouver comment caster cette ref
    return <Component ref={textRef} className={rootClass} {...rest} />;
  }
);

export { OmlText };
