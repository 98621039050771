import { FC, useState } from 'react';
import styled from 'styled-components';
import { fonts } from 'styles/variables/fonts';
import { ITeamStatistics, ITeamStatisticsDetail } from '../equipe.types';
import '../styles/Team.scss';

export interface IProps {
  teamStats: ITeamStatistics;
}

const Container = styled.div`
  width: 100%;
  .team-statistics-detail-wrapper {
    min-width: 500px;
  }
  overflow: scroll;
  font-size: 12px;
  font-family: ${fonts.font_title};

  .detail-title {
    display: flex;
    justify-content: space-around;
  }
  .team-statistics-tab-sub-header {
    font-weight: bold;
  }
  .team-statistics-tab-stat-title {
    width: 75px;
  }
`;

const TeamStatisticsDetail: FC<{ title: string; detail: ITeamStatisticsDetail }> = ({
  title,
  detail: { matchs, offense, defense },
}) => {
  const [leftColRef, setLeftColRef] = useState<HTMLSpanElement>();
  const [rightColRef, setRightColRef] = useState<HTMLSpanElement>();

  return (
    <Container className="mb-3">
      <div className="team-statistics-detail-wrapper">
        <div className="tab-title text-uppercase">{title}</div>
        <div className="border">
          <div className="detail-title bord border-bottom py-2 text-center">
            <div className="flex-1">
              <span ref={(newRef) => setLeftColRef(newRef || undefined)}>Matchs joués</span>
            </div>
            <div className="flex-1">Victoires</div>
            <div className="flex-1">
              <span ref={(newRef) => setRightColRef(newRef || undefined)}>Matchs nuls</span>
            </div>
            <div className="flex-1">Défaites</div>
          </div>
          <div className="detail-title text-center py-2 ">
            <div className="flex-1">{matchs.played}</div>
            <div className="flex-1">{matchs.won}</div>
            <div className="flex-1">{matchs.draw}</div>
            <div className="flex-1">{matchs.lost}</div>
          </div>
          <div className="position-relative my-3" style={{ height: '50px' }}>
            <div
              className="d-flex position-absolute"
              style={leftColRef ? { left: `${leftColRef.offsetLeft}px` } : undefined}
            >
              <div className="mr-4 team-statistics-tab-sub-header">Attaque</div>
              <div>
                <div className="d-flex">
                  <div className="team-statistics-tab-stat-title">Total</div>
                  <div className="font-weight-bold">{offense.total}</div>
                </div>
                <div className="d-flex">
                  <div className="team-statistics-tab-stat-title">Moyenne</div>
                  <div className="font-weight-bold">{offense.avg}</div>
                </div>
                <div className="d-flex">
                  <div className="team-statistics-tab-stat-title">Rang</div>
                  <div className="font-weight-bold">{offense.rank}</div>
                </div>
              </div>
            </div>
            <div
              className="d-flex position-absolute"
              style={rightColRef ? { left: `${rightColRef.offsetLeft}px` } : undefined}
            >
              <div className="mr-4 team-statistics-tab-sub-header">Défense</div>
              <div>
                <div className="d-flex">
                  <div className="team-statistics-tab-stat-title">Total</div>
                  <div className="font-weight-bold">{defense.total}</div>
                </div>
                <div className="d-flex">
                  <div className="team-statistics-tab-stat-title">Moyenne</div>
                  <div className="font-weight-bold">{defense.avg}</div>
                </div>
                <div className="d-flex">
                  <div className="team-statistics-tab-stat-title">Rang</div>
                  <div className="font-weight-bold">{defense.rank}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

const TeamStatisticsTab: FC<IProps> = ({ teamStats }) => {
  const { domicile, exterieur, saison } = teamStats;
  return (
    <>
      {saison && <TeamStatisticsDetail detail={saison} title="Saison" />}
      {domicile && <TeamStatisticsDetail detail={domicile} title="Domicile" />}
      {exterieur && <TeamStatisticsDetail detail={exterieur} title="Extérieur" />}
    </>
  );
};

export default TeamStatisticsTab;
