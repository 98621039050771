import { colors } from 'styles/variables/colors';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledButton = styled.button`
  color: ${colors.color_title};
  border: none;
  background: transparent;
`;

const InscriptionBackButton = (props: { onBack: () => void }) => {
  const { onBack } = props;
  return (
    <StyledButton
      onClick={(e) => {
        e.preventDefault();
        onBack();
      }}
    >
      <FontAwesomeIcon className="mr-2" icon="arrow-left" />
      Précédent
    </StyledButton>
  );
};
export default InscriptionBackButton;
