// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function safeJsonParse(input: any) {
  if (typeof input !== 'object') {
    return JSON.parse(input);
  }
  return input;
}

export function joinNomPrenom({ nom, prenom }: { nom: string; prenom?: string }) {
  return prenom ? `${prenom} ${nom}` : nom;
}
