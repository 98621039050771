import { Component } from 'react';
import { withRouter } from 'utils/router';

class ScrollToTop extends Component<any> {
  public componentDidUpdate(prevProps: any) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
